
import global from '../../global.js';
import * as tools from '../../tools.js';
import { validateEmail } from '../agroambiente/commonJS.js';
import * as db from './data.js';
import *  as fs_oo from './fairshare_oo.js';
import *  as fs_lib from './fairshare.js';
import * as model_lib from './fairshare_model.js';
import storage from '../../storage.js';

import t from '../../translate.js';
import * as d3 from "d3v4";
import * as c3 from 'c3';
import 'c3/c3.css';




export function homeReport(farm_data){




  let id_farm_center=farm_data.farm_center[0].id_farm_center;
  let id_season=null;
  if(farm_data.actual_season.length>0){
    id_season=farm_data.actual_season[0].id_season;
  }



  if(id_season!=null){
    jQuery("#plot_report").html(`
        <div class="block-title">Report</div>
        <div class="list inset">
          ${t('In questa pagina sarà possibile accedere ad una sintesi dei dati colturali.')}
          <hr/>


          <div id="report_space"></div>



        </div>


      `);



      console.log(farm_data);

      jQuery("#report_space").html(`
      <div class="row">
      <div class="col-50">
        <div class="card">
          <div class="card-header">${t("Monitoraggio")}</div>
          <div class="card-content  card-large">${farm_data.observation.length}</div>
          <div class="card-footer">
            <button onclick="location.hash='farm/${id_farm_center}/season/${id_season}/observation'"
              class="button  button-small button-fill">
                ${t('Report Monitoraggio')}
            </button>
          </div>
        </div>
      </div>
      <div class="col-50">
        <div class="card">
          <div class="card-header">${t("Trappole")}</div>
          <div class="card-content card-large">${farm_data.trap.length}</div>
          <div class="card-footer">
            <button onclick="location.hash='farm/${id_farm_center}/season/${id_season}/trap'"
              class="button  button-small button-fill">
                ${t('Elenco delle trappole')}
            </button>
          </div>
        </div>
      </div>
      <div class="col-50">
        <div class="card">
          <div class="card-header">${t("Operazioni Colturali")}</div>
          <div class="card-content  card-large">${farm_data.operation.length}</div>
          <div class="card-footer">
              <button onclick="location.hash='farm/${id_farm_center}/season/${id_season}/operation'"
                class="button  button-small button-fill">
                  ${t('Report Operazioni')}
              </button>
          </div>
      </div>
      </div>


      `);
  }
  else{

    jQuery("#plot_report").html(`
        <div class="block-title">${t('Report')}</div>
        <div class="list inset">  
          ${t('In questa pagina sarà possibile accedere ad una sintesi dei dati colturali una volta inseriti.')}
          <hr/>
        </div>
      `);
  }

    // var fd=db.getFarmDataSeason(id_farm_center,id_season);

    // db.calcfarmIndicators(farm_data);


}

export function home_operation( id_farm_center,  id_season){
  return home_oo("operation", id_farm_center,  id_season);
}

export function home_observation( id_farm_center,  id_season){
  return home_oo("observation", id_farm_center,  id_season);
}

export function homeModels(farm_data,id_farm_center,id_season){
  var optmodels='';
  var selected='selected'
  var first_el=t('Irrigazione');
  optmodels+=`<option value="dss_irrigation" ${selected}>${t('Irrigazione')}</option>`;
  optmodels+=`<option value="dss_nutrition">${t('Concimazione')}</option>`;
  optmodels+=`<option value="aeco_corn_diabro">${t('Diabrotica virgifera')}</option>`;
  optmodels+=`<option value="aeco_grape_powdery">${t('Oidio della vite')}</option>`;


  var models=`
  <div class="block-title block-title-medium">${t('Modelli')}</div>
  <div class="list inset">
    <ul>
      <li>
        <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">
          <select id="model">
            ${optmodels}
          </select>

          <div class="item-content">
            <div class="item-inner" style="width: fit-content;">
              <div class="item-title"></div>
              <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${first_el}</div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div id="res_table" class="card"></div>
  `;

  jQuery('#plot_models').html(models);


  jQuery('#model').change(function(){
    var model=jQuery('#model').val();
    drawModelDashboard(farm_data,model);
  }).change();

}

function drawModelDashboard(farm_data,model){

  var header=getModelHeader(model);

  var table_record='';
  var id_season=farm_data.actual_season[0].id_season;
  var model=model;


  jQuery.each(farm_data.field,function(k,v){
    if (v.id_season==id_season) {
      var fd=db.getFieldData(v.id_field);


      jQuery.each(fd.aemodel,function(km,m){
        if (m.inner_models) {
          jQuery.each(m.inner_models,function(kk,vm){
            var table_body='';
            if (vm.model_name==model) {
              var modelCache=db.getModelFromCache(global.getTodayDate(), vm.model_name, v.id_field);
              console.log(modelCache);

              if (modelCache.length>0) {


                var iModelRes=modelElaborate(vm.model_name, modelCache[0].response);
                jQuery.each(iModelRes,function(i,d){
                  table_body+=`<th>${t(d)}</th>`;
                });
              }
              else {
                table_body=`<th colspan="${header.length}"><span class="row" style="flex-wrap: unset;"><i style="color: #bbb;">${t('Modello non ancora calcolato')}</i><i id_field="${v.id_field}" class="runmodel f7-icons" style="float:right;">arrow_clockwise</i></span></th>`;
              }

              table_record+=`
              <tr>
                <th>${v.name}</th>
                ${table_body}
              </tr>`;
            }

          });
        }

        var table_body='';
        if (m.model_name==model) {
          var modelCache=db.getModelFromCache(global.getTodayDate(), m.model_name, v.id_field);
          console.log(modelCache);

          if (modelCache.length>0) {


            var iModelRes=modelElaborate(m.model_name, modelCache[0].response);
            jQuery.each(iModelRes,function(i,d){
              table_body+=`<th>${t(d)}</th>`;
            });
          }
          else {
            table_body=`<th colspan="${header.length}"><span class="row" style="flex-wrap: unset;"><i style="color: #bbb;">${t('Modello non ancora calcolato')}</i><i id_field="${v.id_field}" class="runmodel f7-icons" style="float:right;">arrow_clockwise</i></span></th>`;
          }

          table_record+=`
          <tr>
            <th>${v.name}</th>
            ${table_body}
          </tr>`;
        }
      });
    }
  });



  var table_header='';
  jQuery.each(header,function(i,d){
    table_header+=`<th>${t(d)}</th>`;
  });

  var table=`
  <table class="data-table">
    <thead>
      <tr>
        <th>${t('Campo')}</th>
        ${table_header}
      </tr>
    </thead>
    <tbody>${table_record}</tbody>
  </table>`;


  jQuery('#res_table').html(table);


  jQuery('.runmodel').click(function(){
    var id_field=jQuery(this).attr('id_field');

    var fd=db.getFieldData(id_field);

    var s=storage.get();
    var today=global.getTodayDate();

    jQuery.each(s.model_cache,function(k,v){
      if (v.model_name==model && v.id_field==id_field) {
        delete s.model_cache[k];
      }
    });

    if (!s.model_cache) {
      s.model_cache=[];
    }
    s.model_cache = s.model_cache.filter(function () { return true });

    storage.save(s);


    // setTimeout(function() {
      var force_refresh=true;
      var model_cache=model_lib.runModel(id_field, fd, model,force_refresh);
      setTimeout(function() {
        jQuery('#model').change();
      }, 1000);
    // }, 2500);


  });
}

export function home_oo(type,  id_farm_center,  id_season){

  var fd=db.getFarmDataSeason( id_farm_center, id_season);
  var id_field=0;

  if(fd.field.length>0){
    id_field=fd.field[0].id_field;
  }

  var label=t("Monitoraggi");
  if(type=='operation'){
    label = t('Operazioni Colturali');
  }

  global.theme.render({
    exclude_card:true,
    title: ` ${t('Stagione')} ${fd.actual_season[0].season_name} - ${label}`,
    content: `

      <div id="farm_season_selector"></div>
      <div id="oo_list"></div>
    `
  });

  fs_oo.table_oo("#oo_list", id_farm_center, fd[type], {"fields": fd.field});

  //riempie il selecttore di campo e stagioe
  fs_lib.getFarmSeasonSelects(fd,id_farm_center,id_season);


  jQuery('#farm').change(function(){
    if (jQuery(this).val()=='new_farm') {
      location.hash=`create_farm_center/${fd.farm.farm_role}`;
    }
    else {

      var new_id_farm_center=jQuery(this).val();

      //devo prendere a stagione di default del new_id_farm_center (uso getSeasonsSorted)
      var new_seas=db.getSeasonsSorted(new_id_farm_center);
      var new_id_season="";
      if (new_seas.length>0) {
        new_id_season=new_seas[0].id_season;
      }
      location.hash=`farm/${new_id_farm_center}/season/${new_id_season}/${type}`;
    }
  });

  jQuery('#season').change(function(){
    if (jQuery(this).val()=='new_season') {
      location.hash=`farm/${id_farm_center}/new_season`;
    }
    else{
      location.hash=`farm/${id_farm_center}/season/${jQuery(this).val()}/${type}`;
    }
  });

}


function getModelHeader(model_name){
  if(model_name=='dss_nutrition'){
    return ["N (kg/ha)","P (kg/ha)","K (kg/ha)"]
  }
  else if(model_name=='dss_irrigation'){
    return ["MM da irrigare"]
  }
  else {
    return ["Risultato"]
  }
}
function modelElaborate(model_name, res){
  if(res.ok){

    if(model_name=='dss_nutrition'){
      var n,p,k;

      if (res.results.values.n_bal-res.results.values.n_applied>0) {
        n=(res.results.values.n_bal-(res.results.values.n_applied)).toFixed(2);
      }
      else {
        n="OK"
      }
      if (res.results.values.p_bal-res.results.values.p_applied>0) {
        p=(res.results.values.p_bal-(res.results.values.p_applied)).toFixed(2);
      }
      else {
        p="OK"
      }
      if (res.results.values.k_bal-res.results.values.k_applied>0) {
        k=(res.results.values.k_bal-(res.results.values.k_applied)).toFixed(2);
      }
      else {
        k="OK"
      }

      return [n,p,k];
    }
    else if(model_name=='dss_irrigation'){
      return [res.final_value.water_need.toFixed(2)];
    }
    else {
      if (res && res.final_value && res.final_value.alert && res.final_value.alert.message) {
        return [(model_name+'_'+res.final_value.alert.message)];
      }
      else {
        return [JSON.stringify(res).substring(0,50)];
      }
    }
  }
  else{
    return [res.message]
  }
}
