import global from '../../global.js';
import * as tools from '../../tools.js';
import storage from '../../storage.js';
import SignaturePad from 'signature_pad/dist/signature_pad.min.js';
import t from '../../translate.js';
import photo from '../../core/photo.js';
import * as db from './data.js';
import gps from '../../core/gps.js';


// console.log(signaturepad);
// debugger

export function misura21(){
  var s=storage.get();

  global.theme.render({
    exclude_card:true,
    title: ``,
    content: `<div id="calendar_container"></div>`
  });

  var html = `
    <div id="last_update"></div>
    <div id="calendar_imminenti"></div>
    <div id="calendar_other"></div>
  `;
  jQuery('#calendar_container').html(html);


  var date_s='';
  var last_update=s.lastUpdate;
  if(last_update){
    var date = new Date(last_update);
    date_s=date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear()+' '+date.getHours()+':'+((date.getMinutes() < 10 ? '0' : '') + date.getMinutes());
  }

  var html = ` 
    <div class="block-header" style="text-align: center;">Ultimo aggiornamento: ${date_s}</div>
    <button id="update_events" class="button button-tonal button-round">Aggiorna eventi</button>
  `;
  jQuery('#last_update').html(html);

  jQuery('#update_events').on('click', function(){
    global.app.dialog.preloader(t("Sto scaricando i dati"));
    setTimeout(function() {
      global.app.dialog.close();
      tools.synchronizes('misura21');
    }, 200);
  });


  var events_imminenti='';
  var events_other='';

  // debugger
  var imminent=s.calendar.filter(function(d){return d.status=='30_sent'});
  var other=s.calendar.filter(function(d){return d.status!='30_sent'});
  // if(imminent.length==0){
  //   events_imminenti+=` Nessun evento imminente trovato `;
  // }
  // else{
    
  // }


  // if(other.length==0){
  //   events_other+=` Nessun altro evento trovato `;
  // }
  
// order s.calendar by status, datetime_start
  var events=s.calendar.sort(function(a, b){
    var keyA = a.status + a.datetime_start,
        keyB = b.status + b.datetime_start;
    // Compare the 2 dates
    if(keyA < keyB) return -1;
    if(keyA > keyB) return 1;
    return 0;
  });

  var decodeStatus={
    '10_draft': {
      label: 'Bozza',
      description: 'Bozza',
      emoji: '📝'
    },
    '20_sent_mod': {
      label: 'Inviato ma modificabile',
      description: 'Inviato alla Regione ma modificabile',
      emoji: '📨'
    },
    '25_modified': {
      label: 'Modificato',
      description: 'Modificato prima della conferma',
      emoji:  '✏️'
    },
    '27_deleted': {
      label: 'Cancellato',
      description: 'Cancellato prima della conferma',
      emoji: '🗑️'
    },
    '30_sent': {
      label: 'Confermato',
      description: 'Inviato alla Regione - Confermato',
      emoji: '🗓️'
    },
    '38_reopened': {
      label: 'Riaperto',
      description: 'Visita effettuata ma riaperta per modifiche',
      emoji: '🔓'
    },
    '40_done': {
      label: 'Effettuato',
      description: 'Visita effettuata con successo',
      emoji: '✅'
    },
    '45_done_mod': {
      label: 'Effettuato',
      description: 'Visita effettuata con successo',
      emoji: '✅'
    },
    '50_cancelled': {
      label: 'Annullato',
      description: 'Visita annullata',
      emoji: '❌'
    }
  }

  jQuery.each(events, function(i, event){
    console.log(event);

    const mesi = ["gen", "feb", "mar", "apr", "mag", "giu","lug", "ago", "set", "ott", "nov", "dic"];
    // i need a string like  2 dic 2019 for the date
    var date = new Date(event.datetime_start);
    var date_s=date.getDate()+' '+mesi[date.getMonth()]+' '+date.getFullYear();
    var date_end = new Date(event.datetime_end);
    var date_end_s=date_end.getDate()+' '+mesi[date_end.getMonth()]+' '+date_end.getFullYear();

    console.log(date_s);
    console.log(date_end_s);
    
    var date_string=date_s;
    if(date_s!=date_end_s){
      date_string=date_s+' - '+date_end_s;
    }

    var ora_inizio=date.getHours()+':'+((date.getMinutes() < 10 ? '0' : '') + date.getMinutes())
    var ora_fine=date_end.getHours()+':'+((date_end.getMinutes() < 10 ? '0' : '') + date_end.getMinutes())

    // debugger
    var statusValue=decodeStatus[event.status];
    var statusLabel=statusValue && statusValue.label?statusValue.label:event.status;
    var statusEmoji=statusValue && statusValue.emoji?statusValue.emoji:'';
    var statusDescription=statusValue && statusValue.description?statusValue.description:'';

    if(event.status=='30_sent'){
      events_imminenti+=`
        <li>
          <a href="#misura21/event/${event.id_calendar}" class="link external item-link item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">${date_string} - ${ora_inizio}-${ora_fine}</div>
                <div class="item-after">${statusLabel}</div>
              </div>
              <div class="item-subtitle">${statusEmoji} ${statusDescription}</div>
              <div class="item-text">${event.description}</div>
            </div>
          </a>
        </li>
      `;
    }
    else{

      var swipeout=`
        <div class="swipeout-actions-right">
          <a class="swipeout-reopen" data-id-calendar="${event.id_calendar}"><i class="f7-icons color-white">lock_open_fill</i>Riapri</a>
        </div>
      `;

      events_other+=`
        <li class="${(event.status=='40_done' || event.status=='45_done_mod' || event.status=='50_cancelled')?'swipeout':''}" style="${(event.status=='27_deleted' || event.status=='45_done_mod' || event.status=='40_done' || event.status=='50_cancelled')?'color:grey;':''}">
          <a href="#misura21/event/${event.id_calendar}" class="${(event.status!='40_done' && event.status!='45_done_mod' && event.status!='50_cancelled')?' link external ':''} ${(event.status=='40_done' ||event.status=='45_done_mod' || event.status=='50_cancelled')?' swipeout-content ':''} item-link item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title"> ${date_string} - ${ora_inizio}-${ora_fine}</div>
                <div class="item-after">${statusLabel} </div>
              </div>
              <div class="item-subtitle">${statusEmoji} ${statusDescription}</div>
              <div class="item-text">${event.description}</div>
            </div>
          </a>
          ${(event.status=='40_done' || event.status=='45_done_mod'  || event.status=='50_cancelled')?swipeout:''}
        </li>
      `;
    }
  });

  var html = `
  <div class="block-title">Eventi imminenti</div>
    <div class="card">
      <div class="card-content card-content-padding">
        <div class="list media-list list-outline-ios list-strong-ios list-dividers-ios">
          <ul>${events_imminenti}</ul>
        </div>
      </div>
    </div>
    `;
  if(imminent.length==0){
    html=`<div class="block-title">Nessun evento imminente trovato</div>`;
        

  }
  jQuery('#calendar_imminenti').html(html);

  var html = `
  <div class="block-title">Altri eventi</div>
  <div class="list media-list list-outline-ios list-strong-ios list-dividers-ios">
    <ul>${events_other}</ul>
  </div>
 `;
  
  if(other.length==0){
    html=`<div class="block-title">Nessun altro evento trovato</div>`;
  }

  jQuery('#calendar_other').html(html);


  jQuery('.swipeout').on('click', function(){
    global.app.swipeout.open(jQuery(this));
  });

  // when i click on the swipeout i want to change the status
  jQuery('.swipeout-reopen').on('click', function(){

    // open preloader
    global.app.dialog.preloader(t("Sto riaprendo l'evento"));

    var id_calendar=jQuery(this).data('id-calendar');
    console.log(id_calendar);
  
    var s=storage.get();
    var event=s.calendar.find(e=>e.id_calendar==id_calendar);
    event.status='38_reopened';
    event.sync=false; 
    console.log(event);
    storage.save(s); 
    db.lightSync(function(){
      global.app.dialog.close();
      location.reload();
    }, 
    function(){
      global.app.dialog.close();
      location.reload();
    });
    
    setTimeout(() => {
      ;
    }, 1500);
  });


  // jQuery('#footer').html(`<button id="add_visita" class="button fs-button button-large button-fill button-raised">Aggiungi visita</button>`); 
  jQuery('#footer').html(``); 
  jQuery('#add_visita').on('click', function(){
    location.hash=('#misura21/event/new');
  });

}

export function event(id_calendar){
  var event={};
  if(id_calendar=='new'){
    id_calendar=null;
  }
  
  var s=storage.get();
  
  if(id_calendar){
    event=s.calendar.find(e=>e.id_calendar==id_calendar);
    console.log("evento:",event);
  }
  
  global.theme.render({
    exclude_card:false,
    title: `Eventi`,
    content: `<div id="dbmng"></div>`
  });

  var theme_f7 = new Dbmng.Framework7Theme();

  var aForm = s.calendar_dbmng;
  global.form=new Dbmng.Form({aForm:aForm, aParam:{exclude_card:true}, theme:theme_f7});
  jQuery('#dbmng').html(global.form.createForm(event));

  // Disable jQuery('.dbmng_form_field_id_voce_azienda .real_widget')
  jQuery('.dbmng_form_field_id_voce_azienda .real_widget').attr('disabled', true);

  if(event.status=='30_sent' || event.status=='40_done' || event.status=='45_done_mod' || event.status=='50_cancelled' || event.status=='38_reopened'){
    jQuery('.dbmng_form_field_datetime_start .real_widget, .dbmng_form_field_datetime_end .real_widget').attr('disabled', true);
  }

  try{
    // jQuerata per mettere data inizio prima di data fine
    var lis=jQuery('.dbmng_form_field_datetime_start').parent().parent().find('li');
    lis.each(function(k,v) {
      if(k<2){
        jQuery(this).parent().prepend(this);
      }
    });
  }
  catch(e){
    console.log(e);
  }

  var html = `
    <div class="list list-strong-ios list-outline-ios list-dividers-ios">
      <ul>
        <li class="only-verylow-status">
          <label class="item-checkbox item-checkbox-icon-start item-content">
            <input type="checkbox" name="demo-checkbox" value="10_draft" ${event.status=='10_draft'?'checked':''}/>
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
              <div class="item-title">Modifica</div>
            </div>
          </label>
        </li>
        <li class="only-low-status">
          <label class="item-checkbox item-checkbox-icon-start item-content">
            <input type="checkbox" name="demo-checkbox" value="25_modified" ${event.status!='38_reopened' && event.status!='30_sent'?'checked':''}/>
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
              <div class="item-title">Modifica</div>
            </div>
          </label>
        </li>
        <li class="only-low-status only-verylow-status">
          <label class="item-checkbox item-checkbox-icon-start item-content">
            <input type="checkbox" name="demo-checkbox" value="27_deleted" />
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
              <div class="item-title">Cancella</div>
            </div>
          </label>
        </li>
        <li class="only-30-status only-low-status only-verylow-status">
          <label class="item-checkbox item-checkbox-icon-start item-content">
            <input type="checkbox" name="demo-checkbox" value="40_done" ${event.status=='38_reopened' || event.status=='30_sent'?'checked':''} />
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
              <div class="item-title">Conferma la visita</div>
            </div>
          </label>
        </li>
        <li class="only-30-status">
          <label class="item-checkbox item-checkbox-icon-start item-content">
            <input type="checkbox" name="demo-checkbox" value="45_done_mod" />
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
              <div class="item-title">Conferma la vista con modifiche</div>
            </div>
          </label>
        </li>
        <li class="only-30-status">
          <label class="item-checkbox item-checkbox-icon-start item-content">
            <input type="checkbox" name="demo-checkbox" value="50_cancelled" />
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
              <div class="item-title">Visita annullata</div>
            </div>
          </label>
        </li>
      </ul>
    </div>
  `;
  jQuery('#dbmng').append(html);
  

  if(event.status=='30_sent'){
    jQuery('.only-low-status').hide();
    jQuery('.only-verylow-status').hide();
    jQuery('.only-30-status').show();
  }
  else if(event.status=='10_draft'){
    jQuery('.only-low-status').hide();
    jQuery('.only-30-status').hide();    
    jQuery('.only-verylow-status').show();
  }
  else{
    jQuery('.only-verylow-status').hide();
    jQuery('.only-30-status').hide();
    jQuery('.only-low-status').show();
  }


  // when i select one of the checkbox i want to disable the other
  jQuery('.list input[type="checkbox"]').on('change', function(){
    jQuery('.list input[type="checkbox"]').not(this).prop('checked', false);
  });

  
  jQuery('.list input[type="checkbox"]').on('change', function(){
    var status=jQuery(this).val();
    console.log(status);
    if(status=='45_done_mod'){
      jQuery('.dbmng_form_field_datetime_start .real_widget, .dbmng_form_field_datetime_end .real_widget').attr('disabled', false);
    }
    else{
      jQuery('.dbmng_form_field_datetime_start .real_widget, .dbmng_form_field_datetime_end .real_widget').attr('disabled', true);
    }

    if(status=='40_done' || status=='45_done_mod' || status=='38_reopened'){
      jQuery('#firme').show();
    }
    else{
      jQuery('#firme').hide();
    }
  });


  var html = `
  <div id="firme" style="${event.status=='38_reopened' || event.status=='30_sent'?'':'display:none;'}" class="list">
    <ul>
      <li>
        <div class="fs-photo block block-strong inset">
          ${getPhotoHtml(id_calendar)}
        </div>
      </li>

      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-title item-label">Georeferenziazione</div>
          <div class="item-input-wrap" style="text-align: center;">
            <div id="position_status">
              <img src="icons/loading.svg" height="50px"/>
            </div>
          </div>
        </div>
      <li>

      <li>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Firma consulente</div>
            <div class="row">
              <div class="col-90">
                <canvas style="border: 1px solid #efeff4;" id="firma_consulente"></canvas>
              </div>
              <div class="col-10" style="margin:auto;">
                <i id="clear_firma_consulente" class="f7-icons">arrow_uturn_left_circle</i>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li style="display:none;">
        <input type="hidden" id="event_lat" value="${event.lat}">
        <input type="hidden" id="event_lon" value="${event.lon}">
      </li>

      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-title item-label">Nome firmatario</div>
          <div class="item-input-wrap">
            <input id="nome_firmatario" type="text" placeholder="Nome firmatario" value="${event.nome_firmatario?event.nome_firmatario:''}">
            <span class="input-clear-button"></span>
          </div>
        </div>
      <li>
      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-title item-label">In qualità di</div>
          <div class="item-input-wrap">
            <select id="in_qualita_di"  placeholder="In qualità di">
              <option value="titolare">Titolare</option>
              <option value="familiare_titolare">Familiare titolare</option>
              <option value="coadiuvante">Coadiuvante</option>
              <option value="operaio">Operaio</option>
              <option value="dipendente_amm">Dipendente amm</option>
            </select>
            <span class="input-clear-button"></span>
          </div>
        </div>
      <li>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Firma dell'azienda</div>
            <div class="row">
              <div class="col-90">
                <canvas style="border: 1px solid #efeff4;" id="firma_azienda"></canvas>
              </div>
              <div class="col-10" style="margin:auto;">
                <i id="clear_firma_azienda" class="f7-icons">arrow_uturn_left_circle</i>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>  `;
  jQuery('#dbmng').append(html);

  if(event.in_qualita_di){
    jQuery('#in_qualita_di').val(event.in_qualita_di);
  }


  var images=event.images
  fillPhotos(id_calendar,images)

  jQuery('#event_lat, #event_lon').on('change', function(){
    var lat=jQuery('#event_lat').val();
    var lon=jQuery('#event_lon').val();
    if(lat && lon){
      jQuery('#position_status').html(`<input style="font-style: italic;" type="text"  disabled value="📍 Posizione acquisita correttamente" />`);
    }
  });


  gps.startGPS({
    buffer_max_size: 1,
    position_temp: function(pos){
      jQuery('#event_lat').val(pos.bufferedLatitude)
      jQuery('#event_lon').val(pos.bufferedLongitude).change();;
    },
    position: function(pos){
      jQuery('#event_lat').val(pos.bufferedLatitude)
      jQuery('#event_lon').val(pos.bufferedLongitude).change();;
    },
    error: function(error){
      alert("Non è stato possibile georeferenziare la posizione");
    }
  });


  var signaturePad1 = new SignaturePad(document.getElementById('firma_consulente'), {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    penColor: 'rgb(0, 0, 0)'
  });
  var signaturePad2 = new SignaturePad(document.getElementById('firma_azienda'), {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    penColor: 'rgb(0, 0, 0)'
  });

  if(event.firma_consulente){
    signaturePad1.fromDataURL(event.firma_consulente,{ ratio: 1 });
  }
  if(event.firma_azienda){
    signaturePad2.fromDataURL(event.firma_azienda,{ ratio: 1 });
  }

  jQuery('#clear_firma_consulente').on('click', function(){
    signaturePad1.clear();
  });
  jQuery('#clear_firma_azienda').on('click', function(){
    signaturePad2.clear();
  });


  var event_obj=event;

  jQuery('#footer').html(`<button id="save" class="button fs-button button-large button-fill button-raised">Salva</button>`); 

  jQuery('#save').on('click', function(){

    var s=storage.get();

    var data={
      firma_consulente: signaturePad1.toDataURL(),
      firma_azienda: signaturePad2.toDataURL(),
      nome_firmatario: jQuery('#nome_firmatario').val(),
      in_qualita_di: jQuery('#in_qualita_di').val(),
      status: jQuery('.list input[type="checkbox"]:checked').val(),
      lat: jQuery('#event_lat').val(),
      lon: jQuery('#event_lon').val(),
      sync: false
    }
    var data_dbmng=global.form.getValue();
    var data_merged = {...data_dbmng, ...data};

    console.log(data_merged);

    data_merged=photo.addImages(data_merged);

    console.log(event_obj);

    // debugger
    if (!event_obj.aCoords) {
      event_obj.aCoords = [];
    }

    event_obj.aCoords.push({
      lat: data_merged.lat,
      lon: data_merged.lon,
      datetime: new Date().toISOString()
    });

    data_merged.aCoords=event_obj.aCoords;

    // check if data_merged.datetime_end is after data_merged.datetime_start and difference is not more than 20 hours
    var date_start=new Date(data_merged.datetime_start);
    var date_end=new Date(data_merged.datetime_end);
    var diff=date_end-date_start;
    var diff_hours = Math.round((diff/1000)/3600);
    console.log(diff_hours);
    if(diff_hours>20){
      alert('La visita non può durare più di 20 ore');
      return;
    }

    if(id_calendar){
      var event=s.calendar.find(e=>e.id_calendar==id_calendar);
      console.log(event);
      jQuery.extend(event, data_merged);
      console.log(event);
      storage.save(s);
      db.lightSync();
      location.hash=('/misura21');
    }
    else{
      data_merged.id_calendar=tools.getGuid();
      data_merged.calendar_type="farm_visit";
      data_merged.description=jQuery('.dbmng_form_field_id_voce_azienda select').find('option:selected').text();
      data_merged.status="10_draft";

      s.calendar.push(data_merged);
      storage.save(s);
      db.lightSync();
      location.hash=('/misura21');
    }
  });
}


export function getPhotoHtml(id_calendar, images){
  var html='';
  html+="<div id='photo'>";
    html+='<p class="row hide-see-only">';
      if (!global.is_cordova()) {
        html+='<input style="display:none;" id="scatta_foto" class="scatta_foto scatta_foto_trigger col" type="file" accept="image/*"></input>';
        html+='<button id="scatta_foto_btn" class="scatta_foto col button button-large button-raised" type="file" accept="image/*"><span>'+t('Aggiungi')+'</span> <i class="icon f7-icons">camera_fill</i></button>';

      }
      else {
        html+='<button id="scatta_foto_camera" class="scatta_foto scatta_foto_trigger col button button-large button-raised" type="file" accept="image/*"><span>'+t('Aggiungi')+'</span> <i class="icon f7-icons">camera_fill</i></button>';
        html+='<button id="scatta_foto_gallery" class="scatta_foto scatta_foto_trigger col button button-large button-raised" type="file" accept="image/*"><span>'+t('Aggiungi')+'</span> <i class="icon f7-icons">photo_fill_on_rectangle_fill</i></button>';
      }
    html+='</p>';

    try {
      html+=photo.getPhotoDiv(id_calendar, images);
    }
    catch (e) {
      console.log(e);
    }
  html+="</div>";
  return html;
}

export function fillPhotos(id_calendar, images){
  
  photo.fillPhotoDiv(id_calendar, images);

  if (global.is_cordova()) {
    jQuery(".scatta_foto_trigger").click(function() {
      var soruce=jQuery(this).attr("id");
      if(soruce=="scatta_foto_camera"){
        photo.takePicture(id_calendar,null, Camera.PictureSourceType.CAMERA);
      }
      else if(soruce=="scatta_foto_gallery"){
        photo.takePicture(id_calendar,null, Camera.PictureSourceType.PHOTOLIBRARY);
      }
    });

  }
  else {
    jQuery("#scatta_foto").change(function() {
      photo.takePicture(id_calendar);
    });

    jQuery('#scatta_foto_btn').click(function(){
      // debugg
      jQuery("#scatta_foto").click();
    });
  }
}
