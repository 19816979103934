import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js';

export function feed_home(){
  var today_date=new Date();
  var one_month_ago=new Date() - 30*24*60*60*1000;
  
  // format dates in yyyy-mm-dd
  today_date=today_date.toISOString().slice(0,10);
  one_month_ago=new Date(one_month_ago).toISOString().slice(0,10);

  var html=`
    <div style="display:block;" id="filter_container">
    
      <div class="block-title block-title-medium" style="text-align: left;">Filtri</div>
      <div class="list list-strong list-dividers inset">
        <ul>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Dal</div>
              <div class="item-input-wrap">
                <input type="date" id="date_start" placeholder="Data inizio" value="${one_month_ago}">
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Al</div>
              <div class="item-input-wrap">
                <input type="date" id="date_end" placeholder="Data fine" value="${today_date}">
              </div>
            </div>
          </li>
          <li class="item-content item-input" id="escludi_miei">
            <div class="item-inner">
              <div class="item-title item-label">Escludi i miei</div>
              <div class="item-after">
                  <div class="toggle toggle-init">
                    <input id="escludi_mine" type="checkbox">
                    <span class="toggle-icon"></span>
                  </div> 
              </div>
            </div>
          </li>


          <li class="item-content item-input" id="solo_con_foto">
            <div class="item-inner">
              <div class="item-title item-label">Solo con foto</div>
              <div class="item-after">
                  <div class="toggle toggle-init">
                    <input id="con_foto_input" type="checkbox">
                    <span class="toggle-icon"></span>
                  </div> 
              </div>
            </div>
          </li>


          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Scegli la tipologia</div>
              <div class="item-input-wrap input-dropdown-wrap">
                <select placeholder="Please choose...">
                  <option value="0">Qualsiasi</option>
                  <option value="operation">Operazioni colturali</option>
                  <option value="observation">Monitoraggi</option>
                </select>
              </div>
            </div>
          </li>

          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Scegli la coltura</div>
              <div class="item-input-wrap input-dropdown-wrap">
                <select id="choose_crop">                
                  <option value="0">Tutte le colture</option>
                </select>
              </div>
            </div>
          </li>

        </ul>
      </div>

    </div>
    
    <div id="feed_message"></div>
    <div id="feed" style="text-align:center"><img src="icons/loading.svg" style="width:70%;" id="loading_chat" /></div>
  `;
  var page={
    exclude_card:true,
    title: t(""),
    content: html
  };

  global.theme.render(page);

  

  jQuery('#escludi_miei').unbind().click(function(){
    if (jQuery('#escludi_miei input').is(':checked')) {
      jQuery('#escludi_miei input').prop('checked', false).change();  
    } 
    else {
      jQuery('#escludi_miei input').prop('checked', true).change();
    }
  });

  jQuery('#solo_con_foto').unbind().click(function(){
    if (jQuery('#solo_con_foto input').is(':checked')) {
      jQuery('#solo_con_foto input').prop('checked', false).change();
    }
    else {
      jQuery('#solo_con_foto input').prop('checked', true).change();
    }
  });

  


  jQuery('#ae_navbar_right').html(`
    <a class="link icon-only" id="filter_button"><i class="icon f7-icons">slider_horizontal_3</i></a>
  `);

  jQuery('#filter_button').unbind().click(function(){
    jQuery('#filter_container').slideToggle(500);
  });

  ajax_feed();
}

function ajax_feed(){

  jQuery('#feed').html('<img src="icons/loading.svg" style="width:70%;" id="loading_chat" />');
  jQuery('#feed_message').html('');


  var start_date=jQuery('#date_start').val();
  var end_date=jQuery('#date_end').val();
  var exclude_mine=jQuery('#escludi_miei input').is(':checked');
  var with_image=jQuery('#solo_con_foto input').is(':checked');
  var type=jQuery('#filter_container select').val();
  var id_crop=jQuery('#choose_crop').val();

  let filter="?";

  if(id_crop != 0){
    filter += "&id_crop=" + id_crop;
  }
  if(start_date != ""){
    filter += "&date_from=" + start_date;
  }
  if(end_date != ""){
    filter += "&date_to=" + end_date;
  }
  if(exclude_mine){
    filter += "&exclude_mine=1";
  }
  if(type != 0){
    filter += "&type=" + type;
  }
  if(with_image){
    filter += "&with_image=1";
  }

  var s=storage.get();
  var urladdr=global.base_call+`feed/feed${filter}`;
  var headers={
    'Authorization': 'Bearer '+s.user.access_token,
    "Content-Type": "application/json",
  }
  jQuery.ajax({
    type: "GET",
    headers: headers,
    url: urladdr,
    dataType: "json",
    timeout:10000,
    success: function(data){
      console.log(data);

      jQuery("#filter_container select, #filter_container input").unbind().change(function(){
        ajax_feed();
      });

      if(data.ok) {
        if(data.warning){
          jQuery(".warning_space").html(`
            <div class="block block-strong inset">
              ${data.warning}
            </div>
          `);
        }
        else{
          jQuery(".warning_space").html('');
        }

        jQuery.each(data.crops.data, function( index, crop ) {
          jQuery("#choose_crop").append(`
            <option value="${crop.id_crop}">${crop.name}</option>
          `);
        });


        
        renderFeed(data);      
      }
      else{
        jQuery('#feed_message').html(`
          <div class="block block-strong inset">
            Errore: ${data.message}
          </div>
        `);
        jQuery('#feed').html('');
      }

    },
    error: function(xhr, status, error) {
      jQuery('#feed_message').html(`
        <div class="block block-strong inset">
          ${status}
        </div>
      `);
      jQuery('#feed').html('');
    }
  });
}

export function renderFeed(data){
  var posts=data.data;
  
  var feed_html='<div class="block-title block-title-medium" style="text-align: left;">Feed</div>';
  
  if(posts.length==0){
    feed_html+=`
      <div class="block block-strong inset">
        Nessun post trovato
      </div>
    `;
  }

  jQuery.each(posts, function( index, post ) {
    let feed=prepareFeed(post, data);
    let type=post.type;
    if(post.type=="operation"){
      type="Operazione colturale";
    }
    else if(post.type=="observation"){
      type="Monitoraggio";
    }
    else if(post.type=="animal"){
      type="Monitoraggio animale";
    }

    feed_html+=`
      <div class="card">
        <div class="card-header"><b>${post.farm_name}</b></div>
        
        <div class="card-content card-content-padding">
          ${feed.image}
          <p class="date" style="color:#8e8e93;">${feed.timeago_str} - ${post.user_name}</p>
          <p>${feed.crop_emoji} </p>
          <p>${feed.text}</p>
        </div>
        <div class="card-footer">
          <span>${type}</span>
          <span>${feed.scheda}</span>
        </div>
      </div>
    `;
  });

  jQuery('#feed').html(feed_html);
}

function prepareFeed(post, data){
  let image='';
  if(post.images && post.images.length > 0){
    image = `
      <div class="feed-image p-2 px-3">
        <img width=100% src="https://studio.poderi.app/static/uploads/${post.images[0]}.png">
      </div>
    `;
  }

  let date = new Date(post.update_timestamp);
  let time = date.toLocaleString();
  //write how many time ago

  let timeago_str="";
  let timeago = Math.floor((new Date() - date) / 1000);
  if(timeago < 60){
    timeago_str = timeago + " secondi fa";
  }
  else if(timeago < 3600){

    timeago_str = Math.floor(timeago / 60) + " minuti fa";
  }
  else if(timeago < 86400){
    timeago_str = Math.floor(timeago / 3600) + " ore fa";
  }
  else{
    timeago_str = Math.floor(timeago / 86400) + " giorni fa";
  }

  let crop_emoji = '';
  let crop_description = '';
  if(post.id_crops.length > 0){
    crop_emoji,crop_description = solveCropEmoji(post.id_crops, data.crops.data); 
  }

  let text=null;
  let js=post.json;

  let flds="";

  flds+="<b>Data</b>: "+post.json.date+" ";

    if(post.fields){
      if(post.fields.length>0){

        if(post.type=='animal'){
          flds+="<b>Capo</b>: "+post.fields[0];
        }
        else{

          if(post.fields.length==1){
            flds+="<b>Campo</b>: ";
          }
          else{
            flds+="<b>Campi</b>: "
          }
          jQuery.each(post.fields, function( index, value ) {
            flds+=value.field_name+", ";
          });

          flds=flds.slice(0,-2);
        }
      }
    }

    if(post.type!=='animal'){
      flds=flds+". <b>Coltura</b>: "+crop_description+".";
      flds+=` <b>Superfici</b>: ${(post.area/10000).toFixed(1)} ha.`;  
    }

  let scheda="....";
  if(post.type=='observation'){
    //decode scheda da data.survey_schema.data
    
    let scheda_data = data.survey_schema.data.find(x => x.id_survey_schema == post.id_form);
    scheda = scheda_data.name;

    if(post.id_form==1){

      let trap_name="";
      if(js.trap_name){
        trap_name=js.trap_name;
        trap_name+=` (${js.trap_type} - ${js.trap_pest})`;
      }

      text = `
        Sono stati catturati con la trappola ${trap_name} ${js.trap} insetti
      `;
    }
    else if(post.id_form==10){

      let attiva=0;


      text=`
        Sono state campionate ${js.tot} olive.
      `;

      let stadi=['u','l1v','l1m','l2v','l2m','l3v','l3m','pv','pm','fu'];
      jQuery.each(stadi, function( index, value ) {
        if(js[value]>0){
          text+=value+":"+js[value]+", ";
        }
      });
    }
    else if(post.id_form==12){
      text=`
        Insetti catturati: ${js.catture}.
      `;
    }
    else{
      text=decodeJsonMessage(post, scheda_data);          
    }


    text=text;
  }
  else if(post.type=='animal'){
    //decode scheda da data.animal_schema.data


    if(post.json.type=='delivery'){
      scheda="Parto";

      text=`
        Parto di ${post.fields?post.fields[0]:"capo sconosciuto"} avvenuto il ${post.json.date} con ${post.json.numbers_f?post.json.numbers_f:"0"} femmine e 
        ${post.json.numbers_m?post.json.numbers_m:0} maschi. ${post.json.death?post.json.death+"agnelli morti":" Nessun morto."} 
      `;
    }
    else if(post.json.type=='eco'){
      scheda="Ecografia";
      text=`
        Ecografia di ${post.fields?post.fields[0]:"capo sconosciuto"} effettuata il ${post.json.date} 
        - Esito: ${post.json.result?post.json.result:"Sconosciuto"}.  ${post.json.gg?"Stima della nascita in "+post.json.gg+" giorni.":""}
      `;
    }
    else if(post.json.type=='latte'){
      scheda="Lattazione";

      if(post.json.in_out=='in'){
        text=`
          ${post.json.date} - Inizio lattazione di ${post.fields?post.fields[0]:"capo sconosciuto"}.
        `;
      }
      else if(post.json.in_out=='out'){
        text=`
          ${post.json.date} - Fine lattazione di ${post.fields?post.fields[0]:"capo sconosciuto"}.
        `;
      }
      else{
        text=`
          ${post.json.date} -  ${post.fields?post.fields[0]:"capo sconosciuto"}.
        `;
      }
    }
    else if(post.json.type=='health'){
      scheda="Salute";

      text=`
        ${post.json.date} - ${post.fields?post.fields[0]:"capo sconosciuto"} Si segnala ${post.json.type_health?post.json.type_health:"nessuna malattia"}.
      `;
    }
    else{
      scheda=post.json.type;
      text=JSON.stringify(post.json);
    
    }
  }
  else if(post.type=='operation'){
    //decode scheda da data.crop_operation
    let scheda_data = data.crop_operation.data.find(x => x.id_crop_operation == post.id_form);
    scheda = scheda_data.name;
        
    if(post.id_form==2){
      text=`
        Irrigazione effettuata il ${js.date} con ${js.water} mm/ha.
      `;
    }
    else if(post.id_form==3){
      text=`
        Concimazione effettuata il ${js.date} con <b>"${js.product_name}"</b> di ${js.amount_ha}/ha  per un totale di ${js.amount_tot} kg.
      `;
    }
    else if(post.id_form==4){
      text=`
        Concimazione organica effettuata il ${js.date} con <b>"${js.product_name}"</b> di ${js.amount_ha}/ha  per un totale di ${js.amount_tot} kg.
      `;
    }
    else if(post.id_form==5){
      text=`
        Effettuato il  ${js.date} sovescio di ${js.coltura} con data interramento il ${js.data_interramento}.
      `;
    }
    else if(post.id_form==6){
      text=`
        POtatura effettuata il   ${js.date} ${js.potatura} .
      `;
    }
    else if(post.id_form==7){
      text=`
        Raccolta effettuata il ${js.date} con una produzione totale di ${js.amount_tot} ${decodeUOM(js.uom)} ed una produzione ettaro di ${js.amount_ha} ${decodeUOM(js.uom)}/ha.
      `;
    }
    else if(post.id_form==8){
      text=`
        Lavorazione effettuata il ${js.date} di tipo ${js.tillage?js.tillage:"non comunicato"} ad una profondità  ${js.depth?"di"+js.depth+"cm":"sconosciuta"}
      `;
    }
    else if(post.id_form==10){
      text=`
        Trattamento effettuato il ${js.date} con ${js.nome_prodotto} con dose/ha ${js.dose_ha} .
      `;
    }
    else{
      text=decodeJsonMessage(post, scheda_data);          
    }
  }


  text+="<p class='mb-1'>"+flds+"</p>";

  if(js.notes && js.notes.length>0){
    text+=`<p class='mb-0'> <b>Note</b>: ${js.notes}</p>`;
  }
  
  

  text="<p class='mb-1' title='"+JSON.stringify(post.json)+"'>"+text+"</p>";

  return {
    scheda:scheda,
    timeago_str:timeago_str,
    image:image,
    text:text,
    crop_emoji:crop_emoji,
  };
}

function solveCropEmoji(id_crops, crops){
  let emoji = '';
  let descriprion = '';
  for(const id_crop of id_crops){
    for(const crop of crops){
      if(crop.id_crop == id_crop){
        emoji += crop.emoji;
        descriprion += crop.name+", ";
      }
    }
  }
  if(descriprion.length > 1){
    descriprion = descriprion.substring(0, descriprion.length - 2);
  }
  return emoji, descriprion;
}

function decodeUOM(uom) {
  if(uom==1)  {
    return "kg";
  }
  else if(uom==100)  {
    return "q";
  }
  else if(uom==1000)  {
    return "t";
  }
  else{
    return "";
  }
}

function decodeJsonMessage(post, form){


  let text="";

  let exlude_fields=[
    "id_farm_center",
    "id_field",
    "id_observation",
    "id_operation",
    "notes",
    "date",
    "sync",
    "trap_name",
    "trap_type",
    "trap_pest",
    "id_fields",
    "id_survey_schema",
    "images"
  ]
  jQuery.each(post.json, function( index, value ) {
    if(exlude_fields.indexOf(index)==-1){
      if(value!==null && value!==undefined && value!==""){
        text+="<b>"+index+"</b>"+": "+value+", ";
      }
    }
    
  });
  return text;
}