/*jshint esversion: 6 */
import './style.css';

import '../fairshare_style.css';
import storage from '../../../storage.js';
import global from '../../../global.js';
import dbmng from '../../../ext/dbmng.js';
import notification from '../../../core/notification.js';
import * as fs_chat from '../fs_chat.js';


// ---- file duplicato da ripulire ----
import * as partecipa from '../../../core/partecipa/partecipa_new.js';
// Se serve GPS
//*********************************************************************
import gps from '../../../core/gps.js';
//*********************************************************************


// TRADUZIONE
//*********************************************************************
import t from '../../../translate.js';
//*********************************************************************

import * as db from '../data.js';
import fairshare_routes from '../fairshare_routes.js';

// Se serve LEAFLET
//*********************************************************************
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

import 'leaflet.markercluster/dist/leaflet.markercluster-src.js';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
//*********************************************************************


// Variabili globali
//*********************************************************************
global.useSocialLogin=false;
global.skip_classic_login=true;

let redirect_uri='poderiapp://a';
if(!global.is_cordova()){
  redirect_uri=location.origin;
}

global.custom_scheme_url='poderiapp://';

global.use_oauth2={
  "label": "Poderi Access",
  "client_id": "poderi",
  "scope": "offline_access",
  "client_secret": "znMHGiVQjy1lU6nGRSJ8ud8H0OxLO39N",
  "redirect_uri": redirect_uri,
  "endpoint": "https://login.aedit.it/realms/poderi/protocol/openid-connect/"  
}

if(!global.app_version){
  global.app_version='1.1.69';
}
global.icon='icons/fairshare.png';

global.base_link='http://localhost:8080/';
global.base_path='http://localhost:9135/';
global.model_path='http://localhost:9135/api/v1/poderi/model/';
global.images_link='http://localhost:8888/static/uploads/';
global.chat_url='http://localhost:9135/';
 
if (location.origin!='http://localhost:8080' && location.origin!='http://localhost:8081') {
  global.chat_url='https://studio.poderi.app/';
  global.base_link='https://poderi.app/';
  global.base_path='https://studio.poderi.app/';
  global.model_path='https://fairshare-staging.aedit.it/api/';

}
global.images_link='https://studio.poderi.app/static/uploads/';

// global.chat_url=global.base_path;
// global.chat_url='https://chat-staging.aedit.it/';
// global.chat_url='http://192.168.213.161:9135/';

global.base_call=global.base_path+'api/v1/poderi/';
global.base_call_aedita=global.base_path;
global.nomeApp='Poderi';
// global.useSocialLogin=true;
global.language='it';
global.settingsName='fairshare';
//*********************************************************************

global.firebaseConfig = {
  apiKey: "AIzaSyBinMrUIrfGwJ2tdjvTqS4kASC1RMrd464",
  authDomain: "fairshare-61fe4.firebaseapp.com",
  projectId: "fairshare-61fe4",
  storageBucket: "fairshare-61fe4.appspot.com",
  messagingSenderId: "309967651300",
  appId: "1:309967651300:web:0740d19ad535d7d72047f1",
  measurementId: "G-MN71QK8PT2"
};
// debugger
// if (typeof firebase!=='undefined') {

if(!global.is_cordova()){
  global.firebase=firebase.initializeApp(global.firebaseConfig);
  global.messaging = null;
  
  if (firebase.messaging.isSupported()){
    global.messaging = firebase.messaging();
  }
}
// }


if (notification) {
  global.notification=notification;
}

notification.default_notify();



//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  router=partecipa.exe_routes(router);
  router=fairshare_routes(router);

  return router;
}

export function tools(){
  var s=storage.get();
  if(s.user && s.user.uid && s.user.first_login){
    var fist_login=new Date(s.user.first_login);
    // data tipo 22 marzo 2021
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    fist_login=fist_login.toLocaleDateString('it-IT', options);


    var html=`
      <div class="block block-strong block-outline inset">
        <p>Prima registrazione: <b>${fist_login}</b></p>
      </div>
    `;
  
    // Fatto volonatariamente per avere solo i tools custom
    jQuery('#appSettings').html(html);
  }
// partecipa.partecipa_tools();
}

// function cleanPage(){
//   var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">'+('Back')+'</span></a>';
//   jQuery('.left').html(html);

//   jQuery('#download_app').hide();

//   jQuery('#footer').parent().show();
//   jQuery('#footer').html('');
// }

export function home_page(){


  console.log("HOME PAGE");
  var s=storage.get();
  console.log(s);
  var title='Poderi';

  if(s.user && !s.user.refreshUser) {
    if (s.id_access_to_grant) {
      location.hash='grant_access/'+s.id_access_to_grant;
    }
    else if (s.farms && s.farms.length>0) {
      var farm_role='';
      jQuery.each(s.farms,function(k,v){
        if (farm_role=='' || farm_role=='azienda') {
          farm_role=v.farm_role;
        }
      });

      if (farm_role=='azienda') {
        if (s.farms.length==1) {
          location.hash='farm/'+s.farms[0].id_farm_center;
        }
        else {
          // Todo: va bene prendere il primo campo? O meglio l'ultimo usato?
          location.hash='farm/'+s.farms[0].id_farm_center;
          // alert(`Evvai azienda! Ho trovato ${s.farms.length} aziende collegate a te`);
        }
      }
      else {

        location.hash='farms';
      }
    }
    else {

     

        // global.theme.render({
        //   exclude_card:true,
        //   title: ` Loading...`,
        //   content: `
        //     Stiamo caricando...<br>
        //   `
        // });
       
        // Nessuna azienda è stata trovata per l'utente. Nè come agronomo né come azienda, facciamo scegliere all'utente che tipologia di utente sia
        location.hash='start';
       
    }
  }
  else {
    location.hash='login';
  }
}

export function get_theme_param(theme_param){

  var s=storage.get();
  if (s.user) {

    var explorer=false;
    jQuery.each(s.user.roles,function(k,v){
      if(v=='explorer'){
        explorer=true;
      }
    });
  }


  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},    
    {'label': 'Credits', 'icon_f7':'info_circle', 'href':'#credits'},
  ];

  if(s.user && s.user.uid){
    sidebars.push({'label': t('Feed'), 'icon_f7':'today', 'href':'#feed'});
    sidebars.push({'label': t('Gestisci le aziende'), 'icon_f7':'building_2_fill', 'href':'#farms'});
    sidebars.push({'label': t('Scarica report'), 'icon_f7':'tray_arrow_down_fill', 'href':'#reports'});
    sidebars.push({'label': t('Parla con Poderi'), 'icon_f7':'mic', 'href':'#copilot'});
  }

  //Funzioni solo per l'utente avanzato
  if(explorer){
    sidebars.push({'label': 'Poderi Explorer', 'icon_f7':'eye_fill', 'href':'#explorer'});
    sidebars.push({'label': 'Contatta le aziende', 'icon_f7':'envelope_badge', 'href':'#advice/new'});
  }

  if(s.user && s.user.uid){
    if(s.user.feature_flag && s.user.feature_flag.length>0){
      jQuery.each(s.user.feature_flag,function(k,v){
        if(v.id_feature=='misura21' && v.is_enabled==1){
          sidebars.push({'id':'sidebar_misura21','label': 'Visite aziendali', 'icon_f7':'calendar', 'href':'#misura21'});
        }
        else{
          sidebars.push({'id':'sidebar_misura21','label': 'Visite aziendali', 'icon_f7':'calendar', 'href':'#misura21', 'visible':false});
        }
      });
    }
    else{
      sidebars.push({'id':'sidebar_misura21','label': 'Visite aziendali', 'icon_f7':'calendar', 'href':'#misura21', 'visible':false});
    }

    sidebars.push({'label': 'Poderi chat', 'icon_f7':'chat_bubble_2_fill', 'href':'#chat'});
    sidebars.push({'html': `
      <div class='custom_farm_menu'>
        <h3><div style="font-size: 20px; margin-bottom: -20px;" class="block-title" id='custom_farm_name'>...</div></h3>
      </div>
    `});


    sidebars.push({'label': t('Bluetooth'), 'icon_f7':'antenna_radiowaves_left_right', 'href':'#bluetooth_new','class':'custom_link_farm_bt'});    
    sidebars.push({'label': t("Modifica l'azienda"), 'icon_f7':'building', 'href':'#farm_edit','class':'custom_link_farm_edit'});
    sidebars.push({'label': t("Stazione meteo"), 'icon_f7':'cloud_sun_rain_fill', 'href':'#ws','class':'custom_link_ws'});
    sidebars.push({'label': t("Magazzino"), 'icon_f7':'shippingbox', 'href':'#farm_warehouse','class':'custom_link_warehouse'});

    sidebars.push({'label': t('Marketing'), 'icon_f7':'qrcode_viewfinder', 'href':'#marketing','class':'custom_link_marketing'});
    
    sidebars.push({'label': t('Accessi all\'azienda'), 'icon_f7':'person_3_fill', 'href':'#access','class':'custom_link_access'});
    sidebars.push({'label': t('Analisi del suolo'), 'icon_f7':'triangle_righthalf_fill', 'href':'#soil','class':'custom_link_soil'});


  }


  var s=storage.get();
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;

    var showPartecipa=false;
    if (showPartecipa) {
      sidebars.push({'label': 'Partecipa', 'icon_f7':'doc_append', 'href':'#partecipa'});
    }
  }

  theme_param.sidebars=sidebars;

  theme_param.title='<img src="'+global.icon+'"  height="35" />';
  theme_param.ptr=false; // attiva/disattiva pull to refresh

  return theme_param;
}

export function synchronize(redirect_url){
  db.sync(function(){
    console.log("Sync");
    if(redirect_url){
      location.hash=redirect_url;
      location.reload();
    }
    else{
      location.hash='';
      location.reload();
    }
  },
  function(e){
    console.log(e);
  });
}



export function notification_received(nn){
  fs_chat.notification_received(nn);
}


//Funzione custom eseguita dopo aver svuotato l'utente e prima di fare il logout sul server
export function doLogout(){

  //La funzione svuota tutto ma occorre prima fare la sincronizzazione

  // var s=storage.get();
  // var deviceData={
  //   id_device:s.id_device,
  //   platform:s.platform,
  //   model:s.model,
  //   version_os:s.version_os,
  //   version:s.version,
  //   app_code:s.app_code,
  //   notification_token:s.notification_token
  // }
  
  // storage.save(deviceData);
    
}