
import global from '../../global.js';
import * as tools from '../../tools.js';
import * as db from './data.js';
import *  as fs_lib from './fairshare.js';
import storage from '../../storage.js';
import t from '../../translate.js';
import photo from '../../core/photo.js';
import * as fairshare_oo from './fairshare_oo.js';

import * as c3 from 'c3';
import 'c3/c3.css';
import s from 'signature_pad/dist/signature_pad.min.js';




export function homeZoo(farm_data,id_farm_center,id_season){

  let zoos=farm_data.farm_center[0].zoo;

  let species = '';
  var selected='selected'
  var first_el=t(zoos[0]);
  jQuery.each(zoos,function(k,v){

    species+=`<option value="${v}" ${selected}>${t(v)}</option>`;
    if(selected!=''){
      selected='';
    }
  });

  
  var zoo=`
  <div class="block-title block-title-medium">${t('Allevamenti')}</div>
  <div class="list inset">
    <ul>
      <li>
        <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">
          <select id="species">
            ${species}
          </select>

          <div class="item-content">
            <div class="item-inner" style="width: fit-content;">
              <div class="item-title"></div>
              <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${first_el}</div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div id="res_table" class="card"></div>
  `;

  jQuery('#plot_zoo').html(zoo);


  jQuery('#species').on('change',function(){
    var specie=jQuery(this).val();
    var data=db.getZoo(id_farm_center,specie);

    data.id_farm_center=id_farm_center;
    var table=zooTable(data,specie);
    
  }).trigger('change');
}

//Stampa la tabella degli animali
function zooTable(data, specie){

  let alert='';
  if(specie!=='sheep'){
    alert=` 
        <div class="block block-strong color-orange">
          <p><b>Attenzione!</b> ${t('Questa funzione attualmente è stata ottimizzata solo per gli ovini.')}</p>
        </div>
     `;
  }



  let table = `
    
    ${alert}

    ${anagraficaHomeCard()}
    ${gruppiHomeCard(data.id_farm_center)}
    ${partiHomeCard()}

    <div class="card" id="milk"></div>
  `;
  jQuery('#res_table').html(table);

  var s=storage.get();
  addQualityCard(s, data.id_farm_center);


  drawAnag(data.id_farm_center, data);


  jQuery('#open_anag').click(function(){
    location.hash="#farm/"+data.id_farm_center+"/zoo/"+specie+"/anagrafica";
  });

  jQuery('#open_group').click(function(){
    location.hash="#farm/"+data.id_farm_center+"/zoo/"+specie+"/gruppi";
  });

  jQuery("#footer").html(`
    <button onclick="location.hash='farm/${data.id_farm_center}/zoo/${specie}/anagrafica/new'" class="button fs-button button-large button-fill " style="margin: 0px 5px 0px 5px;">
      ${t('Aggiungi un capo')}
    </button>    
  `);

}



//Presa da poderi
export function anagraficaHomeCard(){
  var html='';
  html+='<div id="anagrafe" class="card">';
    html+='<div class="card-header">'+t('anagrafe')+'</div>';
    html+='<div class="card-content card-content-padding">';
      html+='<div style="display:none;" class="icons_animals row">';
        html+='<div class="col-25">';
          html+='<img src="icons/zoo/f_adult.svg" width="100%"/>';
          html+='<h3 id="f_adult" class="central"></h3>';
        html+='</div>';
        html+='<div class="col-25">';
          html+='<img src="icons/zoo/m_adult.svg" width="100%"/>';
          html+='<h3 id="m_adult" class="central"></h3>';
        html+='</div>';
        html+='<div class="col-25">';
          html+='<img src="icons/zoo/f_baby.svg" width="100%"/>';
          html+='<h3 id="f_baby" class="central"></h3>';
        html+='</div>';
        html+='<div class="col-25">';
          html+='<img src="icons/zoo/m_baby.svg" width="100%"/>';
          html+='<h3 id="m_baby" class="central"></h3>';
        html+='</div>';
      html+='</div>';


      html+=`
        <div style="display:none; margin-top: 0;" class="list links-list icons_animals">
          <ul>
            <li>
              <a id="open_anag"><span style="text-align: end;">${t('access_anagrafica')}</span></a>
            </li>
          </ul>
        </div>
      `;

      

    html+='</div>';
  html+='</div>';
  return html;

}


//Crea le card per i parti
export function gruppiHomeCard(id_farm){
  // var html='';
  // html+='<div id="parti" class="card">';
  //   html+='<div class="card-header">Parti</div>';
  //   html+='<div class="icons_parti card-content card-content-padding">';
  //   // html+='<div class="block-title">Customization</div>';
  //     // html+='<div class="block block-strong">';
  //       html+='<div class="row">';
  //       html+='<div class="col text-align-center">';
  //         html+='<div class="mesi-36 gauge gauge-init"></div>';
  //       html+='</div>';
  //         html+='<div class="col text-align-center">';
  //           html+='<div class="mesi-24 gauge gauge-init"></div>';
  //         html+='</div>';
  //         html+='<div class="col text-align-center">';
  //           html+='<div class="mesi-12 gauge gauge-init"></div>';
  //         html+='</div>';
  //       html+='</div>';

  //       html+='<div class="row">';
  //       html+='<div class="col text-align-center">';
  //         html+='<div class="text-parti text-parti-mesi-36 gauge gauge-init"></div>';
  //       html+='</div>';
  //         html+='<div class="col text-align-center">';
  //           html+='<div class="text-parti text-parti-mesi-24 gauge gauge-init"></div>';
  //         html+='</div>';
  //         html+='<div class="col text-align-center">';
  //           html+='<div class="text-parti text-parti-mesi-12 gauge gauge-init"></div>';
  //         html+='</div>';
  //       html+='</div>';

  //   html+='</div>';
  // html+='</div>';

  var groups=db.get_elements_filtered('animal_group', id_farm);



  var groups_html='';
  if (groups.length>0) {
    jQuery.each(groups,function(k,v){

      var members=db.getAnimalGroupMembers(id_farm, v.id_animal_group);
      var num_members='';

      if (members.length>0) {
        if (members.length==1) {
          num_members=members.length+' animale';
        }
        else{
          num_members=members.length+' animali';
        }
      }
      else{
        num_members='Nessun animale';
      }



      

      groups_html+=`
        <div class="row">
          <div class="col-50" style="display: ruby;">
            <div class="circle" style="background:${v.color}"></div>
            <h3>${v.name}</h3>
          </div>
          <div class="col-50">
            <h3>${num_members}</h3>
          </div>
        </div>
      `;
    });
  }


  let html=`
    <div id="open_group" class="card">
      <div class="card-header">${t('group')}</div>
        <div class="card-content card-content-padding">
          ${groups_html}
        </div>
        <div style="display:none; margin-top: 0;" class="list links-list icons_animals">
          <ul>
            <li>
              <a><span style="text-align: end;">${t('group_anagrafica')}</span></a>
            </li>
          </ul>
        </div>
        
      </div>
    </div>
  `;

  return html;
}

//Crea le card per i parti
export function partiHomeCard(){
  var html='';
  html+='<div id="parti" class="card">';
    html+='<div class="card-header">Parti</div>';
    html+='<div class="icons_parti card-content card-content-padding">';
    // html+='<div class="block-title">Customization</div>';
      // html+='<div class="block block-strong">';
        html+='<div class="row">';
        html+='<div class="col text-align-center">';
          html+='<div class="mesi-36 gauge gauge-init"></div>';
        html+='</div>';
          html+='<div class="col text-align-center">';
            html+='<div class="mesi-24 gauge gauge-init"></div>';
          html+='</div>';
          html+='<div class="col text-align-center">';
            html+='<div class="mesi-12 gauge gauge-init"></div>';
          html+='</div>';
        html+='</div>';

        html+='<div class="row">';
        html+='<div class="col text-align-center">';
          html+='<div class="text-parti text-parti-mesi-36 gauge gauge-init"></div>';
        html+='</div>';
          html+='<div class="col text-align-center">';
            html+='<div class="text-parti text-parti-mesi-24 gauge gauge-init"></div>';
          html+='</div>';
          html+='<div class="col text-align-center">';
            html+='<div class="text-parti text-parti-mesi-12 gauge gauge-init"></div>';
          html+='</div>';
        html+='</div>';

    html+='</div>';
  html+='</div>';
  return html;
}


//Disegna la home con agarafica e parti
function drawAnag(id_farm, data){

  var herd={
    m_adult:0,
    f_adult:0,
    m_baby:0,
    f_baby:0
  };


  var html='';
  if (data.animal) {

    jQuery('.icons_animals').show();

    jQuery.each(data.animal,function(k,v){
      var age=getAge(v.date_birth);
      var years=age.years;
      var months=age.months;


      var status="";
      if (years>0 || (years==0 && months>6)) {
        status="adult";
        if (v.sex=='M') {
          herd.m_adult ++;
        }
        else if (v.sex=='F') {
          herd.f_adult ++;
        }
      }
      else {
        status="baby";
        if (v.sex=='M') {
          herd.m_baby ++;
        }
        else if (v.sex=='F') {
          herd.f_baby ++;
        }


      }


      console.log(herd);

    });

    var all_animal=jQuery.merge( jQuery.merge( [], data.animal ), data.recently_deleted);

    // console.log("DIEGOPRE", all_animals)
    calcolaParti(all_animal ,herd.f_adult);
  }


  jQuery.each(herd,function(k,v){
    jQuery('#'+k).html(v);
  });

}



function calcolaParti(animals,pecore){

  var num_mesi=[36, 24, 12];
  var partiXpecoraAttesi=[4.5, 3 ,1.5];
  // var num_mesi=[24];

  jQuery.each(num_mesi,function(i,d){


    //Elenco delle nascite e dei parti.
    //Le nascite possono essere segnate in due modi, come evento o come nuovo agnello nato
    var parti=[];
    var nascite=[];

    var checkDate=addToMonth(null,-d);
    checkDate=new Date(checkDate).getFullYear()+"-"+addZero(new Date(checkDate).getMonth()+1)+"-"+addZero(new Date(checkDate).getDate());

    console.log("DiegoMesi",d, checkDate);

    //Il "metodoParti" considera gli agnelli degli eventi (anche se non segnati come nuovi agnelli in anagrafica)
    var method_parti=true;
    if(method_parti){

      //metodo che crea i parti dalle segnalazioni di event delivery
      jQuery.each(animals,function(k,v){
        if(v.json){

          var json=v.json;
          if (typeof v.json=='string') {
            json=JSON.parse(v.json).events;
          }
          else{
            json=v.json.events;
          }
          jQuery.each(json,function(ke,event){

            if(event.type=='delivery'){
              //Scegli solo le date corrette
              if(checkDate<event.date){
                var aParti={'source': 'events', 'guid_mother': v.id_animal, 'date_birth': event.date};
                parti.push(aParti);

                var num_tot=event.number_m+event.number_f;
                for(var i=0; i<num_tot; i++){
                  var cod_agnello='xxxxx';
                  var aNascite={"source": "events",  "cod_agnello": cod_agnello, "date_birth": event.date, "guid_mother": v.id_animal};
                  nascite.push(aNascite);
                }
              }

            }
          });

        }
      });
    }


      //Scorre tutti gli animali per caricare degli agnelli messi manualmente
      jQuery.each(animals,function(k,v){
        //Vann filtrati solo gli agnelli nati in azienda da meno di x mesi
        if (v.type_in=='N' && v.date_birth>checkDate) {
          // console.log(d+"mesi "+v.date_birth);
          var aParti={'source': 'anag', 'guid_mother': v.guid_mother, 'date_birth': v.date_birth};

          //se sono stati messi già prima non li considera
          var found = false;
          for(var i = 0; i < parti.length; i++) {
            if (parti[i][v.guid_mother] == v.date_birth) {
              found = true;
              break;
            }
          }
          if (!found) {
            parti.push(aParti);
          }

          if(!method_parti){
            var aNascite={"source": "anag", "cod_agnello": v.id_animal, "date_birth": v.date_birth, "guid_mother": v.guid_mother};
            nascite.push(aNascite);
          }
        }
      });


    //Copntando abbiamo anche dei duplicati
    // console.log("DiegoParti", parti);
    var parti_unici={};
    //TODO Attenzione, dobbiamo verificare che non ci siano dei doppi ingressi (sia da events che da anag), oppure dei parti troppo ravvicinati
    jQuery.each(parti, function(k,p){
      var guid_mother=p.guid_mother;
      if(guid_mother==0 || guid_mother==null){
        guid_mother="mm"+k;
      }

      if(!parti_unici[guid_mother]){
        parti_unici[guid_mother]=[];
      }
      parti_unici[guid_mother].push(p.date_birth);
    });

    console.log("Diego",parti_unici);
    // debugger
    var partiUnici=0;
    jQuery.each(parti_unici, function(k,p){
      var old_date='2000-01-01';
      var pp=0;
      var dates=p.sort();
      jQuery.each(dates, function(k2,date){
        // console.log("confronta "+date+" con "+old_date);
        // console.log(diffDates(old_date,date));

        //Verifica che le date siano distanti almeno 3 mesi - 90GIORNI
        if(diffDates(old_date,date)>90){
          partiUnici=partiUnici+1;
        }
        old_date=date;
      });
    });

    console.log("Diego",partiUnici, pecore);

    var partiXpecora=partiUnici/pecore;

    var percentuale=partiXpecora/partiXpecoraAttesi[i];
    if(pecore===0){
      partiXpecora=0;
      percentuale=0;
    }

    // console.log(percentuale);

    var color='#2196f3';
    if(percentuale<0.33){
      color='#E53935'; //rosso
    }
    else if(percentuale<0.66){
      color='#FF9800';   // arancio
    }
    else{
      color="#2E7D32"; //verde
    }

    var demoGauge = global.app.gauge.create({
      el: '.mesi-'+d,
      type: 'semicircle',
      value: percentuale,
      size: 250,
      borderColor: color,
      borderWidth: 10,
      valueText: (percentuale*100).toFixed(0)+'%',
      valueFontSize: 60,
      valueTextColor: color,
      labelText: 'su '+d+' mesi',
      labelFontSize:35
    });

    jQuery('.text-parti-mesi-'+d).html(partiUnici+' parti');

    // console.log(parti);
    // console.log(nascite);
    // console.log(d+" mesi - "+partiXpecora);
    // console.log("-------------------------------");

  });

}


export function addZero(i) {
  if (i < 10) {
      i = "0" + i;
  }
  return i;
}



export function getAge(dateString) {
  var now = new Date();
  var today = new Date(now.getYear(),now.getMonth(),now.getDate());

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(dateString);

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var monthAge;

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob)
    monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow -monthDob;
  }

  var age = {
    years: yearAge,
    months: monthAge
  };

  return age;
}


function addToMonth( date, months ) {
  var d = new Date( date || new Date() );
  d.setMonth( d.getMonth() + (months || 0), d.getDate());
  return d;
}


function diffDates(oldDate,newDate){
  var date1 = new Date(oldDate);
  var date2 = new Date(newDate);
  var diffTime = Math.abs(date2 - date1);
  var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}


export function gruppi_home(id_farm, specie){
  console.log("gruppi_home",id_farm, specie);

  var groups=db.get_elements_filtered('animal_group', id_farm);
  console.log(groups);


  var html='';

  html+="<div style='margin-top:15px;' class='block-title block-title-medium central'>"+t('group')+"<br><span id='farm_name'></span></div>";

  if(groups.length==0){

    html+=`
    <div class="data-table data-table-init card">
      <div class="card-header">
        <div class="row">
          <h3>Nessun gruppo presente. <br> Crea adesso il primo gruppo! </h3>
        </div>
      </div>
    </div>

    <div style="margin-top:${window.innerHeight-460}px" class="arrow-down">
      <i style="margin: auto; font-size:80px;" class="f7-icons">arrow_down</i>
    </div>
    `;
  }
  else{
    html+=`
      <div class="data-table data-table-init card">
        <div class="card-header">
          <div class="row">
            <h3>Gruppi presenti</h3>
          </div>
        </div>
        <div class="card-content">
          <table id="group_table">
            <thead>
              <tr id="group">
                <th class="label-cell sortable-cell">Nome</th>
                <th class="label-cell sortable-cell">Colore</th>
                <th class="label-cell sortable-cell">Data inizio</th>
                <th class="label-cell sortable-cell">Data fine</th>
              </tr>
            </thead>
            <tbody id="group_tbody">
            </tbody>
          </table>
        </div>
      </div>
      `;
  }

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);


  jQuery.each(groups,function(k,v){
    var tr=`
      <tr class="actions-cell" id_group="${v.id_animal_group}">
        <td class="label-cell">${v.name}</td>
        <td class="label-cell"><div class="circle" style="background:${v.color}"></div></td>
        <td class="label-cell">${v.date_from?v.date_from:'---'}</td>
        <td class="label-cell">${v.date_to?v.date_to:'---'}</td>
      </tr>
    `;
    jQuery('#group_tbody').append(tr);

    jQuery('.actions-cell').click(function(){
      var id_group=jQuery(this).attr('id_group');
      location.hash="farm/"+id_farm+"/zoo/"+specie+"/gruppi/"+id_group;
    });



    
  });


  var button='<button id="aggiungi_gruppo" class="button button-fill">Aggiungi gruppo</button>';
  jQuery('#footer').html(button);

  jQuery("#aggiungi_gruppo").click(function() {
    location.hash="#farm/"+id_farm+"/zoo/"+specie+"/gruppi/new";
  });
}

export function single_gruop_view(id_animal_group, id_farm_center, id_specie){
  console.log("single_gruop_view",id_animal_group, id_farm_center, id_specie);

  var group=db.get_element_first('animal_group', id_animal_group,'id_animal_group');

  let existing_events=[];
  if(group.events){
    existing_events=group.events;
  }

  var s=storage.get();


  let forms=s.animal_custom_form;
  var html='';
  
  let tabs="";
    tabs+='<p class="custom_tabs segmented">';
    if (s.animal_custom_form) {
      jQuery.each(s.animal_custom_form,function(k,v){

        var isValid=false;
        if (v.valid_for=='G' ) {
            isValid=true;
        }
        if (isValid) {
          tabs+='<button id="'+v.custom_form+'" class="button button-outline">'+v.form_name+'</button>';
        }
      });
    }
    tabs+='</p>';

    tabs+='<div id="custom_form">';
    tabs+='</div>';
 

  html+=`
  <div class="block-title block-title-medium">${t('Gruppo')}</div>
  <div class="list inset">
    <ul>
      <li>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title
            item-label">Nome</div>
            <div class="item-input-wrap">
              <input id="nome" type="text" name="name" value="${group.name}" required validate>
            </div>
          </div>
        </div>
      </li>
      <li>
        <a class="item-link smart-select smart-select-init">
          <select id="color">
            <option value="#9c27b0">Viola</option>
            <option value="#ff9800">Arancione</option>
            <option value="#2196f3">Blu</option>
            <option value="#4caf50">Verde</option>
            <option value="#f44336">Rosso</option>
            <option value="#ffeb3b">Giallo</option>
            <option value="#795548">Marrone</option>
            <option value="#607d8b">Grigio</option>
          </select>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title
              ">Colore</div>
            </div>
          </div>
        </a>
      </li>
      <li>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title
            item-label">Data inizio</div>
            <div class="item-input-wrap">
              <input id="data_inizio" type="date" value="${group.date_from}" required validate>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title
            item-label">Data fine</div>
            <div class="item-input-wrap">
              <input id="data_fine" type="date" value="${group.date_to}" required validate>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div id="animal_group_form" class="data-table data-table-init card">
    <div class="card-header">
        <h3>Eventi del gruppo</h3>
    </div>
    <div class="card-content">
      ${tabs}
    </div>
  </div>

  <div id="members_table" class="data-table data-table-init card">
    <div class="card-header">
      <div class="row">
        <h3>Animali presenti nel gruppo</h3>
      </div>
    </div>

    <div class="card-header">
      <div style="display:contents;"><i class="f7-icons">search</i><input type="text" id="search" placeholder="${t('search_in_table')}" title="Digita qui"></div>
    </div>
    <!--
    -->
    <div class="card-content">
      <div class="block-header" id="subtitle_gruppi">Block Header</div>
      <table id="animal_table">
        <thead>
          <tr id="animal">
            <th class="label-cell sortable-cell">Codice</th>
            <th class="label-cell sortable-cell">Data nascita</th>
            <th class="label-cell sortable-cell">Sesso</th>
            <th class="label-cell sortable-cell">Razza</th>
            <th class="label-cell sortable-cell">Dal </th>
            <th class="label-cell sortable-cell">Giorni ad oggi</th>
          </tr>
        </thead>
        <tbody id="animal_tbody">
        </tbody>
      </table>
    </div>
  </div>

  `;
  html+='<div style="display:none;" class="timeline-block-group">';
  html+="<div class='block-title'>Elenco degli eventi</div>";
  html+='<div class="card">';
    html+='<div class="card-content card-content-padding">';
      html+='<div class="timeline"></div>';
    html+='</div>';
  html+='</div>';
html+='</div>';

  
  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  jQuery('#color').val(group.color);

  //Inizio gruppo
  var oForm=null;
  jQuery(".custom_tabs button").click(function() {
    jQuery('.custom_tabs button').removeClass('button-active');
    jQuery(this).addClass('button-active');

    var id=jQuery(this).attr('id');

    jQuery.each(forms,function(k,v){
      if (id==v.custom_form) {

        if(typeof v.json !=='undefined' ){
          jQuery('#custom_form').html('');
          var json=v.json;
          if(typeof json=='string'){
            json=JSON.parse(json);
          }
          var fields = json;
          var f = {};
          f.table_name = "klean";
          f.primary_key = Array('id');
          f.fields = fields;
          jQuery.each(f.fields, function(k1,v1){
            if(k1=='date'){
              v1.default=getTodayDate();
            }
          });

          //se uno dei fields contiene il campo "poderi_use_warehouse" alora aggiungi il voc_val
          jQuery.each(f.fields, function(k1,v1){

            if(v1.poderi_use_warehouse){

              let type_warehouse=v1.poderi_use_warehouse;

              //get the list of products enetred in the warehouse
              let prod=db.getWarehouse(id_farm_center).product.filter(function(p){
                return p.id_product_type==type_warehouse;
              });

              let voc_val_product=[];
              jQuery.each(prod, function(k,v){
                let o={};
                o[v.id_product]=v.name;
                voc_val_product.push(o);
              });

              v1.voc_val=voc_val_product;
              v1.widget='select';


            }
          });

          // Col tema Framework7Theme non salva bene i dati col getValue perché aggiungiamo dei div..  jQuery(this.widget)[0].firstElementChild.value
          // var theme_f7=new Dbmng.AppTheme();
          var theme_f7=new Dbmng.Framework7Theme();

          oForm = new Dbmng.Form ({'aForm':f, theme:theme_f7});
          jQuery('#custom_form')[0].appendChild(oForm.createForm({}));

          global.oForm=oForm;
          jQuery('#custom_form').find('.label-form').addClass('field_separator');
        }
      }
    });
  });
  //Fine gruppo

  var animals=db.getAnimalGroupMembers(id_farm_center, id_animal_group);

  console.log(animals);

  if(animals.length==0){
    jQuery('#members_table').html(`
      <div class="card-header">
        <div class="row">
          <h3>Non ci sono animali presenti in questo gruppo</h3>
        </div>
      </div>
    `);
  }

  var avg_days=[];
  var animals_array=[];
  jQuery.each(animals,function(k,v){

    var animal=db.get_element_first('animal', v.id_animal, 'id_animal');
    console.log(animal);

    animals_array.push(animal);

    var daysCount=diffDates(v.date_from, new Date());

    avg_days.push(daysCount);

    var tr=`
      <tr class="actions-cell" id_animal="${v.id_animal}">
        <td class="label-cell">${animal.code}</td>
        <td class="label-cell">${animal.date_birth}</td>
        <td class="label-cell">${animal.sex}</td>
        <td class="label-cell">${animal.cod_breed}</td>
        <td class="label-cell">${v.date_from}</td>
        <td class="label-cell">${daysCount}</td>
      </tr>
    `;

    jQuery('#animal_tbody').append(tr);

    jQuery('.actions-cell').click(function(){
      var id_animal=jQuery(this).attr('id_animal');
      location.hash="farm/"+id_farm_center+"/zoo/"+id_specie+"/anagrafica/view/"+id_animal+"/from_group";
    });



  });
  
  jQuery('#search').on('keyup',function(){
    var value=jQuery(this).val().toLowerCase();
    jQuery('#animal_tbody tr').filter(function(){
      jQuery(this).toggle(jQuery(this).text().toLowerCase().indexOf(value)>-1);
    });
  });


  const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

  var avg_days_result=average(avg_days);
  // debugger
  jQuery('#subtitle_gruppi').html(`Media giorni di permanenza: ${avg_days_result.toFixed(0)}`);

  var button='<button id="salva" class="button button-fill color-green">Salva</button>';
  jQuery('#footer').html(button);

  if (existing_events.length>0) {
    jQuery('.timeline-block-group').show();
    var timeline='';
    
    existing_events=existing_events.sort(function(a,b){
      return new Date(b.date) - new Date(a.date);
    });

    jQuery.each(existing_events,function(k,v){

      if(v.toDelete){
        timeline+="";
      }
      else{
        timeline+='<div class="timeline-item">';
          timeline+='<div class="timeline-item-date">'+getTimelineDate(v.date)+'</div>';
          timeline+='<div class="timeline-item-divider"></div>';
          //todo  lLEAN PERCHE C'ÈERA SCRITTO 125PX???
          // timeline+='<div class="timeline-item-content" style="width:125px;">';
          timeline+='<div class="timeline-item-content" style="width:100%;">';
            jQuery.each(forms,function(i,d){
              if (d.custom_form==v.type) {
                timeline+='<div class="timeline-item-title">'+d.form_name+'<div class="timeline-item-icon" style="color: #bbb;float:right;margin-top: 7px;">';
                //TODO fare l'update
                timeline+='<i id="'+k+'" style="padding-right:20px;"" class="update-event f7-icons">pencil</i>';
                timeline+='<i id="'+k+'" class="delete-event f7-icons">trash</i>';
                timeline+='</div></div>';
              }
            });

            
            if (v.type=='custom_milk') {

              console.log("Milk",v);

              var label="litri";
              if(v.amount==1){
                label="litro";
              }

              timeline+='<div class="timeline-item-subtitle">'+v.amount+' '+label+'</div>';
            }
            else if (v.type=='razione') {
              console.log("Razione",v);
              var sub="";
              jQuery.each(v,function(k2,v2){
                if(k2=='amount'){
                  sub=sub+v2+" Kg<br/>";
                }
                else if(k2=='codice_razione'){
                  let razione=db.getWarehouse(id_farm_center).product.filter(function(p){
                    return p.id_product==v2;
                  });
                  razione=razione[0];

                  console.log("Razione iesima",razione);

                  sub=sub+"Razione: "+razione.name+"<br/>";
                }
              });
              timeline+='<div class="timeline-item-subtitle">'+sub+'</div>';

            }
            else if(v.type=='sanitary_group'){
              console.log("Sanitary",v);
              var sub="";
              jQuery.each(v,function(k2,v2){
                if(["id_event","type","date"].indexOf(k2)==-1){
                  sub=sub+v2+"<br/>";
                }
              });
              timeline+='<div class="timeline-item-subtitle">'+sub+'</div>';
            } 
            else {
              console.log("Event",v);

              var sub="";
              jQuery.each(v,function(k2,v2){
                if(["guid","type","date"].indexOf(k2)==-1){
                  sub=sub+v2+"<br/>";
                }
              });
              timeline+='<div class="timeline-item-subtitle">'+sub+'</div>';
            }
          timeline+='</div>';
        timeline+='</div>';
      }
    });
    jQuery('.timeline').html(timeline);

    jQuery(".delete-event").click(function() {
      var k_2_del=jQuery(this).attr('id');

      existing_events[k_2_del].toDelete=true;

      group.events=(existing_events);
      db.updateElement("animal_group", group.id_animal_group, group);
      location.reload();
    });


    jQuery(".update-event").click(function() {
      var k_2_update=jQuery(this).attr('id');
      var event_to_update=existing_events[k_2_update];
      if(event_to_update.type !== jQuery('.button-active').attr('id')){
        //update the oForm and goes on the right tab
        jQuery(".custom_tabs #"+event_to_update.type).click();
      }

      jQuery('#custom_form').html("");
      oForm.do_update=event_to_update.id_event;
        jQuery('#custom_form')[0].appendChild(oForm.createForm(event_to_update));
        jQuery('#custom_form').find('.label-form').addClass('field_separator');
    });

  }

  jQuery("#salva").click(function() {
    jQuery('#anagrafe-new').find('input').each(function(){
      global.app.input.validate(this);
    });

    if (jQuery('.input-invalid').length==0) {
      var group={
        'id_farm_center': id_farm_center,
        'id_animal_group': id_animal_group,
        'color': jQuery('#color').val(),
        'name': jQuery('#nome').val(),
        'date_from': jQuery('#data_inizio').val()&&jQuery('#data_inizio').val()!=""?jQuery('#data_inizio').val():null,
        'date_to': jQuery('#data_fine').val()?jQuery('#data_fine').val():null,
        'events': existing_events
      };

      if(oForm){
        let custom_data=oForm.getValue();
        custom_data.type=jQuery('.button-active').attr('id');

        // debugger

        if(oForm.do_update){
          jQuery("#salva_form").html("Salvataggio in corso... 4");
  
          custom_data.id_event=oForm.do_update;
  
          //find the position of the events to update
          var event_position=-1;
          for (var k=0; k<existing_events.length; k++){
            if(existing_events[k].id_event==custom_data.id_event){
              event_position=k;
              break;
            }
          }
  
          //if guid is not found insert a new event
          if(event_position>=0){
            existing_events[event_position]=custom_data;
          }
          else{
            existing_events.push(custom_data);
          }
        }
        else{
          //se oForm è divserso da null, vuol dire che c'è un form custom da salvare nel gruppo
          custom_data.id_event=tools.getGuid();

          existing_events.push(custom_data);
        }

        if(!group.events){
          group.events=[];
        }
        group.events=existing_events;
      }

      

      db.updateElement("animal_group", group.id_animal_group, group);

      location.hash="farm/"+id_farm_center+"/zoo/"+id_specie+"/gruppi";

    }
    else {
      global.app.dialog.alert("Compila tutti i campi obbligatori per procedere con il salvataggio.", "Campi non compilati");
    }
  });

}

// Prepara il form quando voglio inserire un nuovo gruppo
export function gruppi_new(id_farm,specie, cod_animale,cod_bdn){


  var html='';

  // i campi da inserire per i gruppi sono: colore (tendina), nome del gruppo, data di inizio, data di fine

  html+=`
  
  <div class="block-title block-title-medium">${t('new_group')}</div>
  <div class="list inset form-store-data" id="gruppo-new">
  
    <ul>
      <li>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">${t('group_name')}</div>
            <div class="item-input
            -wrap">
              <input id="nome" type="text" name="name" placeholder="${t('group_name')}" required validate>
            </div>
          </div>
        </div>
      </li>
      <li>
        <a class="item-link smart-select smart-select-init">
          <select id="color">
            <option value="#9c27b0">Viola</option>
            <option value="##ff9800">Arancione</option>
            <option value="#2196f3">Blu</option>
            <option value="#4caf50">Verde</option>
            <option value="#f44336">Rosso</option>
            <option value="#ffeb3b">Giallo</option>
            <option value="#795548">Marrone</option>
            <option value="#607d8b">Grigio</option>
          </select>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title">${t('color')}</div>
            </div>
          </div>
        </a>
      </li>
      <li>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">${t('start_date')}</div>
            <div class="item-input-wrap">
              <input id="data_inizio" type="date" placeholder="Data inizio>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title
            item-label">${t('end_date')}</div>
            <div class="item-input-wrap">
              <input id="data_fine" type="date" placeholder="Data fine">
            </div>
          </div>
        </div>
      </li>

    </ul>
  </div>
  `;
  

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  var button ='<button id="salva" class="button button-fill color-green">Salva</button>';
  jQuery('#footer').html(button);

  jQuery("#salva").click(function() {
    console.log("ccc");
    jQuery('#anagrafe-new').find('input').each(function(){
      global.app.input.validate(this);
    });

    if (jQuery('.input-invalid').length==0) {
      var group={
        'id_farm_center': id_farm,
        'id_animal_group': tools.getGuid(),
        'color': jQuery('#color').val(),
        'name': jQuery('#nome').val(),
        'date_from': jQuery('#data_inizio').val()&&jQuery('#data_inizio').val()!=""?jQuery('#data_fine').val():null,
        'date_to': jQuery('#data_fine').val()?jQuery('#data_fine').val():null,
        'events': []
      };

      db.insert_record('animal_group', group, function( group_inserted){
        location.hash="farm/"+id_farm+"/zoo/"+specie+"/gruppi";
      });

    }
    else {
      global.app.dialog.alert("Compila tutti i campi obbligatori per procedere con il salvataggio.", "Campi non compilati");
    }
  });

}


export function anagrafe_home(id_farm, specie){


  var html='';

  html+="<div style='margin-top:15px;' class='block-title block-title-medium central'>"+t('advanced_registry')+"<br><span id='farm_name'></span></div>";

  html+='<div class="show-nt-if-num-eq-0" style="display:none;">';
    html+='<div class="data-table data-table-init card">';
      html+='<div class="card-header">';
        html+='<div class="row">';
          html+='<h3>Non ci sono ancora dati inseriti! Aggiungi subito il tuo primo dato! </h3>';
        html+='</div>';
      html+='</div>';
    html+='</div>';

    html+='<div style="margin-top:'+(window.innerHeight-460)+'px" class="arrow-down">';
      html+='<i style="margin: auto; font-size:80px;" class="f7-icons">arrow_down</i>';
    html+='</div>';

  html+='</div>';

  html+='<div style="display:none;" class="show-nt-if-num-sup-0 data-table data-table-init card">';
  // html+='<div style="display:none; width:100%" class="show-nt-if-num-sup-0 card">';
    html+='<div class="card-header">';
      html+='<div style="display:contents;"><i class="f7-icons">search</i><input type="text" id="search" placeholder="'+t('search_in_table')+'" title="Digita qui"></div>';
    html+='</div>';
    html+='<div class="card-content">';
      html+='<table id="animal_table">';
        html+='<thead>';
          html+='<tr id="animal">';
            html+='<th></th>';
            html+='<th data-field="code" style="width:25%; text-align:left" class="label-cell sortable-cell">Codice</th>';
            html+='<th data-field="date_birth" style="width:25%; text-align:left" class="label-cell sortable-cell">Età</th>';
            html+='<th data-field="sex" style="width:25%; text-align:left" class="label-cell sortable-cell">Sesso</th>';
            html+='<th data-field="breed" style="width:25%; text-align:left" class="label-cell sortable-cell">Razza</th>';

            html+='<th data-field="group" style="width:25%; text-align:left" class="label-cell sortable-cell">Gruppo</th>';

          html+='</tr>';
        html+='</thead>';
        html+='<tbody id="animals_tbody">';
        html+='</tbody>';
      html+='</table>';
    html+='</div>';


    html+=`
    <div id="pagination_space" class="data-table-footer">
    <!--
      <div class="data-table-rows-select">
        Per page:
        <div class="input input-dropdown">
          <select>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="all">All</option>
          </select>
        </div>
      </div>
    -->
    <div class="data-table-pagination">
      <span class="data-table-pagination-label"><span class='start'>1</span>-<span class='end'>25</span> di <span class='max'></span></span>
      <a data-page="0" class="prev link disabled">
        <i class="icon icon-prev color-gray"></i>
      </a>
      <a data-page="1" class="next link">
        <i class="icon icon-next color-gray"></i>
      </a>
    </div>
  </div>
    `;
  html+='</div>';


  html+='<div style="display:none;" class="show-if-num-sup-0 data-table data-table-init card">';
    html+='<div class="card-header">';
      html+='<div>Capi non più in azienda (<span id="num_eliminati_recente">0</span>)</div>'; //<i class="f7-icons">trash</i>
    html+='</div>';
    html+='<div class="card-content">';
      html+='<table id="recently_deleted_table">';
        html+='<thead>';
          html+='<tr id="recently_deleted">';
            html+='<th class="label-cell sortable-cell">Codice</th>';
            html+='<th class="label-cell sortable-cell">Motivo uscita</th>';
            html+='<th class="label-cell sortable-cell">Data di uscita</th>';
          html+='</tr>';
        html+='</thead>';
        html+='<tbody id="recently_deleted_tbody">';
        html+='</tbody>';
      html+='</table>';
    html+='</div>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  var button='<button id="aggiungi_animale" class="button button-fill">Aggiungi</button>';
  jQuery('#footer').html(button);

  jQuery("#aggiungi_animale").click(function() {
    location.hash="#farm/"+id_farm+"/zoo/"+specie+"/anagrafica/new";
  });


  var data=db.getZoo(id_farm,specie);

  drawTableAnimals(data,id_farm,specie);

}



//Disegna la tabella degli animali
function drawTableAnimals(data, id_farm, specie){


  // jQuery('*').css('transition', 'none');
  // jQuery('*').css('animation', 'none');

    if (data.recently_deleted.length>0) {
      jQuery('.show-if-num-sup-0').show();
    }

    if (data.animal.length==0) {
      jQuery('.show-nt-if-num-eq-0').show();
    }
    else {
      jQuery('.show-nt-if-num-sup-0').show();
    }


    //TODO si aggiunge il cambio di azienda?

  if (data.animal) {
    var html='';

    jQuery.each(data.recently_deleted,function(k,v){
      html+='<tr class="actions-cell" id_animal="'+v.id_animal+'">';
        html+='<td class="numeric-cell">'+v.code+'</td>';
        html+='<td class="label-cell">'+v.type_out+'</td>';
        html+='<td class="numeric-cell">'+v.date_out+'</td>';
      html+='</tr>';
    });
    jQuery('#recently_deleted_tbody').html(html);


    //Funzionje per paginare i record.
    var pagination_record=30;


    jQuery(".sortable-cell").click(function() {
      if (jQuery(this).hasClass('sortable-cell-active')) {
        if (jQuery(this).hasClass('sortable-asc')) {
          jQuery(this).removeClass('sortable-asc').addClass('sortable-desc');
        }
        else {
          jQuery(this).removeClass('sortable-desc').addClass('sortable-asc');
        }
      }
      else {
        jQuery('.sortable-cell').removeClass('sortable-cell-active');
        jQuery(this).addClass('sortable-cell-active sortable-asc');
      }

      var dir;
      // var n=jQuery('.sortable-cell-active')[0].cellIndex;

      // data-field="sex"
      // var n_text=jQuery('.sortable-cell-active')[0].innerText;


      var field_to_sort=jQuery('.sortable-cell-active').attr("data-field");

      if (jQuery('.sortable-cell-active').hasClass('sortable-asc')) {
        dir='asc';
      }
      else {
        dir='desc';
      }

      // var table_id=jQuery(this).parent().attr('id')+'_table';
      // var haveToParse=false;

      // if (n==2 && n_text=='Età') {
      //   haveToParse=true;
      // }

      // console.log(table_id,n,dir,haveToParse);

      //TODO
      // var field_to_sort="code";

      var cresce=1;
      var decresce=-1;
      if(dir=='desc'){
        cresce=-1; decresce=1;
      }

      var animals_to_draw=data.animal.sort((a, b) => (a[field_to_sort] < b[field_to_sort]) ? cresce : decresce);
      addAnimalToTable(animals_to_draw.slice(0,pagination_record), id_farm, specie);
      //sortTable(table_id,n,dir,haveToParse);
    });


    var num_animals=data.animal.length
    var ratio=num_animals/pagination_record;

      // for(var x=0; x<ratio; x++){
      //   jQuery("#pagination_space").append(` <button data-page=${x} class="pag">${x+1}</button>&nbsp;`);
      // }


      jQuery("#pagination_space .next, #pagination_space .prev" ).on("click", function(){

        var page=parseInt(jQuery(this).attr("data-page"));

        var start=page*pagination_record;
        var end=start+pagination_record;

        if(end>=num_animals){
          end=num_animals;
          jQuery("#pagination_space .next").attr("data-page",page).addClass("disabled");
        }
        else{
          jQuery("#pagination_space .next").attr("data-page",page+1).removeClass("disabled");;
        }

        jQuery("#pagination_space .start").html(start+1);
        jQuery("#pagination_space .end").html(end);
        jQuery("#pagination_space .max").html(num_animals);


        if(page>0){
          jQuery("#pagination_space .prev").attr("data-page",page-1).removeClass("disabled");
        }
        else{
          jQuery("#pagination_space .prev").attr("data-page",0).addClass("disabled");;
        }


        var animals_to_draw=data.animal.slice(start, end);

        addAnimalToTable(animals_to_draw, id_farm, specie);
      });


      if(ratio<1){
        jQuery("#pagination_space").hide();
      }



    jQuery("#pagination_space .max").html(num_animals);

    var animals_to_draw=data.animal.slice(0, pagination_record);
    addAnimalToTable(animals_to_draw, id_farm, specie);

    jQuery('#num_eliminati_recente').html(data.recently_deleted.length);



    //Comunque filtra i primi 25 (altrimenti si blocca se sono molti)
    searchInTableData('animal_table', data.animal, id_farm, pagination_record, specie);


  }
  else {
    alert("C'è un errore che non dipende da te. Contatta chi gestisce l'APP");
  }
}


function addAnimalToTable(animals, id_farm, specie){

  var html="";

  jQuery.each(animals,function(k,v){
    if (!v.deleted && v.date_out==null ) {

      var age=getAge(v.date_birth);
      var years=age.years;
      var months=age.months;
      var completeAge=years+"-"+months;

      var status="";
      if (years>0) {
        status="adult";
      }
      else if (months>6) {
        status="adult";
      }
      else {
        status="baby";
      }

      var sex='';
      if (v.sex!=null) {
        sex=v.sex;
      }
      html+='<tr class="actions-cell" id_animal="'+v.id_animal+'">';
        html+='<td>';
          html+='<img src="icons/zoo/'+sex.toLowerCase()+'_'+status+'.svg" width="25"/>';
        html+='</td>';
        html+='<td  >'+checkNull(v.code)+'</td>';
        html+='<td  >'+completeAge+'</td>';
        html+='<td  >'+sex+'</td>';
        html+='<td  >'+v.cod_breed+'</td>';
        if(v.group && v.group.current_group && v.group.current_group.id_animal_group!=0){
          html+='<td  ><div class="circle mini_circle" style="background:'+v.group.current_group.color+';display: inline-flex; margin-right: 5px; "></div>'+v.group.current_group.name+'</td>';
        }
        else{
          html+='<td  >---</td>';
        }
      html+='</tr>';
    }
    else if (v.date_out!=null) {
      // var s=storage.get();
      var recDeleted='';
      recDeleted+='<tr class="actions-cell" id_animal="'+v.id_animal+'">';
        recDeleted+='<td class="numeric-cell">'+v.code+'</td>';
        recDeleted+='<td class="label-cell">'+v.type_out+'</td>';
        recDeleted+='<td class="numeric-cell">'+v.date_out+'</td>';
      recDeleted+='</tr>';
      jQuery('#recently_deleted_tbody').append(recDeleted);
      // s['data_'+v.id_farm].recently_deleted.push(v);
      // storage.save(s);
    }
  });
  jQuery('#animals_tbody').html(html);


  jQuery(".actions-cell").click(function() {

    location.hash="farm/"+id_farm+"/zoo/"+specie+"/anagrafica/view/"+jQuery(this).attr('id_animal');
  });



}


function getTimelineDate(date){
  var timelineDate='';
  var day=date.substr(8,2);
  var month=date.substr(5,2);
  var year=date.substr(0,4);

  if (month=='01') {
    month="GEN";
  }
  else if (month=='02') {
    month="FEB";
  }
  else if (month=='03') {
    month="MAR";
  }
  else if (month=='04') {
    month="APR";
  }
  else if (month=='05') {
    month="MAG";
  }
  else if (month=='06') {
    month="GIU";
  }
  else if (month=='07') {
    month="LUG";
  }
  else if (month=='08') {
    month="AGO";
  }
  else if (month=='09') {
    month="SET";
  }
  else if (month=='10') {
    month="OTT";
  }
  else if (month=='11') {
    month="NOV";
  }
  else if (month=='12') {
    month="DIC";
  }

  return day+' <small>'+month+'</small> '+year;
}


function checkNull(a){
  if(a==null){
    return "";
  }
  else{
    return a;
  }
}



//search in data and then render the table (needs for pagination)
function searchInTableData(table_id, animals, id_farm, pagination_record, specie){
  // var rows = jQuery('#'+table_id+' tr');
  jQuery('#search').keyup(delay(function() {
    var val = jQuery.trim(jQuery(this).val()).replace(/ +/g, ' ').toLowerCase();
    if(val.length>2){
      var a=animals.filter(function(an) {
        var text=(an.code_bdn+" "+an.code).toLowerCase();
        return text.indexOf(val)>-1;
      });
      addAnimalToTable(a.slice(0,pagination_record), id_farm, specie);
    }
    else{
      addAnimalToTable(animals.slice(0,pagination_record), id_farm, specie);
    }

  },500));
}


//funzione per attendere uin po prima di cercare
function delay(callback, ms) {
  var timer = 0;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}




export function getTodayDate(format){
  var today=new Date();

  var today_yymmdd=new Date(today).getFullYear()+"-"+addZero(new Date(today).getMonth()+1)+"-"+addZero(new Date(today).getDate());
  today=addZero(new Date(today).getDate())+"/"+addZero(new Date(today).getMonth()+1)+"/"+addZero(new Date(today).getFullYear());
  if (typeof format!='undefined') {
    if (format=='dd/mm/yyyy') {
      return today;
    }
    else if (format=='yyyy-mm-dd') {
      return today_yymmdd;
    }
    else {
      alert('Formato non ancora sviluppato.');
      return today_yymmdd;
    }
  }
  else {
    return today_yymmdd;
  }
}

// Prepara il form quando voglio inserire un nuovo animale
export function anagrafe_new(id_farm,specie, cod_animale,cod_bdn){


  var html='';
  if (!cod_animale) {
    cod_animale='';
  }
  if (!cod_bdn) {
    cod_bdn='';
  }

  html+='<div class="list form-store-data" id="anagrafe-new">';
    html+='<ul>';
      html+='<li>';
        html+='<div class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Motivazione</div>';
            html+='<div class="item-input-wrap">';
              html+='<select id="motivazione">';
                html+='<option value="Nascita" selected>Nascita</option>';
                html+='<option value="Acquisto">Acquisto</option>';
              html+='</select>';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
      html+='<li>';
        html+='<div style="display:none" id="codice_azienda_origine_div" class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Azienda origine</div>';
            html+='<div class="item-input-wrap">';
              html+='<input id="codice_azienda_origine" type="text" name="name" placeholder="inserire il codice dell\'azienda di origine">';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Cod. animale</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="codice_azienda" type="text" name="name" placeholder="Cod. animale" value="'+cod_animale+'" required validate>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Codice BDN</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="codice_bdn" type="text" name="name" value="'+cod_bdn+'" placeholder="Codice BDN">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
        html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Scrapie</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="scrapie">';
                  html+=` <option value=""></option>
                          <option value="ARR/ARR">ARR/ARR</option>
                          <option value="ARR/ARH">ARR/ARH</option>
                          <option value="ARR/AHQ">ARR/AHQ</option>
                          <option value="ARQ/ARR">ARQ/ARR</option>
                          <option value="ARQ/ARQ">ARQ/ARQ</option>
                          <option value="ARQ/AHQ">ARQ/AHQ</option>
                          <option value="AHQ/AHQ">AHQ/AHQ</option>
                          <option value="ARQ/ARH">ARQ/ARH</option>
                          <option value="ARH/ARH">ARH/ARH</option>
                          <option value="AHQ/ARH">AHQ/ARH</option>
                          <option value="VRQ/VRQ">VRQ/VRQ</option>
                          <option value="VRQ/ARQ">VRQ/ARQ</option>
                          <option value="VRQ/ARH">VRQ/ARH</option>
                          <option value="VRQ/AHQ">VRQ/AHQ</option>
                          <option value="VRQ/ARR">VRQ/ARR</option>
                  `;
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
        html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Sesso</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="sesso">';
                  html+='<option value="M">Maschio</option>';
                  html+='<option value="F" selected>Femmina</option>';
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Razza</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="razza"></select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Padre</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="padre"></select>';
                html+='<input style="display:none;" id="disabled_padre" type="text" name="name" placeholder="Inserisci padre">';
              html+='</div>';

              html+='<i id="padre_change" class="f7-icons">pencil_circle</i>';

            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Madre</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="madre"></select>';
                html+='<input style="display:none;" id="disabled_madre" type="text" name="name" placeholder="Inserisci madre">';

              html+='</div>';

              html+='<i id="madre_change" class="f7-icons">pencil_circle</i>';

            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data di nascita</div>';
              html+='<div class="item-input-wrap">';

                var today = getTodayDate();

                html+='<input id="data_di_nascita" type="text" placeholder="Data verbale" readonly="readonly" value="'+today+'" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li id="ingresso">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data ingresso</div>';
              html+='<div class="item-input-wrap">';

                today = getTodayDate();

                html+='<input id="data_in" type="text" placeholder="Data verbale" readonly="readonly" value="'+today+'" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Note</div>';
            html+='<div class="item-input-wrap">';
              html+='<textarea id="note" placeholder="Le tue note"></textarea>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


    html+='</ul>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  jQuery("#padre_change,#madre_change").click(function() {
    var gen=jQuery(this).attr('id').split('_')[0];

    jQuery('#'+gen).attr('id','tmp_disabled_'+gen);
    jQuery('#disabled_'+gen).attr('id',gen);
    jQuery('#tmp_disabled_'+gen).attr('id','disabled_'+gen);

    jQuery('#disabled_'+gen).hide();
    jQuery('#'+gen).show();

    if (jQuery(this).html()=='pencil_circle') {
      jQuery(this).html('pencil_circle_fill');
    }
    else {
      jQuery(this).html('pencil_circle');
    }
  });

  let data=db.getZoo(id_farm,specie);

  var options='';
  jQuery.each(data.breed,function(k,v){
    options+='<option value="'+v.cod_breed+'">'+v.breed+'</option>';
  });
  jQuery('#razza').html(options);


  let date_birth=getTodayDate('yyyy-mm-dd');
  drawParents(data, id_farm, date_birth,null);
  


  //Se cambia la data ricalcola i possibili genitori
  jQuery('#data_di_nascita').on("change", function(){
    date_birth=jQuery(this).val();
    drawParents(data, id_farm, date_birth,null);
  });


  var button ='<button id="salva" class="button button-fill color-green">Salva</button>';
  jQuery('#footer').html(button);

  jQuery("#motivazione").change(function() {
    if (jQuery(this).val()=='Nascita') {
      jQuery('#ingresso').hide();
      jQuery('#codice_azienda_origine_div').hide();
    }
    else {
      jQuery('#data_di_nascita').val('');
      jQuery('#ingresso').show();
      jQuery('#codice_azienda_origine_div').show();
    }
  }).change();

  jQuery("#salva").click(function() {
    console.log("ccc");
    jQuery('#anagrafe-new').find('input').each(function(){
      global.app.input.validate(this);
    });

    if (jQuery('.input-invalid').length==0) {
      var animal={
        id_animal: tools.getGuid(),
        id_farm_center:id_farm,
        specie: specie,
        code:jQuery('#codice_azienda').val(),
        code_bdn:jQuery('#codice_bdn').val(),
        sex:jQuery('#sesso').val(),
        scrapie:jQuery('#scrapie').val(),
        cod_breed:jQuery('#razza').val(),
        date_birth:jQuery('#data_di_nascita').val(),
        date_in:jQuery('#data_in').val(),
        type_in:jQuery('#motivazione').val(),
        guid_mother:jQuery('#madre').val(),
        guid_father:jQuery('#padre').val(),
        note:jQuery('#note').val(),
        farm_in:jQuery('#codice_azienda_origine').val()
      };

      db.insert_record('animal', animal, function( animal_inserted){
        location.hash="farm/"+id_farm+"/zoo/"+specie+"/anagrafica/view/"+animal.id_animal;
      });

    }
    else {
      global.app.dialog.alert("Compila tutti i campi obbligatori per procedere con il salvataggio.", "Campi non compilati");
    }
  });

  var calendarBirth = global.app.calendar.create({
    inputEl: '#data_di_nascita',
    closeOnSelect:true,
    dateFormat: 'yyyy-mm-dd'
  });
  var calendarIn= global.app.calendar.create({
    inputEl: '#data_in',
    closeOnSelect:true,
    dateFormat: 'yyyy-mm-dd'
  });
}




function drawParents(data, id_farm,date_birth,id_animal){


  let parents=data.animal.filter(function(an) {
    let age=diffDates(an.date_birth,date_birth);
    return age>365 && an.id_animal!==id_animal;
  });
  console.log("parents",parents);

  var html_f='<option value=0></option>';
  var html_m='<option value=0></option>';

  jQuery.each(parents,function(k,v){
    if (v.sex=='F') {
      html_f+='<option value="'+v.id_animal+'">'+v.code+'</option>';
    }
    else {
      html_m+='<option value="'+v.id_animal+'">'+v.code+'</option>';
    }
  });

  jQuery('#padre').html(html_m);
  jQuery('#madre').html(html_f);
}



export function anagrafe_view(id_animal,id_farm, specie){
  // var animal = db.get_element_first('animal', id_animal, 'id_animal');
  var animal=db.getAnimal(id_farm, id_animal);

  var groups=animal.group.animal_group;

  var html='';

  html+="<div class='block-title'>Riassunto animale</div>";
  html+='<div class="card">';
    html+='<div class="card-content card-content-padding">';

      html+='<div class="row">';
        html+='<div class="col">';
          html+='<div class="list">';
            html+='<ul>';
              html+='<li>';
                html+='<div class="item-content">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title">';
                      html+='<div class="item-header">Codice animale</div>';
                      html+='<span id="codice_azienda"></span>';
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';
              html+='<li>';
                html+='<div class="row">';

                  html+='<div class="col">';
                    html+='<div class="item-content">';
                      html+='<div class="item-inner">';
                        html+='<div class="item-title">';
                          html+='<div class="item-header">Sesso</div>';
                          html+='<span id="sesso"></span>';
                        html+='</div>';
                      html+='</div>';
                    html+='</div>';
                  html+='</div>';

                  html+='<div class="col">';
                    html+='<div class="item-content">';
                      html+='<div class="item-inner">';
                        html+='<div class="item-title">';
                          html+='<div class="item-header">Età</div>';
                          html+='<span id="eta"></span>';
                        html+='</div>';
                      html+='</div>';
                    html+='</div>';
                  html+='</div>';

                  
                html+='</div>';

              html+='</li>';
            html+='</ul>';
          html+='</div>';

        html+='</div>';
        html+='<div class="col">';

          html+='<div class="list">';
            html+='<ul>';
              html+='<li>';
                html+='<div class="item-content">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title">';
                      html+='<div class="item-header">Razza</div>';
                      html+='<span id="razza"></span>';
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';


                html+=`
                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">Gruppo</div>
                      <div class="item-input-wrap input-dropdown-wrap" style="display:flex; align-items: center;">
                        <div class="circle" style="background:${animal.group.current_group.color}"></div>
                        <select style="margin-left:5px;" id="gruppo_animale" placeholder="Please choose...">
                          <option value="0">Nessun gruppo</option>
                        `;

                          jQuery.each(groups,function(k,v){

                            html+=`
                              <option value="${v.id_animal_group}" ${animal.group.current_group.id_animal_group==v.id_animal_group?'selected':''}>${v.name}</option>
                            `;
                          });

                  html+=`
                        </select>
                      </div>
                    </div>
                  </li>

                  
                  `;
                  // <ul>
                  //   <li>
                  //     <div class="item-content item-input">
                  //       <div class="item-inner">
                  //         <div class="item-input-wrap">
                  //           <input type="text" placeholder="Select date" readonly="readonly" id="demo-calendar-modal" />
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </li>
                  // </ul>

            html+='</ul>';
          html+='</div>';
        html+='</div>';
      html+='</div>';

      html+='<p id="note"></p>';
    html+='</div>';

    html+=`
      <div class="card-footer">
        <button id="edit_anagrafica" class="col button button-small">Modifica Anagrafica</button>
      </div>
    `;
  html+='</div>';
  
      

  //controlla se il in  s.log_bluetooth ci sono dei dati per la giornata di oggi

  let dupliCount=db.exist_duplicate_ble(animal.id_farm_center, animal.code_bdn);

  let duplit="";
  if(dupliCount>=2){

    //create an alert badge
    duplit=`<span class="badge color-red">Attenzione! già letto</span>`;

  }




  html+="<div class='block-title'>Aggiungi evento "+duplit+"</div>";


  
  html+='<div class="card">';
    html+='<div class="card-content card-content-padding">';    
      html+='<p class="custom_tabs segmented">';

      let forms=db.getZooForm(specie);
      
      if (forms) {
        jQuery.each(forms,function(k,v){

          var isValid=true;
          if (typeof v.valid_for!='undefined' && v.valid_for!=null && v.valid_for!='' ) {
            if (v.valid_for!=animal.sex) {
              isValid=false;
            }
          }
          if (isValid) {
            html+='<button id="'+v.custom_form+'" class="button button-outline">'+v.form_name+'</button>';
          }
        });
      }
      html+='</p>';

      html+='<div id="custom_form">';
      html+='</div>';
    html+='</div>';
  html+='</div>';

  html+='<div style="display:none;" class="timeline-block">';
    html+="<div class='block-title'>Elenco degli eventi</div>";
    html+='<div class="card">';
      html+='<div class="card-content card-content-padding">';
        html+='<div class="timeline"></div>';
      html+='</div>';
    html+='</div>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  // get max date of the animal_group_member
  var animal_group_member=db.getAnimalGroupMembers_singleAnimal(id_farm, animal.id_animal);
  var max_date=null;
  if(animal_group_member){
    jQuery.each(animal_group_member,function(k,v){
      // debugger
      if(max_date==null){
        max_date=v.date_from;
      }
      else{
        if(v.date_from>max_date){
          max_date=v.date_from;
        }
      }
    });
  }






  jQuery("#gruppo_animale").unbind().change(function(){
    let id_animal_group=jQuery(this).val();
    global.app.calendar.create({
      inputEl: '#demo-calendar-modal',
      openIn: 'customModal',
      header: true,
      footer: true,
      toolbarCloseText: 'Salva trasferimento',
      headerPlaceholder: 'Data inserimento nel gruppo',
      closeByBackdropClick: false,
      locale: 'it',
      dateFormat: 'yyyy-mm-dd',
      value: [new Date()],
      minDate: new Date(max_date),
      on: {
        open: function(){
          jQuery('.page-content').addClass('scurisci-tutto');
          jQuery('.card').css('background', 'none');
        },
        closed: function(){
          jQuery('.page-content').removeClass('scurisci-tutto');
          jQuery('.card').css('background', 'white');

          var date=this.value;

          // date in format yyyy-mm-dd
          var date_group_move= new Date(date[0]).toLocaleDateString('sv');
          saveChangeGroup(date_group_move, id_animal_group);
        }
      }
    }).open();
  });

  function saveChangeGroup(date, id_animal_group){

    let new_animal_group_member={
      "id_animal_group_member": tools.getGuid(),
      "id_animal_group": id_animal_group,
      "id_animal": id_animal,
      "id_farm_center": id_farm,
      "date_from": date, //getTodayDate('yyyy-mm-dd'),
      "date_to": null
    };

    db.updateAnimalGroupMember(id_animal, id_farm, new_animal_group_member, function(){
      console.log("aggiornato gruppo");
    });
  }

  // var button='<button id="salva" class="if-not-rimosso button button-fill color-green">Salva</button>';
  // jQuery('#footer').html(button);
  var button='<button id="salva_form" class="if-not-rimosso button button-fill color-green">Salva</button>';
  button+='<button style="display:none;" id="annulla_eliminazione" class="if-rimosso button button-fill color-orange">Annulla eliminazione</button>';
  jQuery('#footer').html(button);


  var oForm=null;
  jQuery(".custom_tabs button").click(function() {
    jQuery('.custom_tabs button').removeClass('button-active');
    jQuery(this).addClass('button-active');

    var id=jQuery(this).attr('id');

    jQuery.each(forms,function(k,v){
      if (id==v.custom_form) {
        console.log(animal.sex);

        if(typeof v.json !=='undefined' ){
          jQuery('#custom_form').html('');
          var json=v.json;
          if(typeof json=='string'){
            json=JSON.parse(json);
          }
          var fields = json;
          var f = {};
          f.table_name = "klean";
          f.primary_key = Array('id');
          f.fields = fields;
          jQuery.each(f.fields, function(k1,v1){
            if(k1=='date'){
              v1.default=getTodayDate();
            }
          });

          // Col tema Framework7Theme non salva bene i dati col getValue perché aggiungiamo dei div..  jQuery(this.widget)[0].firstElementChild.value
          // var theme_f7=new Dbmng.AppTheme();
          var theme_f7=new Dbmng.Framework7Theme();

          oForm = new Dbmng.Form ({'aForm':f, theme:theme_f7});
          jQuery('#custom_form')[0].appendChild(oForm.createForm({}));

          global.oForm=oForm;
          jQuery('#custom_form').find('.label-form').addClass('field_separator');
        }
      }
    });
  });

  var last_event=db.getLastEventType();
  if(last_event){
    jQuery(".custom_tabs #"+last_event).click();
  }
  else{
    jQuery(".custom_tabs button").first().click();
  }


  jQuery("#annulla_eliminazione").click(function() {
    console.log("ANNULLO ELIMINAZIONE");
    // var from_deleted=true;
    var animal = db.get_element_first('animal', id_animal, 'id_animal');
    animal.date_out=null;
    animal.type_out=null;
    animal.farm_out=null;
    animal.sync=false;

    db.updateElement("animal", animal.id_animal, animal, function(){
      history.back();
      location.reload();
    });

  });

  jQuery("#salva_form").click(function() {

    // disabilita il bottone
    jQuery("#salva_form").attr("disabled", true);

    // aggiungi u testo di debug sul bottone
    jQuery("#salva_form").html("Salvataggio in corso... 1");

    var haveData=false;
    jQuery.each(jQuery('.real_widget'),function(k,v){
      if (jQuery(v).attr('type')!=='date' && jQuery(v).val()!=='') {
        console.log(jQuery(v).val());
        haveData=true;
      }
    });

    jQuery("#salva_form").html("Salvataggio in corso... 2");
    
    console.log("HAVE: "+haveData);
    if (haveData) {

      jQuery("#salva_form").html("Salvataggio in corso... 3");

      //1. get animal from id_animal
      var animal=db.getAnimal(id_farm, id_animal);


      //fix the json of the animal (legacy)
      animal.json=get_json_fixed(animal.json);

      //get animal events
      var events=animal.json.events;

      //get event object
      var new_event=global.oForm.getValue();
      new_event.type=jQuery('.button-active').attr('id');
      //check if needs to update
      if(global.oForm.do_update){
        jQuery("#salva_form").html("Salvataggio in corso... 4");

        new_event.guid=global.oForm.do_update;

        //find the position of the events to update
        var event_position=-1;
        for (var k=0; k<events.length; k++){
          if(events[k].guid==new_event.guid){
            event_position=k;
            break;
          }
        }

        //if guid is not found insert a new event
        if(event_position>=0){
          events[event_position]=new_event;
        }
        else{
          events.push(new_event);
        }
      }
      else{
        jQuery("#salva_form").html("Salvataggio in corso... 5");

        new_event.guid=tools.getGuid();
        //aggiunge il nuovo evento
        events.push(new_event);
      }

      jQuery("#salva_form").html("Salvataggio in corso... 6");

      db.updateElement("animal", animal.id_animal, animal, function(){
        jQuery("#salva_form").html("Salvataggio in corso... 7");
        
        db.saveLastEventType(new_event.type);
        jQuery("#salva_form").html("Salvataggio in corso... 8");

        let id_animal_group=jQuery('#gruppo_animale').val()    
        // debugger    
        if(animal.group.current_group.id_animal_group && animal.group.current_group.id_animal_group!=id_animal_group){
          console.log("E' cambiato il gruppo");

          var date=jQuery('.dbmng_form_field_date .real_widget').val();

          let new_animal_group_member={
            "id_animal_group_member": tools.getGuid(),
            "id_animal_group": id_animal_group,
            "id_animal": id_animal,
            "id_farm_center": id_farm,
            "date_from": date,
            "date_to": null
          };
          
          db.updateAnimalGroupMember(id_animal, id_farm, new_animal_group_member, function(){

            anagrafe_view(id_animal,id_farm, specie);
          
            jQuery("#salva_form").html("Salvataggio in corso... 9");
    
    
            // abilita il bottone
            jQuery("#salva_form").attr("disabled", false);
            jQuery("#salva_form").html("Salva");

            //KLEAN: serve ricaricare la pagina?

            // Si comporta come faceva prima....
    
          });
        }
        else{
          console.log("Non è cambiato il gruppo");

          anagrafe_view(id_animal,id_farm, specie);
          
          jQuery("#salva_form").html("Salvataggio in corso... 9");
          jQuery("#salva_form").html("Salva");
        }
      });
    }
    else {
      history.back();
    }
  });

  jQuery("#edit_anagrafica").click(function() {
    location.hash="#farm/"+id_farm+"/zoo/"+specie+"/anagrafica/edit/"+id_animal;
  });


  // jQuery("#manage_gruppo").click(function() {
  //   //Start a modal
  //   var modal = global.app.dialog.create({
  //     title: 'Gestione gruppo',
  //     text: 'Seleziona il gruppo a cui vuoi assegnare l\'animale',
  //     content: `
  //       <div class="list">
  //       </div>
  //     `,
  //   });      
  // });

  drawSingleAnimal(animal, id_farm, specie);
}



// Funzione chiamata in modifica dell'animale e serve a riempire/disegnare la sua storia(timeline) e i dati(select dei parents ad esempio)..
function drawSingleAnimal(animal, id_farm, specie){

  let data=db.getZoo(id_farm,specie);
  let forms=db.getZooForm(specie);

  var json=get_json_fixed(animal.json);

  //TODO intercettare che data.animals abbia almeno un records
  //TODO Creare l'oggetto animal=data.animals.data[0] (attenzione sotto a volte si usa)
  //TODO smettere di usare data.animals.data

  try {

    var events=[];
    if(json && json.events){
      events=json.events;
    }
    else{
      json.events=events;
    }

    // get all movements of the animal from animal_group_member
    var animal_group_member=db.getAnimalGroupMembers_singleAnimal(id_farm, animal.id_animal);
    var deep_copied_events=JSON.parse(JSON.stringify(events));

    var processed_events_group_member=[];

    // merge the events with the movements
    jQuery.each(animal_group_member,function(k,v){

      if(processed_events_group_member.indexOf(v.id_animal_group_member)==-1){
        console.log("AAAA",v)

        if(v.date_from){
          var event={};
          event.date=v.date_from;
          event.type='movement';
          event.guid=tools.getGuid();
          event.id_animal_group=v.id_animal_group;
          event.id_animal_group_member=v.id_animal_group_member;
          event.id_farm_center=v.id_farm_center;
          event.sync=true;
          deep_copied_events.push(event);
        }
  
        if(v.date_to){
          var event={};
          event.date=v.date_to;
          event.type='movement_out';
          event.guid=tools.getGuid();
          event.id_animal_group=v.id_animal_group;
          event.id_animal_group_member=v.id_animal_group_member;
          event.id_farm_center=v.id_farm_center;
          event.sync=true;
          deep_copied_events.push(event);
        }
  
        processed_events_group_member.push(v.id_animal_group_member);
      }


    });

    deep_copied_events=deep_copied_events.sort((a, b) => (a.date < b.date) ? 1 : -1);

    
    if (deep_copied_events.length>0) {
      jQuery('.timeline-block').show();
      var timeline='';
      var setDaysEco=true;
      jQuery.each(deep_copied_events,function(k,v){

        if(v.toDelete){
          timeline+="";
        }
        else{
          timeline+='<div class="timeline-item">';
            timeline+='<div class="timeline-item-date">'+getTimelineDate(v.date)+'</div>';
            timeline+='<div class="timeline-item-divider"></div>';
            //todo  lLEAN PERCHE C'ÈERA SCRITTO 125PX???
            // timeline+='<div class="timeline-item-content" style="width:125px;">';
            timeline+='<div class="timeline-item-content" style="width:100%;">';
              jQuery.each(forms,function(i,d){
                if (d.custom_form==v.type) {
                  timeline+='<div class="timeline-item-title">'+d.form_name+'<div class="timeline-item-icon" style="color: #bbb;float:right;margin-top: 7px;">';
                  //TODO fare l'update
                  timeline+='<i id="'+k+'" style="padding-right:20px;"" class="update-event f7-icons">pencil</i>';
                  timeline+='<i id="'+k+'" class="delete-event f7-icons">trash</i>';
                  timeline+='</div></div>';
                }
              });

              if (v.type=='eco') {
                console.log("Eco",v);
                if(setDaysEco){
                  var last_gg_eco=v.gg;
                  var days_diff_from_last_eco=diffDates(v.date, getTodayDate());
                  var forecast_days=parseInt(last_gg_eco)+parseInt(days_diff_from_last_eco);

                  jQuery('.dbmng_form_field_gg .real_widget').val(forecast_days);

                  setDaysEco=false;
                }

                timeline+='<div class="timeline-item-subtitle">'+v.result+" ("+ v.gg+')</div>';
              }
              else if (v.type=='movement') {
                var group=db.getAnimalGroup(id_farm, v.id_animal_group);
                if(group){
                  timeline+='<div class="timeline-item-subtitle">Entrato a far parte di: <b>'+group.name+'</b></div>';
                }
                else{
                  timeline+='<div class="timeline-item-subtitle">Gruppo non trovato</div>';
                }
              }
              else if (v.type=='movement_out') {
                var group=db.getAnimalGroup(id_farm, v.id_animal_group);
                if(group){
                  timeline+='<div class="timeline-item-subtitle">Uscito dal gruppo: <b>'+group.name+'</b></div>';
                }
                else{
                  timeline+='<div class="timeline-item-subtitle">Gruppo non trovato</div>';
                }
              }


              else if (v.type=='delivery') {

                if (typeof v.numbers_m!=='undefined' && v.numbers_m!='') {
                  var lambLabelM='agnelli';
                  if (parseInt(v.numbers_m)==1) {
                    lambLabelM='agnello';
                  }
                  timeline+='<div class="timeline-item-subtitle">'+v.numbers_m+' '+lambLabelM+'</div>';
                }

                if (typeof v.numbers_f!=='undefined' && v.numbers_f!='') {
                  var lambLabelF='agnelle';
                  if (parseInt(v.numbers_f)==1) {
                    lambLabelF='agnella';
                  }
                  timeline+='<div class="timeline-item-subtitle">'+v.numbers_f+' '+lambLabelF+'</div>';
                }

                if (typeof v.numbers!=='undefined' && v.numbers!='') {
                  var lambLabel='agnell*';
                  timeline+='<div class="timeline-item-subtitle">'+v.numbers+' '+lambLabel+'</div>';
                }


                timeline+='<div class="timeline-item-text">'+v.ram+'</div>';
              }
              else if (v.type=='monta') {
                timeline+='<div class="timeline-item-subtitle">'+v.azione+'</div>';
              }
              else if (v.type=='latte') {
                var label='';
                if (v.in_out=='in') {
                  label='Ingresso in lattazione';
                }
                else {
                  label='Uscita dalla lattazione';
                }
                timeline+='<div class="timeline-item-subtitle">'+label+'</div>';
              }
              else {
                var sub="";
                jQuery.each(v,function(k2,v2){
                  if(["guid","type","date"].indexOf(k2)==-1){
                    sub=sub+v2+"<br/>";
                  }
                });
                timeline+='<div class="timeline-item-subtitle">'+sub+'</div>';
              }
            timeline+='</div>';
          timeline+='</div>';
        }
      });
      jQuery('.timeline').html(timeline);

      jQuery(".delete-event").click(function() {
        var k_2_del=jQuery(this).attr('id');

        // events.splice(k_2_del, 1);
        deep_copied_events[k_2_del].toDelete=true;

        animal.json=(deep_copied_events);

        db.updateElement("animal", animal.id_animal, animal, function(){
          anagrafe_view(animal.id_animal,id_farm, specie);
        });

      });


      jQuery(".update-event").click(function() {
        var k_2_update=jQuery(this).attr('id');
        var event_to_update=deep_copied_events[k_2_update];

        if(event_to_update.type !== jQuery('.button-active').attr('id')){
          //update the oForm and goes on the right tab
          jQuery(".custom_tabs #"+event_to_update.type).click();
        }

        jQuery('#custom_form').html("");
        global.oForm.do_update=event_to_update.guid;
          jQuery('#custom_form')[0].appendChild(global.oForm.createForm(event_to_update));
          jQuery('#custom_form').find('.label-form').addClass('field_separator');
      });

    }
  }
  catch(error) {
    console.error(error);
  }


  //in fase di inserimento i valori sono nulli
  var date_birth=animal.date_birth;
  var id_animal=animal.id_animal;
  drawParents(data, id_farm, date_birth,id_animal);

  //Se cambia la data ricalcola i possibili genitori
  jQuery('#data_di_nascita').on("change", function(){
    date_birth=jQuery(this).val();
    drawParents(data, id_farm, date_birth,id_animal);
  });

  if(jQuery('#codice_azienda').is("span")){
    jQuery('#codice_azienda').text(animal.code);
  }
  if(jQuery('#razza').is("span")){
    jQuery('#razza').text(animal.cod_breed);
  }
  if(jQuery('#sesso').is("span")){
    jQuery('#sesso').text(animal.sex);
  }
  if(jQuery('#scrapie').is("span")){
    jQuery('#scrapie').text(animal.scrapie);
  }

  if(jQuery('#eta').is("span")){
    var age=getAge(animal.date_birth);

    var years=age.years;
    var months=age.months;
    var completeAge=years+"-"+months;


    jQuery('#eta').text(completeAge);
  }
  if(jQuery('#note').is("p")){
    jQuery('#note').text(animal.note);
  }


  jQuery('#note').val(animal.note);
  jQuery('#codice_azienda').val(animal.code);
  jQuery('#codice_bdn').val(animal.code_bdn);
  jQuery('#sesso').val(animal.sex);
  jQuery('#scrapie').val(animal.scrapie);
  jQuery('#motivazione').val(animal.type_in);
  jQuery('#razza').val(animal.cod_breed);
  jQuery('#data_in').val(animal.date_in);
  jQuery('#data_di_nascita').val(animal.date_birth);

  jQuery('#padre').val(animal.guid_father);
  if (jQuery('#padre').val()==null) {
    jQuery('#padre_change').click();
    jQuery('#padre').val(animal.guid_father);
  }

  jQuery('#madre').val(animal.guid_mother);
  if (jQuery('#madre').val()==null) {
    jQuery('#madre_change').click();
    jQuery('#madre').val(animal.guid_mother);
  }

  jQuery('#codice_azienda_origine').val(animal.farm_in);



  if(animal.type_in=='N'){
    jQuery('#codice_azienda_origine_div').hide();
  }

  if (animal.type_out!=null) {
    jQuery('.if-not-rimosso').hide();
    jQuery('.if-rimosso').show();

    jQuery(".disabled-if-rimosso").prop( "disabled", true );


    jQuery('#data_out').val(animal.date_out);
    jQuery('.motivo_out').val(animal.type_out);

    if (animal.type_out=='slaughter') {
      jQuery('.if-macello').show();
      jQuery('.if-morte').hide();

      if (animal.macello!=null) {
        jQuery('#macello').val(animal.macello);
      }
      if (animal.peso_carcassa!=null) {
        jQuery('#peso_carcassa').val(animal.peso_carcassa+" KG");
      }
    }
    else if (animal.type_out=='death') {
      jQuery('.if-macello').hide();
      jQuery('.if-morte').show();

      if (animal.motivo_morte!=null) {
        jQuery('#motivo_morte').val(animal.motivo_morte);
      }

    }
    else {
      jQuery('.if-macello').hide();
      jQuery('.if-morte').hide();
    }
  }
  else {
    jQuery('.if-not-rimosso').show();
    jQuery('.if-rimosso').hide();
  }
}




//va funzione prende il vecchio oggetto json (era un array con il nuovo (oggetto con elemento events))
export function get_json_fixed(json_to_fix){
  var json_fix={};
  if(typeof  json_to_fix=='undefined'){
    json_fix={"events": []};
  }
  else if (typeof  json_to_fix=='string'){
    if (json_to_fix === '[]') {
      json_fix={"events": []};
    }
    else {
      json_fix=JSON.parse( json_to_fix);
    }
  }
  else if (typeof json_to_fix=='object') {
    if (JSON.stringify(json_to_fix) === '[]') {
      json_fix={"events": []};
    }
    else {
      json_fix=json_to_fix;
    }
  }

  if(Array.isArray(json_fix)){
    jQuery.each(json_fix, function(k,v){
      if(typeof v.guid=='undefined'){
        v.guid=tools.getGuid();
      }
    });
    json_fix={"events": json_fix};
  }
  return json_fix;
}



export function anagrafe_edit(id_animal,id_farm, specie){


  let data=db.getZoo(id_farm,specie);
  let animal=db.get_element_first('animal', id_animal, 'id_animal');
  

  var html='';

  html+='<div class="list form-store-data" id="anagrafe-edit">';
    html+='<ul>';
      html+='<li>';
        html+='<div class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Motivazione</div>';
            html+='<div class="item-input-wrap">';
              html+='<select class="disabled-if-rimosso" id="motivazione">';
                html+='<option value="N" selected>Nascita</option>';
                html+='<option value="M">Acquisto</option>';
              html+='</select>';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
      html+='<li>';
        html+='<div  id="codice_azienda_origine_div" class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Azienda origine</div>';
            html+='<div class="item-input-wrap">';
              html+='<input id="codice_azienda_origine" type="text" name="name" placeholder="inserire il codice dell\'azienda di origine">';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Cod. animale</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="codice_azienda" type="text" name="name" placeholder="Cod. animale" required validate>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Codice BDN</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="codice_bdn" type="text" name="name" placeholder="Codice BDN">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li>';
        html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Scrapie</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="scrapie">';

                  html+=` <option value=""></option>
                          <option value="ARR/ARR">ARR/ARR</option>
                          <option value="ARR/ARH">ARR/ARH</option>
                          <option value="ARR/AHQ">ARR/AHQ</option>
                          <option value="ARQ/ARR">ARQ/ARR</option>
                          <option value="ARQ/ARQ">ARQ/ARQ</option>
                          <option value="ARQ/AHQ">ARQ/AHQ</option>
                          <option value="AHQ/AHQ">AHQ/AHQ</option>
                          <option value="ARQ/ARH">ARQ/ARH</option>
                          <option value="ARH/ARH">ARH/ARH</option>
                          <option value="AHQ/ARH">AHQ/ARH</option>
                          <option value="VRQ/VRQ">VRQ/VRQ</option>
                          <option value="VRQ/ARQ">VRQ/ARQ</option>
                          <option value="VRQ/ARH">VRQ/ARH</option>
                          <option value="VRQ/AHQ">VRQ/AHQ</option>
                          <option value="VRQ/ARR">VRQ/ARR</option>
                  `;
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Sesso</div>';
              html+='<div class="item-input-wrap">';
                html+='<select class="disabled-if-rimosso" id="sesso">';
                  html+='<option value="M">Maschio</option>';
                  html+='<option value="F" selected>Femmina</option>';
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Razza</div>';
              html+='<div class="item-input-wrap">';
                html+='<select class="disabled-if-rimosso" id="razza"></select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data di nascita</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="data_di_nascita" type="text" placeholder="Data verbale" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li id="ingresso">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data ingresso</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="data_in" type="text" placeholder="Data verbale" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Padre</div>';
              html+='<div class="item-input-wrap">';
                html+='<select class="disabled-if-rimosso" id="padre"></select>';
                html+='<input class="disabled-if-rimosso" style="display:none;" id="disabled_padre" type="text" name="name" placeholder="Inserisci padre">';
              html+='</div>';

              html+='<i id="padre_change" class="if-not-rimosso f7-icons">pencil_circle</i>';

            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Madre</div>';
              html+='<div class="item-input-wrap">';
                html+='<select class="disabled-if-rimosso" id="madre"></select>';
                html+='<input class="disabled-if-rimosso" style="display:none;" id="disabled_madre" type="text" name="name" placeholder="Inserisci madre">';

              html+='</div>';

              html+='<i id="madre_change" class="if-not-rimosso f7-icons">pencil_circle</i>';

            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li style="display:none" class="if-not-rimosso">';
          html+='<div class="item-content item-input">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label" style="font-size: 17px;">Rimuovi per</div>';
              html+='<p id="motivo_out" class="segmented segmented-raised">';
                html+='<button data-type="slaughter" class="button">Macellazione</button>';
                html+='<button data-type="sell"  class="button">Vendita</button>';
                html+='<button data-type="death" class="button">Morte</button>';
              html+='</p>';

              html+='<a id="elimina_definitivamente" style="color:#acacac;"><u><i>Elimina definitivamente<i></u></a>';

            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li style="display:none" class="if-rimosso">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Rimosso per</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="motivo_out" id="motivo_out" type="text" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li style="display:none" class="if-rimosso" id="uscita">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Rimosso il</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="data_out" type="text" placeholder="Data rimozione" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li style="display:none" class="if-rimosso if-macello" id="_macello">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Macello</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="macello" type="text" placeholder="Macello" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li style="display:none" class="if-rimosso if-macello" id="_peso_carcassa">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Peso carcassa</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="peso_carcassa" type="text" placeholder="Peso carcassa" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li style="display:none" class="if-rimosso if-morte" id="_motivo_morte">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Motivo morte</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="motivo_morte" type="text" placeholder="Motivo morte" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Note</div>';
            html+='<div class="item-input-wrap">';
              html+='<textarea id="note" placeholder="Le tue note"></textarea>';
            html+='</div>';
          html+='</div>';
        html+='</li>';



    html+='</ul>';
  html+='</div>';

  html+=`
    <div class="block block-strong inset">
      ${fairshare_oo.getPhotoHtml(id_animal)}
    </div>
  `;


  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);


  fairshare_oo.fillPhotos(id_animal);

  jQuery("#padre_change,#madre_change").click(function() {
    var gen=jQuery(this).attr('id').split('_')[0];

    jQuery('#'+gen).attr('id','tmp_disabled_'+gen);
    jQuery('#disabled_'+gen).attr('id',gen);
    jQuery('#tmp_disabled_'+gen).attr('id','disabled_'+gen);

    jQuery('#disabled_'+gen).hide();
    jQuery('#'+gen).show();

    if (jQuery(this).html()=='pencil_circle') {
      jQuery(this).html('pencil_circle_fill');
    }
    else {
      jQuery(this).html('pencil_circle');
    }
  });


  var options='';
  jQuery.each(data.breed,function(k,v){
    options+='<option value="'+v.cod_breed+'">'+v.breed+'</option>';
  });
  jQuery('#razza').html(options);



  var button='<button style="display:none" id="salva" class="if-not-rimosso button button-fill color-green">Salva</button>';
  jQuery('#footer').html(button);

  jQuery("#elimina_definitivamente").click(function() {

    // fai uscire l'animale dal gruppo
    let animal_group_member=db.getAnimalGroupMembers_singleAnimal(id_farm, id_animal);
    jQuery.each(animal_group_member,function(k,v){
      v.date_to=new Date().toISOString().split('T')[0];
      db.updateAnimalGroupMember(id_animal, id_farm, v, function(){
        console.log("uscito dal gruppo");
      });
    });


    let animal= db.get_element_first('animal', id_animal, 'id_animal');
    animal.toDelete=true;
    animal.sync=false;

    db.updateElement("animal", animal.id_animal, animal, function(){
      anagrafe_home(id_farm, specie);
    });

  });

  jQuery("#motivo_out button").unbind().click(function() {
    jQuery('#motivo_out button').removeClass('button-active');
    jQuery(this).addClass('button-active');


    let type=jQuery(this).attr('data-type');

    location.hash="farm/"+id_farm+"/zoo/"+specie+"/anagrafica/remove/"+animal.id_animal+"/"+type;

  });

  drawSingleAnimal(animal, id_farm, specie);

  jQuery("#salva").click(function() {


    jQuery('#anagrafe-edit').find('input').each(function(){
      global.app.input.validate(this);
    });

    if (jQuery('.input-invalid').length==0) {
      var animal={
        id_animal:id_animal,
        id_farm:id_farm,
        code:jQuery('#codice_azienda').val(),
        code_bdn:jQuery('#codice_bdn').val(),
        sex:jQuery('#sesso').val(),
        scrapie:jQuery('#scrapie').val(),
        cod_breed:jQuery('#razza').val(),
        date_birth:jQuery('#data_di_nascita').val(),
        date_in:jQuery('#data_in').val(),
        type_in:jQuery('#motivazione').val(),
        farm_in:jQuery('#codice_azienda_origine').val(),
        guid_father:jQuery('#padre').val(),
        guid_mother:jQuery('#madre').val(),
        note:jQuery('#note').val()
      };


      db.updateElement("animal", animal.id_animal, animal, function(){
        history.back();
        db.storeImages();
      });
    }
    else {
      global.app.dialog.alert("Compila tutti i campi obbligatori per procedere con il salvataggio.", "Campi non compilati");
    }
  });


  var calendarIn = global.app.calendar.create({
    inputEl: '#data_in',
    dateFormat: 'yyyy-mm-dd',
    closeOnSelect:true
  });

  var calendarBirth = global.app.calendar.create({
    inputEl: '#data_di_nascita',
    dateFormat: 'yyyy-mm-dd',
    closeOnSelect:true
  });
}


export function anagrafe_remove(id_animal,id_farm, specie, type_out){
  var html='';

  html+='<div class="list form-store-data" id="my-form">';
    html+='<ul>';
      html+='<li>';
        html+='<div class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Cod. animale</div>';
            html+='<div class="item-input-wrap">';
              html+='<input type="text" id="codice_azienda" disabled>';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Motivazione</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="type_out">';
                  html+='<option value="slaughter">Macellazione</option>';
                  html+='<option value="death">Morte</option>';
                  html+='<option value="sell" selected>Vendita</option>';
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li id=li_macello>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Macello</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="macello" type="text" name="name" placeholder="inserire il macello">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li id=li_peso_carcassa>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Peso carcassa</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="peso_carcassa" type="text" name="name" placeholder="inserire il peso in KG">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li id=li_motivo_morte>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Motivo morte</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="motivo_morte" type="text" name="name" placeholder="Motivo della morte">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li id=li_azienda_destinazione>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Azienda destinazione</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="codice_azienda_destinazione" type="text" name="name" placeholder="inserire il codice dell\'azienda di destinazione">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data</div>';
              html+='<div class="item-input-wrap">';

                var today = getTodayDate();

                html+='<input type="text" placeholder="Data verbale" readonly="readonly" id="data_out" value="'+today+'" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
    html+='</ul>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  let animal=db.get_element_first('animal', id_animal, 'id_animal');

  jQuery('#codice_azienda').val(animal.code);

  jQuery("#type_out").change(function() {
     if (jQuery('#type_out').val()=='slaughter') {
       jQuery('#li_macello').show();
       jQuery('#li_peso_carcassa').show();

       jQuery('#li_motivo_morte').hide();

       jQuery('#li_azienda_destinazione').hide();
     }
     else if(jQuery('#type_out').val()=='death') {
       jQuery('#li_motivo_morte').show();

       jQuery('#li_azienda_destinazione').hide();

       jQuery('#li_macello').hide();
       jQuery('#li_peso_carcassa').hide();
     }
     else {
       jQuery('#li_azienda_destinazione').show();

       jQuery('#li_motivo_morte').hide();

       jQuery('#li_macello').hide();
       jQuery('#li_peso_carcassa').hide();
     }
  });

  jQuery('#type_out').val(type_out).change();




  var button='<button id="delete" class="button button-fill color-red">Salva</button>';
  jQuery('#footer').html(button);



  jQuery("#delete").click(function() {

    // fai uscire l'animale dal gruppo
    let animal_group_member=db.getAnimalGroupMembers_singleAnimal(id_farm, id_animal);
    jQuery.each(animal_group_member,function(k,v){
      // v.date_to=new Date().toISOString().split('T')[0];

      var d=jQuery('#data_out').val();

      // d is in format dd/mm/yyyy
      d=d.substring(6,10)+'-'+d.substring(3,5)+'-'+d.substring(0,2);

      v.date_to=d;
      // debugger


      db.updateAnimalGroupMember(id_animal, id_farm, v, function(){
        console.log("uscito dal gruppo");
      });
    });
    

    var animal = db.get_element_first('animal', id_animal, 'id_animal');
    animal.date_out=jQuery('#data_out').val();
    animal.type_out=jQuery('#type_out').val();
    animal.farm_out=jQuery('#codice_azienda_destinazione').val();
    animal.macello=jQuery('#macello').val();
    animal.peso_carcassa=jQuery('#peso_carcassa').val();
    animal.motivo_morte=jQuery('#motivo_morte').val();


    db.updateElement("animal", animal.id_animal, animal, function(){
      history.back();
    });

  });

  var calendarOut = global.app.calendar.create({
    inputEl: '#data_out',
    closeOnSelect:true
  });
}

export function printTilesMain(data, id_farm_center){
  console.log(data);
  if(data.ok){
    if(data.data.length>0){
      var last=data.data[0];

      var ultima_analisi_data=last.data_analisi;
      // ultima_analisi_data fromato dd/mm/yyyy
      ultima_analisi_data=ultima_analisi_data.substring(8,10)+'/'+ultima_analisi_data.substring(5,7)+'/'+ultima_analisi_data.substring(0,4);


      var h="<div class='col-xs-12'><h3>Ultima analisi disponibile:"+ultima_analisi_data+"</h3></div>";
      jQuery("#pecorapp_body").html(h);


      global.tls=[
        {'label':'pecora','title':'Salute',
          'variables': ['carica_batterica','cellule_somatiche'],
          'elab':function(last_value,data){
          var cb=last_value.carica_batterica;
          var cs=last_value.cellule_somatiche;
          var head='';
          var html='';
          var alert='';
          if(cb===null){
            head+="Dato non disponibile";
          }
          else{
            cb=parseFloat(cb);
            cs=parseFloat(cs);
            if(cb>1500 || cs>2000){
              head+="Valori fuori soglia";
              alert='high';
            }
            else if(cb<1000 && cs<1000){
              head+="Valori ottimali";
              alert='low';
            }
            else{
              head+="Valori intermedi";
              alert='medium';
            }
            html+="Cellule somatiche: "+cs.toFixed(0)+"<br/>";
            html+="Carica Batterica: "+cb.toFixed(0)+"<br/>";
          }
          return {'head':head, 'html':html,'alert':alert };

        }},
        {'label':'latte','title':'Qualità del latte',
          'variables': ['grassi','proteine','totale'],
          'elab':function(last_value,data){
          var grassi=last_value.grassi;
          var proteine=last_value.proteine;
          var head='';
          var html='';
          var alert='';
          if(grassi===null){
            head+="Dato non disponibile";
          }
          else{
            grassi=parseFloat(grassi);
            proteine=parseFloat(proteine);
            var latte_norm=(0.25+  0.085 * grassi + 0.035 * proteine  );
            html+='Grassi: '+(grassi).toFixed(2)+"<br/>";
            html+='Proteine: '+(proteine).toFixed(2)+"<br/>";
            var indice_qualita=((latte_norm*100)-100);
            if(indice_qualita>3){
              head='Alta qualità';
              alert='low';
            }
            else if(indice_qualita>-3){
              head='Media qualità';
              alert='medium';
            }
            else{
              head='Bassa qualità';
              alert='high';
            }
            html+='Qualità del latte: '+indice_qualita.toFixed(1)+"%";


          }
          return {'head':head, 'html':html,'alert':alert };

        }},
        {'label':'pascolo','title':'Nutrizione',
        'variables': ['urea'],
        'elab':function(last_value,data){
          var urea=last_value.urea;
          var html=''; var alert='no_data'; var head='';

          if(urea===null){
            head+="Dato non disponibile";
          }
          else{
            urea=parseFloat(urea);
            if(urea<=20){
              head+="Alimentazione poco proteica";
              alert='high';
            }
            else if(urea>=40){
              head+="Alimentazione troppo proteica";
              alert='high';
            }
            else{
              head+="Alimentazione bilanciata";
              alert='low';
            }
            html+="Urea: "+urea.toFixed(0);
          }

          return {'head':head, 'html':html, 'alert':alert};

        }},
        {'label':'euro','title':'Produttività',
          'variables': ['kg','produttivita', 'kg_tot'],
          'elab':function(last_value,data){
          var parti=last_value.parti;
          var pecore=last_value.pecore_adulte;
          var agnelle=last_value.agnelle_rimonta;
          var kg=last_value.kg;
          var head='';
          var html='';
          var alert='';
          if(kg===null){
            head+="Dato non disponibile";
          }
          else{
            kg=parseFloat(kg);
            pecore=parseFloat(pecore);
            agnelle=parseFloat(agnelle);

            html+="<b>Produttività media giornaliera totale</b>: "+(kg).toFixed(2)+" kg/gg <br/>";

            if(pecore !==null && pecore>0 ){
              // parti=parseFloat(parti);
              html+="<b>Produttività mensile per pecora adulta</b>: "+(kg/pecore).toFixed(2)+" ("+kg.toFixed(0)+" kg su "+pecore.toFixed(0)+" pecore adulte)<br/>";

              var latte_norm=(kg/pecore)*(0.25+  0.085 * last_value.grassi + 0.035 * last_value.proteine  );
              html+="<b>Produttività mensile normalizzata per pecora adulta</b>: "+latte_norm.toFixed(2);
            }
            else{
              html+="Capi non disponibili";
            }



          }
          return {'head':head, 'html':html,'alert':alert };

        }}
      ];

      var html='';
      jQuery.each(global.tls, function(k,v){
        html+=getTileMain(data,k,v, id_farm_center);
      });
      return html;
    }
    else{
      jQuery("#pecorapp_body").html("<div class='alert alert-danger'>Non ci sono dati</div>");
    }
  }
  else{
    jQuery("#pecorapp_body").html("<div class='alert alert-danger'>Errore: "+data.message+"</div>");
  }
}


function addQualityCard(s, id_farm_center){

  var html='';
  // var date='2016-04-01';
  if(s.user) {
    var urladdr = global.base_call+"get_milk_data";
    jQuery.ajax({
      type: 'POST',
      data:JSON.stringify({
        id_farm_center:id_farm_center,
        // date:date
      }),
      url: urladdr,
      contentType: "application/json",
      dataType: "json",
      success: function(data){
        console.log(data);
        // debugger
        var html='';
        var blocchi=printTilesMain(data, id_farm_center);
        if (typeof blocchi!='undefined') {
          html+=blocchi;
        }


        jQuery("#milk").html(html);
        // debugger

        if (data && data.data && data.data.length>0) {
          var data_ultima_analisi=data.data[0].data_analisi;
        }

        jQuery('#milk').prepend('<h3 style="text-align:center;">Data ultima analisi: '+ data_ultima_analisi +'</h3>');

        // regola tutti i box all'altezza del più alto
        var max_height=0;
        jQuery('.main-widget-card').each(function(){
          console.log(jQuery(this).height());
          if(jQuery(this).height()>max_height){
            max_height=jQuery(this).height();
          }
        });
        // debugger
        jQuery('.main-widget-card').height(max_height+40);

      },
      error: function(jQ, status){
        console.log('fail '+urladdr);
        if( status == 'timeout' ) {
        }
      }
    });
  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }

}


function getTileMain(data,k,v, id_farm_center){
  var html='';
  var last_value=data.data[0];

  var swipePage='';
  var alert='';

  if(typeof v.elab=='function'){
    var ret=v.elab(last_value, data);
    if (ret.head!='') {
      swipePage+="<b style='font-size: 15px;' class='central'>"+ret.head+"</b><br>";
    }
    swipePage+=ret.html;

    alert=ret.alert;

  }
  else{
    html+="-";
  }

  var status='<i class="f7-icons">info</i>';
  if (alert=='low') {
    status='<i class="icon f7-icons color-green">hand_thumbsup_fill</i>';
  }
  else if (alert=='medium') {
    status='<i class="icon f7-icons color-yellow">arrow_right_arrow_left</i>';
  }
  else if (alert=='high') {
    status='<i class="icon f7-icons color-red">hand_thumbsdown_fill</i>';
  }

  var card_class='card-margin-right';
  if (isPari(k)) {
    html+='<div class="row">';
    card_class='card-margin-left';
  }

    html+=`<div onclick="location.hash='#detail/${id_farm_center}/${v.label}'" id="${v.label}" class="main-widget-card card card-no-bottom ${card_class} card-without-sides-border col">`;
      html+='<div class="card-header">'+(v.title).split(' ')[0]+status+'</div>';
      html+='<div class="card-content card-content-padding">';
        html+='<div class="swiper-container swiper-init">';
          html+='<div class="swiper-pagination"></div>';
          html+='<div class="swiper-wrapper">';
            html+='<div class="swiper-slide text-align-center">';

            html+='<div class="col-xs-3">';
                html+='<img class="tile_logo" src="icons/'+v.label+'.svg"></img>';
              html+='</div>';

              html+='<div class="col-xs-9">';

              jQuery.each(v.variables, function(kk,vv){
                var value=last_value[vv];
                var label=vv;
                if(value===null || value===undefined || value==''){ 
                  value='-';
                }
                else{
                  value=parseFloat(value);
                  if(value%1!=0){
                    value=value.toFixed(2);
                  }
                }

                if(label=='carica_batterica'){
                  label='Carica batterica';
                }
                else if(label=='cellule_somatiche'){
                  label='Cellule somatiche';
                }
                else if(label=='grassi'){
                  label='Grassi';
                }
                else if(label=='proteine'){
                  label='Proteine';
                }
                else if(label=='totale'){
                  label='Qualità del latte';
                }
                else if(label=='urea'){
                  label='Urea';
                }
                else if(label=='kg'){
                  label="Kg (media giornaliera dall'ultimo conferimento)";
                }
                else if(label=='kg_tot'){
                  label="Kg conferiti nel conferimento";
                }

                else if(label=='produttivita'){
                  label='Produttività';
                }


                if (value!=='-') {
                    html+='<div style="margin:5px;" class="tile_value">'+label+': '+value+'</div>';
                }
              });
              html+='</div>';

            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</div>';
    html+='</div>';

  if (!isPari(k)) {
    html+='</div>';
  }

  return html;
}

function isPari(n) {
   return n % 2 == 0;
}


export function detail(data, variables, id_farm_center){
  var s=storage.get();
  if(s.user) {
    var urladdr = global.base_call+"get_milk_data";
    jQuery.ajax({
      type: 'POST',
      url: urladdr,
      data:JSON.stringify({
        id_farm_center:id_farm_center,
      }),
      dataType: "json",
      contentType: "application/json",
      success: function(data){
        console.log(data);
        if(data.ok){
          if(data.data.length>0){
            var last=data.data[0];

            var calcolaTot=function (v){
              v.totale=parseFloat(v.proteine)+parseFloat(v.grassi);
              v.produttivita=parseFloat(v.kg)/parseFloat(v.parti);
              return v;
            };

            data.data=data.data.map(calcolaTot);
            data.zone_average.data=data.zone_average.data.map(calcolaTot);
            data.farm_history.data=data.farm_history.data.map(calcolaTot);

            var h='';
            if(typeof variables=='undefined'){
              location.hash='';
            }
            else{
              var ultima_analisi_data=last.data_analisi;
              // ultima_analisi_data fromato dd/mm/yyyy
              ultima_analisi_data=ultima_analisi_data.substring(8,10)+'/'+ultima_analisi_data.substring(5,7)+'/'+ultima_analisi_data.substring(0,4);
        
              h="<div><h3 class='central'>Ultima analisi disponibile: "+ultima_analisi_data+"</h3></div>";
              h+='<div class="row">';
              jQuery.each(variables, function(k,v){
                h+=getTile(data,v, variables.length,k);
              });
              h+='</div>';
            }

            h+="<div class='second_step' style='display:none;'>";
              h+='<div class="card">';
                h+='<div class="card-content card-content-padding">';
                  h+="<div id='pecora_chart' style='height:200px;'></div>";
                h+='</div>';
              h+='</div>';
              h+="<div id='pecora_table'></div>";
            h+="</div>";


            var content={'title': '', 'content': h, exclude_card:true};
            global.theme.render(content);

            jQuery('.variable').each(function(k,v){

              jQuery(v).click(function(){
                jQuery('.second_step').show();
                console.log(jQuery(v));
                var type=jQuery(v).attr('data-pecora-raw');
                var title=jQuery(v).attr('data-pecora');

                var html=getTileTable(data,type, title);

                jQuery('#pecora_table').html(html);

                var montly_data=getMontlyStat(data,type);
                var md2=[];
                jQuery.each(montly_data,function(k,v){
                  console.log(k);

                  v.month=k;
                  v.current_year=parseFloat(v.current_year);
                  v.last_year=parseFloat(v.last_year);
                  v.zone=parseFloat(v.zone);
                  md2.push(v);
                });
                jQuery('#pecora_chart').html('');

                console.log(md2);
                var chart = c3.generate({
                  bindto: '#pecora_chart',
                  data: {
                    json:md2,
                    keys:{
                      value:['current_year','last_year','zone']
                    },
                    names: {
                      current_year: 'Corrente',
                      last_year: 'Storia',
                      zone: 'Zona'
                    },
                    // x : 'month',
                    type:'spline',
                  },
                  axis : {
                    x : {
                      tick: {
                        rotate: -30,
                        multiline: false,
                        fit: true,
                        format: function (k) {
                          if (k==0) {
                            return 'Gennaio';
                          }
                          else if (k==1) {
                            return 'Febbraio';
                          }
                          else if (k==2) {
                            return 'Marzo';
                          }
                          else if (k==3) {
                            return 'Aprile';
                          }
                          else if (k==4) {
                            return 'Maggio';
                          }
                          else if (k==5) {
                            return 'Giugno';
                          }
                          else if (k==6) {
                            return 'Luglio';
                          }
                          else if (k==7) {
                            return 'Agosto';
                          }
                          else if (k==8) {
                            return 'Settembre';
                          }
                          else if (k==9) {
                            return 'Ottobre';
                          }
                          else if (k==10) {
                            return 'Novembre';
                          }
                          else if (k==11) {
                            return 'Dicembre';
                          }

                        },
                        count: 12,
                      },
                      label : 'Mese'
                    },
                    y:{
                      label: title
                    }
                  }
                });
              });
            });
          }
          else{
            // jQuery("#pecorapp_body").html("<div class='alert alert-danger'>Non ci sono dati</div>");
          }
        }
        else{
          // jQuery("#pecorapp_body").html("<div class='alert alert-danger'>Errore: "+data.message+"</div>");
        }

      },
      error: function(jQ, status){
        console.log('fail '+urladdr);
        if( status == 'timeout' ) {
        }
      }
    });
  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }
}


function getTile(data, label, num_tiles,k){
  var current_year=data.data[0].data_analisi.substring(0,4);

  var current_month=parseInt(data.data[0].data_analisi.substring(5,7));
  var tab=getMontlyStat(data, label);


  var comparison=tab[current_month];
  var okLabel=capitalizeFirstLetter(label.replace(/_/g,' '));

  var html='';
  html+= '<div class="variable col" data-pecora-raw="'+label+'" data-pecora="'+okLabel+'">';

  var classes='';

  if (k==0) {
    classes+=" card-margin-left card-without-sides-border ";
  }
  if (k==(num_tiles-1)) {
    classes+=" card-margin-right card-without-sides-border ";
  }


  html+='<div class="card '+classes+'">';
    html+='<div class="card-header central">'+okLabel+'</div>';
    html+='<div class="card-content card-content-padding">';
    if(typeof comparison !=='undefined'){

      var current_value=null;
      if(!isNaN(parseFloat(data.data[0][label]))){
        current_value=parseFloat(data.data[0][label]).toFixed(2);
      }     

      var last_year=comparison.last_year;
      var zone=comparison.zone;

      html += '<div><div class="main_value">'+current_value+'</div></div>'; //<span class="glyphicon glyphicon-arrow-up"></span>
      html += '<div class="row">';
      // debugger
      html +=  parseArrow('time',last_year, current_value,label);
      html +=  parseArrow('globe',zone, current_value,label);
        html+="</div>";
    }
    else{
      html+="No data";
    }

    html+='</div>';
  html+='</div>';

  html+='</div>';
  return html;

}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getTileTable(data, label, title){

  var current_year=data.data[0].data_analisi.substring(0,4);
  var last_year=(parseInt(current_year)-1)+"";

  var html="";
  // html = '<div class="mosca-tile col-sm-12 col-xs-12"><h3>'+label+'</h3>';
  var tab=getMontlyStat(data, label);
  html+='<div class="data-table card">';
    html+='<div class="card-header">';
      html+='<div class="data-table-title">'+title+'</div>';
    html+='</div>';
    html+='<div class="card-content">';
      html+="<table class='table'><tr><th>Mese</th><th>"+current_year+"</th><th>"+last_year+"</th><th>Media area</th></tr>";
    html+='</div>';

    jQuery.each(tab, function(k,v){

      var mese='';
      // if (k==0) {
      // }
      if (k==1) {
        mese='Gennaio';
      }
      else if (k==2) {
        mese='Febbraio';
      }
      else if (k==3) {
        mese='Marzo';
      }
      else if (k==4) {
        mese='Aprile';
      }
      else if (k==5) {
        mese='Maggio';
      }
      else if (k==6) {
        mese='Giugno';
      }
      else if (k==7) {
        mese='Luglio';
      }
      else if (k==8) {
        mese='Agosto';
      }
      else if (k==9) {
        mese='Settembre';
      }
      else if (k==10) {
        mese='Ottobre';
      }
      else if (k==11) {
        mese='Novembre';
      }
      else if (k==12) {
        mese='Dicembre';
      }

      html+="<tr><th>"+mese+"</th>"+drawCell(v.current_year,label)+drawCell(v.last_year,v.current_year,'time',label)+drawCell(v.zone,v.current_year,'globe',label)+""+"</tr>";
    });
    html+="</table>";
  html+='</div>';

  html+='</div>';

  return html;
}

function getMontlyStat(data, label){
  var current_year=data.data[0].data_analisi.substring(0,4);
  var last_year=(parseInt(current_year)-1)+"";
  var tab={};
  jQuery.each(data.zone_average.data, function(k,v2){
    if(typeof tab[v2.month] == 'undefined'){
      tab[v2.month]={};
    }
    tab[v2.month].zone=v2[label];
  });
  jQuery.each(data.farm_history.data, function(k,v1){
    if(typeof tab[v1.month] == 'undefined'){
      tab[v1.month]={};
    }
    if(v1.year==last_year){
      tab[v1.month].last_year=v1[label];
    }
    if(v1.year==current_year){
      tab[v1.month].current_year=v1[label];
    }
  });
  return tab;
}

function parseArrow(type, val, comp, field_name){
  var html='';
  if(typeof val=='undefined' || typeof comp=='undefined'){
    html='<div>-</div>';
  }
  else{
    val=parseFloat(val);
    comp=parseFloat(comp);

    var perc=100*(comp-val)/comp;
    var label=" La variazione rispetto ";
    if(type=='globe'){
      label+="la media di zona";
    }
    else{
      label+="lo scorso anno";
    }
    
    label+=" è del "+(perc).toFixed(0)+"%.";

    var aa=getArrowAndAlert(perc, field_name);

    html+='<div title="'+label+'" class="central col">';

    var icon='globe';
    if (type=='time') {
      icon='clock';
    }

    console.log(type);
    html+='<div class="label_arrow"><i class="f7-icons">'+icon+'</i></div>';
    html+='<div>'+val.toFixed(2)+"</div>";
    console.log(aa.arrow);


    if (aa.arrow=='arrow-down') {
      html+='<i class="icon f7-icons color-red">arrow_down</i>';
    }
    else if(aa.arrow=='arrow-up') {
      html+='<i class="icon f7-icons color-green">arrow_up</i>';
    }
    else if (aa.arrow=='arrow-right') {
      html+='<i class="f7-icons">arrow_right</i>';
    }

    html+='</div>';
  }
  return html;

}

function getArrowAndAlert(perc, field_name){
  console.log(field_name);

  //invert danger, low is good
  var invert=false;
  if(field_name=='cellule_somatiche'|| field_name=='carica_batterica' || field_name=='urea'){
      invert=true;
  }
  var alert="";
  var arrow="";
  if(perc>5){
    arrow="arrow-up";
    if(invert){
      alert="alert-danger";
    }
    else{
      alert="alert-success";
    }
  }
  else if(perc<-5){
    arrow="arrow-down";
    if(invert){
      alert="alert-success";
    }
    else{
      alert="alert-danger";
    }
  }
  else{
    arrow="arrow-right";
  }
  return {'arrow':arrow, 'alert':alert};
}

function drawCell(value, current, type, field_name){
  if(typeof value=='undefined'){
    return "<th>-</th>";
  }
  else{
    if(typeof current=='undefined'){
      return "<td data-toggle='tooltip'>"+parseFloat(value).toFixed(2)+"</td>";
    }
    else{
      var perc=100*(current-value)/current;
      var label=" La variazione ";
      label+=" è del "+(perc).toFixed(0)+"%.";

      var aa=getArrowAndAlert(perc,field_name);
      var arrow='<span class="glyphicon glyphicon-'+aa.arrow+'"></span>';
      var cls=aa.alert;
      return "<td class="+cls+" data-toggle='tooltip' title='"+label+"'>"+parseFloat(value).toFixed(2)+" "+arrow+"</td>";
    }
  }
}
