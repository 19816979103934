import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js';

export function download_report(){
  console.log("Downloading report");

  var s=storage.get();
  var email=s.user.email;


  var html=`
    <div class="list inset">
      <ul>

        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Anno da scaricare</div>
            <div class="item-input-wrap input-dropdown-wrap">
              <select id="anno" placeholder="Scegli...">
                <option value="all">Tutti</option>
              </select>
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Aziende</div>
            <div class="item-input-wrap input-dropdown-wrap">
              <select id="aziende" placeholder="Scegli...">
                <option value="all">Tutte</option>
              </select>
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Tipo di report</div>
            <div class="item-input-wrap input-dropdown-wrap">
              <select id="report_type" placeholder="Scegli...">
                <option value="quaderno_di_campagna">Quaderno di campagna</option>
              </select>
            </div>
          </div>
        </li>

        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">E-mail</div>
            <div class="item-input-wrap">
              <input id="report_email" type="email" value="${email}" placeholder="Your e-mail">
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
      </ul>
    </div>    

    <div class="list inset">
      <button id="send_report_request" class="button button-large button-raised button-fill">Invia report tramite mail</button>
    </div>    

    <div id="mail_response"></div>

  `;
  var page={
    exclude_card:true,
    title: t("Reports"),
    content: html
  };

  global.theme.render(page);

  jQuery('#send_report_request').on('click', function(){

    global.app.dialog.preloader("Invio la mail...");

    var anno=jQuery('#anno').val();
    var azienda=jQuery('#aziende').val();
    var report_type=jQuery('#report_type').val();
    var email=jQuery('#report_email').val();
    
    console.log("Sending report request", anno, azienda, report_type, email);

    // active loading on click


    jQuery.ajax({
      type: "POST",
      url: global.base_call+"report/"+report_type,
      headers:{
        'Authorization': 'Bearer '+s.user.access_token,
        'Content-Type': 'application/json'
      },
      data:JSON.stringify({
        anno: anno,
        azienda: azienda,
        email: email
      }),
      dataType: "json",
      success: function(email_sent){
        global.app.dialog.close();
        console.log("Report request sent", email_sent);

        if(email_sent.ok && email_sent.mail.ok){
          jQuery('#mail_response').html(`
            <div class="alert alert-success block block-strong block-outline inset">
              Mail inviata correttamente
            </div>
          `);
        }
        else{
          jQuery('#mail_response').html(`
            <div class="alert alert-danger block block-strong block-outline inset">
              ${email_sent.message}
            </div>
          `);
        }
      },
      error: function(err){
        global.app.dialog.close();

        console.error("Error sending report request", err);
        jQuery('#mail_response').html(`
          <div class="alert alert-danger block block-strong block-outline inset">
            Errore nell'invio della mail
          </div>
        `);
      }
    });

  });

}