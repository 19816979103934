/*jshint esversion: 6*/
import * as d3 from "d3v4";//
import t from '../../translate.js';
import ApexCharts from 'apexcharts';

// var Apex;
var isApp=true;


// // Fasce orizzontali....
// export function drawOliveChart(div, model_output,val_name){
//   var series=[];
//   var categories=[];

//   jQuery.each(model_output.results.values,function(k,v){
//     console.log(v);
//     var val=v[val_name];
//     series.push(val);
//     categories.push(v.date);
//   });

//   var chartHeight=350;
//   if (isApp) {
//     chartHeight=400;
//   }
//   var options = {
//     series: [{
//       name: 'Gradi giorno',//"Probabilità di ovideposizione",
//       data: series
//     }],
//     chart: {
//       toolbar:{
//         show:!isApp
//       },
//       height: chartHeight,
//       type: 'line',
//       zoom: {
//         enabled: true
//       }
//     },
//     dataLabels: {
//       enabled: false
//     },
//     stroke: {
//       curve: 'straight'
//     },
//     title: {
//       text: '',//'Probabilità di inizio delle ovideposizioni di mosca dell\'olivo',
//       align: 'left'
//     },
//     yaxis: {
//       tickAmount:5,
//       labels: {
//         formatter: function (value) {
//           return value.toFixed(1);
//         }
//       },
//     },
//     xaxis: {
//       categories: categories
//     },
//     annotations: {
//           yaxis: [{
//             y: 241.6,
//             borderColor: '#33691e',
//             label: {
//               borderColor: '#33691e',
//               style: {
//                 color: '#fff',
//                 background: '#33691e',
//               },
//               text: 'Tralcio 10cm',
//             }
//           }, 
//           {
//             y:513.7,
//             borderColor: '#ffa000',
//             label: {
//               borderColor: '#ffa000',
//               style: {
//                 color: '#fff',
//                 background: '#ffa000',
//               },
//               text: 'Fioritura',
//             }
//           }, 
//           // {
//           //   y: 4,
//           //   y2: 6,
//           //   borderColor: '#000',
//           //   fillColor: '#FEB019',
//           //   opacity: 0.2,
//           //   label: {
//           //     borderColor: '#333',
//           //     style: {
//           //       fontSize: '10px',
//           //       color: '#333',
//           //       background: '#FEB019',
//           //     },
//           //     text: 'Y-axis range',
//           //   }
//           // }
//         ],
//         },
//   };
 
//   var chart = new ApexCharts(document.querySelector('#'+div), options);
//   chart.render();
// }

// Fasce verticali....
export function drawChart(div, model_output,val_name){
  var series=[];
  var fase_fenologica=[];
  var categories=[];
  var annotations_obj={};


  // var main_stages=['vegetative rest', 'bud break', 'inflorescence visible', 'beginning of flowering (10%)', 'fruit set (10%)', 'beginning of ripening'];

  var main_stages = {
      'vegetative rest': 'Riposo vegetativo',
      'bud break': 'Fase vegetativa',
      'beginning leaf unfolded': 'Fase vegetativa',
      '5 leaves unfolded': 'Fase vegetativa',
      'inflorescence visible': 'Emergenza infiorescenze',
      'inflorescence swelling': 'Emergenza infiorescenze',
      'flowers separating': 'Emergenza infiorescenze',
      'beginning of flowering (10%)': 'Fioritura',
      'full flowering (50%)': 'Fioritura',
      'fruit set (10%)': 'Sviluppo dei frutti',
      'fruit set (50%)': 'Sviluppo dei frutti',
      'fruit set (100%)': 'Sviluppo dei frutti',
      'berries groat-sized': 'Sviluppo dei frutti',
      'berries pea-sized': 'Sviluppo dei frutti',
      'berries beginning to touch': 'Sviluppo dei frutti',
      'berries touching': 'Sviluppo dei frutti',
      'beginning of ripening': 'Maturazione',
      'berries developing colour': 'Maturazione',
      'softening of berries': 'Maturazione',
      'maturation': 'Maturazione',


      'winter dormancy': 'Gemme invernali',
      'start bud break': 'Fase vegetativa',
      'bud break': 'Fase vegetativa',
      'leaf development': 'Fase vegetativa',
      'shoot development': 'Fase vegetativa',
      'inflorescence buds are closed': 'Sviluppo bottoni fiorali',
      'inflorescence buds start to swell': 'Sviluppo bottoni fiorali',
      'corolla differentiation': 'Sviluppo bottoni fiorali',
      'beginning of flowering': 'Fioritura',
      'full flowering': 'Fioritura',
      'fruit set': 'Fioritura',
      'fruit growth after fruit set': 'Sviluppo dei frutti',
      'stone hardening 0-50%': 'Sviluppo dei frutti',
      'stone hardening >50%': 'Sviluppo dei frutti',
      'stone hardening 100%': 'Sviluppo dei frutti',
      'fruit growth green olives': 'Sviluppo dei frutti',
      'fruit growth yellow-green olives': 'Maturazione',
      'veraison 5%': 'Maturazione',
      'veraison 50%': 'Maturazione',
      'veraison 100%': 'Maturazione',
      'maturation': 'Maturazione'

  };

  var colors={
    'Riposo vegetativo':{
      light:'#CFE2F3',
      dark:'#2d74b3'
    },
    'Gemme invernali':{
      light:'#CFE2F3',
      dark:'#2d74b3'
    },
    'Fase vegetativa':{
      light:'#D9EAD3',
      dark:'#4F6228'
    },
    'Emergenza infiorescenze':{
      light:'#FFF2CC',
      dark:'#D79B00'
    },
    'Sviluppo bottoni fiorali':{
      light:'#FFF2CC',
      dark:'#D79B00'
    },
    'Fioritura':{
      light:'#F9CB9C',
      dark:'#FFA000'
    },
    'Sviluppo dei frutti':{
      light:'#EAD1DC',
      dark:'#C2185B'
    },
    'Maturazione':{
      light:'#C27BA0',
      dark:'#7B1FA2'
    }


  }


  jQuery.each(model_output.results.values,function(k,v){
    console.log(v);
    var val=v[val_name];
    series.push(val);
    fase_fenologica.push(t(v.stage));
    categories.push(resolveMillis(v.date));
    
    var main_stage=main_stages[v.stage];
    

    console.log(main_stage);
    if(main_stage){
      if(!annotations_obj[main_stage] ){
        var color=colors[main_stage].dark;
        var lighterColor = colors[main_stage].light;

        annotations_obj[main_stage]={
          x:resolveMillis(v.date),
          fillColor: lighterColor,
          opacity: 0.5,
          label: {
            borderColor: lighterColor,
            style: {
              fontSize: '10px',
              color: '#fff',
              background: color,
            },
            offsetY: -10,
            text: main_stage,
          }
          
        };
      }
      else{
        annotations_obj[main_stage].x2=resolveMillis(v.date);
      }
    }
  });

  var annotations=[];
  jQuery.each(annotations_obj,function(k,v){
    annotations.push(v);
  });

  // console.log(annotations);

  var chartHeight=350;
  if (isApp) {
    chartHeight=400;
  }
  var options = {
    series: [{
      name: 'Gradi giorno',
      data: series
    }],
    fase_fenologica:fase_fenologica,
    chart: {
      toolbar:{
        show:!isApp
      },
      height: chartHeight,
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#263238'],
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left'
    },
    yaxis: {
      title: {
        text: t('Gradi giorno (>10 °C)')
      },
      tickAmount:5,
      labels: {
        formatter: function (value) {
          return value.toFixed(1);
        }
      },
    },
    xaxis: {
      // max:10,
      // show: false,
      tickAmount: 10,
      categories: categories,
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        // debugger
        return (
          '<div class="arrow_box">' +
          "<span>Gradi giorno: " +series[seriesIndex][dataPointIndex].toFixed(1) +"</span>" +
          "<br/>"+
          "<span>Fase fenologica: " +w.config.fase_fenologica[dataPointIndex] +"</span>" +
          "</div>"
        );
      }
      // y: {
      //   formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
      //     return value + " - " + w.config.fase_fenologica[dataPointIndex];
      //   }
      // }
    },
    annotations: {
      xaxis: annotations,
    },
  };
 
  var chart = new ApexCharts(document.querySelector('#'+div), options);
  chart.render();
}

function resolveMillis(value){
  
  var date = new Date(value);
  var year = date.getFullYear();
  var month = date.getMonth()+1;
  var dt = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (value<0){
    return '';
  }

  return dt + '/' + month + '/' + year;
}