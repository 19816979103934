
import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js';
import * as db from './data.js';
import mapHelper from '../../core/map.js';
import * as c3 from 'c3';
import 'c3/c3.css';

export function ws_map(id_farm_center){
  var farm=db.getFarm(id_farm_center);

  var html=`
    <div class="list inset nmt" >
      <ul>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">${t('Stazione meteo')}</div>
            <div class="item-input-wrap input-dropdown-wrap">
              <select id="weather_station"><option>Caricamento in corso...</option></select>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="list inset nmt" >
      <ul>
        <li>
          <div style="border-radius:10px;" id="map_container" height="257px;"></div>         
        </li>
      </ul>
    </div>

    <div id="ws_status_container" style="display:none;">
      <div class="block-title">Stato dati meteo</div>
      <div class="block block-strong inset nmt" id="ws_status" ></div>
    </div>

    <div class="list inset nmt" >
      <ul>
        <li>
          <div id="ws_last_data_chart" style="text-align:center;"></div>
        </li>
      </ul>
    </div>

    <div id="ws_forecast_data"></div>

  `;
  var page={
    exclude_card:true,
    title: t("Gestione stazione meteo"),
    content: html
  };

  global.theme.render(page);

  var point={latitude:farm.lat,longitude:farm.lon};

  jQuery('#map_container').css('height','275px');
  global.createMap('map_container',point,10);

  var map=new mapHelper();
  map.addMarker([farm.lat, farm.lon]);



  var s=storage.get();
  var urladdr = global.base_path+"aedita/getSW";
  var headers={};
  var request={
    access_token:s.user.access_token,
    lat:farm.lat,
    lon:farm.lon
  };
  if(global.plus){
    urladdr=global.base_call+"getSW";
    headers={
      'Authorization': 'Bearer '+s.user.access_token,
      "Content-Type": "application/json",
    }
    request=JSON.stringify(request);
  }
  jQuery.ajax({
    type: "POST",
    headers: headers,
    url: urladdr,
    data:request,
    dataType: "json",
    success: function(ws){

      if(ws.data.length==0){
        jQuery('#weather_station').html('<option value="0">Nessuna stazione meteo trovata</option>');
      }
      else{
        console.log(ws.data);
        console.log(farm);
        
        var id_station=null;
        if (farm && farm.id_station) {
          id_station=farm.id_station;
        }
        else {
          id_station=ws.data[0].id_station;
        }
        
        var station_name=null;
        if(farm && farm.station_name){
          station_name=farm.station_name;
        }
        else{
          station_name=ws.data[0].name;
        }

        var opt_ws='';
        jQuery.each(ws.data,function(k,v){
          if(v.id_station==id_station){
            station_name=v.name;
          }
          opt_ws+=`<option value="${v.id_station}" ${v.id_station==id_station?'selected':''} >${v.name} (${parseFloat(v.distance).toFixed(2)} km)</option>`;
        });
        jQuery('#weather_station').html(opt_ws).change();

        manageWSMap(ws);
      }
    },
    error: function(e){
      console.log(e);
      jQuery('#weather_station').html('<option value="0">Nessuna stazione meteo trovata</option>');
      var msg=`
        <span class="station_alert badge color-red" style="padding:3px; margin:10px; font-size:13px; display: inline-table; border-radius: 10px;">
          <a class="link external" style="color:white;">
            Controlla la connessione internet e riprova più tardi, se il problema dovesse persistere contatta l'assistenza.
          </a>
        </span>
      `;

      jQuery('#ws_message').html(msg);
    },
    timeout: 4000
  });

  jQuery('#footer').html(`
    <button id="update_ws" class="button button-fill button-raised" id="save_ws">Salva</button>
  `);

  jQuery('#update_ws').click(function(){
    var id_station=jQuery('#weather_station').val();
    var station_name=jQuery('#weather_station option:selected').text();

    var new_farm=farm;
    new_farm.id_farm_center= farm.id_farm_center,
    new_farm.sync=false,
    new_farm.id_station=id_station,
    new_farm.station_name= station_name

    db.updateFarm(id_farm_center,new_farm);
  });

  jQuery('#weather_station').change(function(){
    var id_station=jQuery(this).val();
    checkWSStatus(id_station);
  });


}

function checkWSStatus(id_station){
  var s=storage.get();
  jQuery('#ws_status_container, #ws_last_data_chart, #ws_forecast_data').hide();
  
  var urladdr = global.base_path+"aedita/get_WS_status";
  var headers={};
  var request={
    access_token:s.user.access_token,
    id_station:id_station
  };
  if(global.plus){
    urladdr=global.base_call+"get_WS_status";
    headers={
      'Authorization': 'Bearer '+s.user.access_token,
      "Content-Type": "application/json",
    }
    request=JSON.stringify(request);
  }
  jQuery.ajax({
    type: "POST",
    headers: headers,
    url: urladdr,
    data:request,
    dataType: "json",
    success: function(data){

      if(data.forecast){
        jQuery('#ws_forecast_data').show().html(`
          <div class="block-title">Previsioni meteo</div>
          <div class="row no-gap" id="forecast_center"></div>
        `);

          
        jQuery.each(data.forecast.daily,function(k,v){     
          var first_record=data.forecast.daily[0];
          var millis=first_record.dt*1000;
          var date=new Date(millis);
          
          // check if the first day is today
          var today=new Date();
          today.setHours(12);
          today.setMinutes(0);
          today.setSeconds(0);

          // today.setHours(0,0,0,0);
          // se today è vicino a date di 4 ore allora è oggi
          var days_to_show=3;
          var start_k=0;
          if(Math.abs(today-date)<1000*60*60*4){
            start_k=1;
            days_to_show=4;
          }

          if(k>=start_k && k<days_to_show){
            var millis=v.dt*1000;
            var date=new Date(millis);
            console.log(date);
            console.log(v);

            // get the day name in italian
            var days = ['Dom','Lun','Mar','Mer','Gio','Ven','Sab'];
            var dayName=days[date.getDay()];

            var html=`
              <div class="col-33">            
                <div class="card">
                  <div class="card-content card-content-padding">
                    <div><b>${dayName} ${date.getDate()}</b></div>
                    <img src="https://openweathermap.org/img/wn/${v.weather[0].icon}@2x.png" style="width:100%;"/>        
                    <div>${v.temp.min.toFixed(0)}°C / ${v.temp.max.toFixed(0)}°C</div>
                    <div>${v.rain?parseFloat(v.rain).toFixed(1):0} mm</div>    
                  </div>
                </div>
              </div>
            `;
            jQuery('#forecast_center').append(html);
          }
        });
      } 


      if(data.ok){
        var status=data.data[0];

        var color,message;
        var three_days_ago=new Date();
        three_days_ago.setDate(three_days_ago.getDate() - 3);
        var last_available_date=new Date(status.last_available_date);

        if(last_available_date>three_days_ago){
          message='Dati meteo disponibili al '+(status.data_present_percentage).toFixed(1)+' %';
          color='green';
        }
        else{
          message='Dati meteo non disponibili - ultimo aggiornamento '+status.last_available_date+' ('+(status.data_present_percentage).toFixed(1)+' % di dati disponibili)';
          color='red';
        }

        jQuery('#ws_status_container').show();
        jQuery('#ws_status').html(`
          <div class="status-light sl-${color}"></div> ${message}
        `);

        var weather=data.last_weather.data;
        console.log(weather.reverse());
        jQuery('#ws_last_data_chart').show();
        var chart = c3.generate({
          bindto: '#ws_last_data_chart',
          data: {
            x:'datetime',
            json:weather.reverse(),
            keys:{
              value:['datetime','tmin','tmax','tavg','psum']
            },
            names: {
              tmin: 'Temperatura minima giornaliera',
              tmax: 'Temperatura massima giornaliera',
              tavg: 'Temperatura media giornaliera',
              psum: 'Pioggia giornaliera'
            },
            axes: {
              tmin: 'y',
              tavg: 'y',
              tmax: 'y',
              psum: 'y2'
            },
            colors: {
              tmin: '#C9DEED',
              tavg: '#40A940',
              tmax: '#DA3C3D',
              psum: '#1F77B4'
            },
            type:'spline',
            types:{
              psum:'bar'
            }
          },
          axis : {
            x : {
              type : 'timeseries',
              tick: {
                format: '%d/%m/%Y'
              },
            },
            y:{
              label: 'Temp (°C)'
            },
            y2: {
              show: true,
              label: 'Pioggia (mm)'
            }
          }
        });

        // var table=`
        //   <table>
        //     <thead>
        //       <tr>
        //         <th>Data</th>
        //         <th>Temp. min</th>
        //         <th>Temp. media</th>
        //         <th>Temp. max</th>
        //         <th>Pioggia</th>
        //       </tr>
        //     </thead>
        //     <tbody>
        // `;
        // jQuery.each(weather,function(k,v){
        //   table+=`
        //     <tr>
        //       <td>${v.datetime}</td>
        //       <td>${v.tmin}</td>
        //       <td>${v.tavg}</td>
        //       <td>${v.tmax}</td>
        //       <td>${v.psum}</td>
        //     </tr>
        //   `;
        // });
        // table+=`
        //     </tbody>
        //   </table>
        // `;
        // jQuery('#ws_last_data_table').html(table);



      }
      else{
        jQuery('#ws_status_container').show();
        jQuery('#ws_status').html(data.message);
      }
    },
    error: function(a,b,c){
      console.log(a,b,c);
    
      var msg=`
        Controlla la connessione internet e riprova più tardi, se il problema dovesse persistere contatta l'assistenza.
      `;

      jQuery('#ws_status_container').show();
      jQuery('#ws_status').html(msg);
    }
  });
}

function manageWSMap(ws){
  var wsIcon=L.icon({
      iconUrl: 'icons/ws.png',
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -75],
  });
  var wsSelectedIcon=L.icon({
      iconUrl: 'icons/ws-fill.png',
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -75],
  });


  var markersGroup = L.layerGroup().addTo(global.map);
  jQuery.each(ws.data,function(k,v){

    if(v.id_station==jQuery('#weather_station').val()){
      var marker=L.marker([v.lat, v.lon],{icon: wsSelectedIcon});
    }
    else{
      var marker=L.marker([v.lat, v.lon],{icon: wsIcon});
    }

    jQuery('#weather_station').change(function(){
      if(v.id_station==jQuery(this).val()){
        marker.setIcon(wsSelectedIcon);
      }
      else{
        marker.setIcon(wsIcon);
      }
    });

    marker.on('click',function(){
      jQuery('#weather_station').val(v.id_station);
      jQuery('#weather_station').trigger('change');

      // change icon with default icon for all markers
      markersGroup.eachLayer(function (layer) {
        layer.setIcon(wsIcon);
      });

      // change icon with selected icon only for this marker
      marker.setIcon(wsSelectedIcon);

    });

    marker.addTo(markersGroup);
  });
}


export function addStation(id_farm_center){
  console.log("ADD STATION");

  // i need a form with lat, lon, ID_STAZIONE, Nome, private_key, public_key
  var form=`
    <div class="list inset nmt" >
      <ul>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Latitudine</div>
            <div class="item-input-wrap">
              <input type="text" id="lat" placeholder="Latitudine" value="45.123456" />
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Longitudine</div>
            <div class="item-input-wrap">
              <input type="text" id="lon" placeholder="Longitudine" value="9.123456" />
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">ID stazione</div>
            <div class="item-input-wrap">
              <input type="text" id="id_station" placeholder="ID stazione" value="123456" />
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Nome stazione</div>
            <div class="item-input-wrap">
              <input type="text" id="name" placeholder="Nome stazione" value="Stazione meteo" />
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Chiave privata</div>
            <div class="item-input-wrap">
              <input type="text" id="private_key" placeholder="Chiave privata" value="123456" />
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Chiave pubblica</div>
            <div class="item-input-wrap">
              <input type="text" id="public_key" placeholder="Chiave pubblica" value="123456" />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="block block-strong inset nmt" style="display:none;" id="ws_message"></div>      
  `;

  var page={
    exclude_card:true,
    title: t("Aggiungi stazione meteo FieldClimate"),
    content: form
  };

  global.theme.render(page);

  // alert('I dati della stazione meteo inserita saranno visibili a tutti gli utenti di Poderi');

  jQuery('#footer').html(`
    <button id="save_ws" class="button button-fill button-raised" id="save_ws">Salva</button>
  `);


  jQuery('#save_ws').click(function(){
    if(global.plus){
      var s=storage.get();
      var urladdr=global.base_call+"addSW";
      var headers={
        'Authorization': 'Bearer '+s.user.access_token,
        "Content-Type": "application/json",
      }
      jQuery.ajax({
        type: "POST",
        url: urladdr,
        data:JSON.stringify({
          lat:jQuery('#lat').val(),
          lon:jQuery('#lon').val(),
          id_station:jQuery('#id_station').val(),
          name:jQuery('#name').val(),
          private_key:jQuery('#private_key').val(),
          public_key:jQuery('#public_key').val()
        }),
        headers: headers,
        dataType: "json",
        success: function(data){
        },
        error_: function(e){
          console.log(e);
          var msg=`
            <span class="station_alert badge color-red" style="padding:3px; margin:10px; font-size:13px; display: inline-table; border-radius: 10px;">
              <a class="link external" style="color:white;">
                Controlla la connessione internet e riprova più tardi, se il problema dovesse persistere contatta l'assistenza.
              </a>
            </span>
          `;
          jQuery('#ws_message').html(msg);
        },
        timeout: 8000
      });
    }
    else{
      alert('Non dovresti essere qui...')
    }
  });
}