import t from '../../translate.js'
import * as d3 from "d3v4";//
import './smartipm.css';
import './martina_theme.css';
import * as arcModel from './arcWidget.js';
import ApexCharts from 'apexcharts';
var isApp=true;

// SmartIpm.PlasmoWidgetSimple = SmartIpm.AbstractWidget.extend({
  function post_init(){
    var self=this;


    self.table_formatter=[
      // {'field':'datetime','label':t('Date')},
      {'field':'datetime','label':t('Date'),
        'format': function(d){
          var val=parseFloat(d);
          var aDate=d.split('-');
          var txt=aDate[2]+"/"+aDate[1]+"/"+aDate[0];
          return txt;
        }
      },
      {'field':'tavg','label':t('Temperatura media'),
        'format': function(d, v, k){
          return v.debug.tavg;
        }
      },
      {'field':'psum','label':t('Pioggia giornaliera'),
        'format': function(d, v, k){
          return v.debug.psum;
        }
      },
      {'field':'debug','label':t('Stato'),
        'format': function(d, v, k){
          var stat='';
          var stt=parseInt(v.status);

          if(stt===0 || stt===1 || stt===20){
            stat=t("Non ci sono infezioni");
          }
          else{
            stat=t("Ci sono delle infezioni in atto");
          }
          return stat;
          }
      },
      {'field':'debug','label':t('Trattamento Consigliato'),
        'format': function(d, v, k){
          var stat='';
          var stt=parseInt(v.status);

          if(stt===0 || stt===1 || stt===20){
            stat=t("Non trattare");
          }
          else if(stt===110){
            stat=t("Citotropici o Sistemici");
          }
          else if(stt===120){
            stat=t("Sistemici");
          }
          else if(stt===130){
            stat=t("Attendi per trattare");
          }
          else if(stt===140){
            stat=t("Prodotti di Contatto");
          }
          else{
            stat=t("-"+stt);
          }
          return stat;
          }
      },
      {'field':'debug','label':t('Infezioni Primarie'),
        'format': function(d, v, k){
          var imax=self.calculateMax(v.infections.primary);
          if(imax===0){
            return "<div class='badge progress-bar-success'>Assenti</div>";
          }
          else{
            return "<div class='badge progress-bar-danger'>Presenti</div> "+self.listInfections(v.infections.primary)+"";
          }
        }
      },
      {'field':'debug','label':t('Infezioni Secondarie'),
        'format': function(d, v, k){
          var imax=self.calculateMax(v.infections.secondary);
          if(imax===0){
            return "<div class='badge progress-bar-success'>Assenti</div>";
          }
          else{
            return "<div class='badge progress-bar-danger'>Presenti</div> "+self.listInfections(v.infections.secondary)+"";
          }
        }
      }
    ];
  }
  function prepareData (data, opt){
    return data;
  }

  export function drawChart(div, data, opt){
    var self=this;  
    var series_primary=[];
    var series_secondary=[];
    var categories=[];

    // debugger
    jQuery.each(data.results.values,function(k,v){
      console.log(v);
      if(v.ok){
        var primary=calculateMax(v.data.infections.primary);
        var secondary=calculateMax(v.data.infections.secondary); 
        
        // debugger
        series_primary.push(primary);
        series_secondary.push(secondary);

        categories.push(v.data.date);
      }
    });


      var chartHeight=350;
  if (isApp) {
    chartHeight=200;
  }
   var options = {
     series: [
        {
          name: 'Infezione primaria',
          data: series_primary
        },
        {
          name: 'Infezione secondaria',
          data: series_secondary
        }
      ],
     chart: {
       toolbar:{
         show:!isApp
       },
       height: chartHeight,
       type: 'line',
       zoom: {
         enabled: false
       }
     },
     colors: ['#f44336', '#4caf50'],
     dataLabels: {
       enabled: false
     },
     stroke: {
       curve: 'smooth',
        width: 2
     },
     title: {
        text: '',//'Probabilità di inizio delle ovideposizioni di mosca dell\'olivo',
        align: 'left'
      },
     yaxis: {
       tickAmount:5,
        min: 0,
        max: 100,
        labels: {
          formatter: function (value) {
            console.log("formatter", value);
            return value.toFixed(0) + "%";
          }
        },
      },
     xaxis: {
       categories: categories,
      tickAmount:12

     }
   };

   // debugger
   var chart = new ApexCharts(document.querySelector('#'+div), options);
   chart.render();

  
  }



  export function drawOLDChart(div, rect_array,opt) {


    var color="#009688";
    var self=this;
    var g=new D3Chart(rect_array, {
      'div': div,
      'type':'line',
      'dim_x': function(d,i) {return new Date(d.datetime).getTime();},
      'label':t('Value'),
      'dim_y': [
        {
          'dim_y': function(d,i){
            return self.calculateMax(d.infections.primary);
          },
          'dim_y_name': 'l1',
          'type':'line',
          'stroke_width':1,
          'label':t('Infezione primaria'),
          'color':'#FF0000'
        },
        {
          'dim_y': function(d,i){
            return self.calculateMax(d.infections.secondary);
          },
          'dim_y_name': 'l1',
          'type':'line',
          'stroke_width':1,
          'label':t('Infezioni secondarie'),
          'color':'#00FF00'
        },
      ],
      'dim_z': function(d) {return d.var_name;},
      'legend':true,
      'color':color,
      'padding':{'left':65, 'top':30, 'bottom':30, 'right':30},
      'axis_x': {
          mode: "time",
          label:"Data"
        },
      'axis_y': {
          label:"Development"
        },
      'interpolate': d3.curveLinear
    });
  }
  function calculateMax(infections){
   var imax=0;
   for(var i in infections){
     var inf=infections[i];
     if(!inf.completed){
       imax=Math.max(imax,inf.germination);
     }
   }
   return imax;
 }
  function listInfections(infections){
  var ret=' ';
  for(var i in infections){
    var inf=infections[i];
    if(!inf.completed){
      ret+=(inf.germination).toFixed(0)+"%,";
    }
  }
  ret=ret.slice(0, -1);
  return ret;
}
  export function drawSummary (div, data, opt){
    if (div[0]=='#') {
      div=div.substring(1);
    }
    var self=this;
    var res=data.final_value.data;
    if(!res){
      res=data.final_value;
    }
    var alert_level="info";
      
    var mmdd='';
    if(res && res.date){
      mmdd=res.date.substr(5,5);
    }
      // debugger


    var status="";

    // debugger
    if(mmdd>='09-15' || res.message=="End of season"){
      // alert_level= "info";
      // status=t("La stagione è finita");
      jQuery("#"+div).html(`<div style="border-radius: 30px;padding: 1px;" class="alert-level alert_level_info"><h2>${t('Il ciclo produttivo della coltura è terminato')}</h2></div>`);
      return;
    }
    else{
      var stt=parseInt(res.status);

      if(stt===0 || stt===1 || stt===20){
        status=t("Non ci sono infezioni");
        alert_level= "low";
      }
      else if(res.ok==false){
        // alert_level= "medium";
        // status=t(res.message);
        jQuery("#"+div).html(`<div style="border-radius: 30px;padding: 1px;" class="alert-level alert_level_info"><h2>${t(res.message)}</h2></div>`);
        return;
      }
      else{
        alert_level= "high";
        status=t("Ci sono delle infezioni in corso");
      }
    }
    var desc="";
    if(res.infections){

      // var html='<div class="panel panel-info">Plasmo';
      desc+=" "+t("Ci sono state")+" "+(res.infections.primary.length)+" "+t("infezioni primarie e")+" "+(res.infections.secondary.length)+" "+t("infezioni secondarie");
      // //html+="The crop current stage is <b>"+data.final_value.stage+"</b>. The crop has cumulated "+data.final_value.day_degree.toFixed(0)+" Day degree";
      // html+="</div>";
    }

    arcModel.setContent(div, opt, {'main':status, 'sub': desc, 'pictogram':"", 'alert_level': alert_level});

    jQuery("#"+div).css("border-radius", "30px");

  }
// });
