
import * as db from './data.js';
import t from '../../translate.js';
import global from '../../global.js';
import storage from '../../storage.js';
import * as tools from '../../tools.js';
import QRCode from 'qrcode'


export function marketing_home(id_farm){

  let fc=db.get_elements_filtered('farms', id_farm);

  let farm=fc[0];
  
  console.log("marketing_home", fc);

  if(!farm.marketing){
    farm.marketing={};
  }

  // debugger

  var farm_marketing=farm.marketing;


  let farmMarketingPageVisibility = farm_marketing.page_visibility;

  if(!farmMarketingPageVisibility){
    openVerticalButtons(id_farm);
  }
  else{
    if(farmMarketingPageVisibility == 'yes'){
      console.log("farmMarketingPageVisibility", farmMarketingPageVisibility);
    }
    else if(farmMarketingPageVisibility == 'not-in-list'){
      console.log("farmMarketingPageVisibility", farmMarketingPageVisibility);
    }
    else{
      console.log("farmMarketingPageVisibility", farmMarketingPageVisibility);
      openVerticalButtons(id_farm);
    }
  }


  var status={
    'yes':'PUBBLICATA',
    'no':'NON PUBBLICATA',
    'not-in-list':'RISERVATA'
  }

  console.log("ciao "+id_farm);

  var html = `

      <div style="text-align:center; display:${farm_marketing.page_visibility?'block':'none'};" class="block-title">Attualmente la pagine è: ${status[farm_marketing.page_visibility]}</div>
    <button onclick="openVerticalButtons('${id_farm}')" style="text-decoration: underline;" class="button">Cambia opzioni di visualizzazione</button>


    <div class="block block-strong-ios block-outline-ios">
      <div class="list inset no-hairlines-md">
        <ul>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">E-mail</div>
              <div class="item-input-wrap">
                <input id="marketing_mail" type="email" placeholder="La tua e-mail" value="${farm_marketing.mail?farm_marketing.mail:''}" >
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Telefono</div>
              <div class="item-input-wrap">
                <input id="marketing_tel" type="tel" placeholder="Il tuo telefono" value="${farm_marketing.tel?farm_marketing.tel:''}">
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">URL pagina Facebook</div>
              <div class="item-input-wrap">
                <input id="marketing_facebook" type="url" placeholder="Facebook" value="${farm_marketing.facebook?farm_marketing.facebook:''}">
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">URL pagina Instagram</div>
              <div class="item-input-wrap">
                <input id="marketing_instagram"  type="url" placeholder="Instagram" value="${farm_marketing.instagram?farm_marketing.instagram:''}">
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">URL pagina X</div>
              <div class="item-input-wrap">
                <input id="marketing_x"  type="url" placeholder="X"   value="${farm_marketing.x?farm_marketing.x:''}">
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Descrizione dell'azienda</div>
              <div class="item-input-wrap">
                <input id="marketing_descrizione_azienda" type="text" placeholder="Descrizione dell'azienda" value="${farm_marketing.descrizione_azienda?farm_marketing.descrizione_azienda:''}">
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Descrizione dei prodotti</div>
              <div class="item-input-wrap">
                <input id="marketing_descrizione_prodotti" type="text" placeholder="Descrizione dei prodotti" value="${farm_marketing.descrizione_prodotti?farm_marketing.descrizione_prodotti:''}">
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>

        </ul>
      </div>
  
      <div class="list inset no-hairlines-md">
        <button style="justify-content: center;display: flex;" id="shareQR" class="button button-fill button-round"><i style="color:white;" class="f7-icons">qrcode</i> Condividi il QR Code</button>
      </div>

      
    </div>

  `;

  global.theme.render({
    "title": t("Pagina aziendale"),
    "content": html,
    exclude_card:true
  });  



  if(farm_marketing.page_visibility && farm_marketing.page_visibility!='no'){

    jQuery('#footer').html(`
      <button id="save_marketing" class="inset button button-fill button-round">Salva</button>
    `);

    // jQuery('#shareQR').on('click', function(){

    //   var url_to_share= global.base_path +"#m/"+id_farm;





    //   return QRCode.toDataURL(url_to_share)
    //   .then(base64String => {
    //     console.log(base64String)


    //     var base64_string = base64String.split(',')[1];
    //     var blob = base64ToBlob(base64_string, 'image/png');

       
        
    //     var file = new File([blob], "qrcode.png", {type:  'image/png'});
    //     console.log(file, file.size, file.type);


    //     // setTimeout(function() {

    //       if(navigator.share){
    //         try{
    //           navigator.share({
    //             title: 'Condividi il QR Code',
    //             text: 'Condividi il QR Code',
    //             files: [file],
    //             url: url_to_share
    //           })
    //         }
    //         catch(err){
    //           console.error(err);
    //         }
    //       }

    //     // }, 1000);

    //   })
    //   .catch(err => {
    //     console.error(err)
    //   });
    // });


    jQuery('#shareQR').on('click', function(){
      var url_to_share= global.base_path +"#m/"+id_farm;
    
      return QRCode.toDataURL(url_to_share)
        .then(base64String => {



          console.log('QR Code Data URL:', base64String);
          
          var base64_string = base64String.split(',')[1];
          var blob = base64ToBlob(base64_string, 'image/png');
          console.log('Blob created:', blob);
    
          var file = new File([blob], "qrcode.png", {type: 'image/png'});
          console.log('File created:', file, file.size, file.type);
    
          if (navigator.share) {
            try {
              console.log('Trying to share file...');
               
              var shareOpt = {
                title: 'Condividi il QR Code',
                text: 'Poderi - La pagina aziendale',
                url: url_to_share
              };

              if(! global.iOS()){
                shareOpt.files = [file];
              }

              navigator.share(shareOpt).then(() => {
                console.log('File shared successfully');
              }).catch(err => {
                console.error('Error sharing file:', err);
              });
            } catch (err) {
              console.error('Navigator share error:', err);
            }
          } else {
            console.warn('Navigator share not supported');
          }
        })
        .catch(err => {
          console.error('QRCode toDataURL error:', err);
        });
    });

  }



  jQuery('.block-title').css('padding-left','15px');


  jQuery('#save_marketing').on('click', function(){

    var farm=fc[0];

    farm.sync=false;
    if(!farm.marketing){
      farm.marketing={};
    }    
    farm.marketing.mail=jQuery('#marketing_mail').val();
    farm.marketing.tel=jQuery('#marketing_tel').val();
    farm.marketing.facebook=jQuery('#marketing_facebook').val();
    farm.marketing.instagram=jQuery('#marketing_instagram').val();
    farm.marketing.x=jQuery('#marketing_x').val();
    farm.marketing.descrizione_azienda=jQuery('#marketing_descrizione_azienda').val();
    farm.marketing.descrizione_prodotti=jQuery('#marketing_descrizione_prodotti').val();

    db.updateFarm(id_farm,farm);
  });
}



// Funzione per convertire Base64 in Blob
function base64ToBlob(base64, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;

}


function openVerticalButtons(id_farm){
  global.app.dialog.create({
    title: "Scegli la visibilità della tua pagina",
    buttons: [
      {
        text: 'Non pubblicata',        
        onClick: function(){
          setPageVisibility(id_farm, 'no');
        }
      },
      {
        text: 'Pubblica',        
        onClick: function(){
          setPageVisibility(id_farm, 'yes');
        }
      },
      {
        text: 'Riservata',
        onClick: function(){
          setPageVisibility(id_farm, 'not-in-list');
        }
      },
    ],
    verticalButtons: true,
  }).open();

}
window.openVerticalButtons = openVerticalButtons;


function setPageVisibility(id_farm, visibility){

  let fc=db.get_elements_filtered('farms', id_farm);
  let farm=fc[0];

  var new_farm=farm;
  new_farm.sync=false;

  if(!new_farm.marketing){
    new_farm.marketing={};
  }    
  new_farm.marketing.page_visibility=visibility;


  // active loading
  global.app.preloader.show();

  db.updateFarm(id_farm,new_farm, function(){
    // hide loading
    global.app.preloader.hide();

    if(visibility=='no'){
      location.hash='farm/'+id_farm;
    }
    else{
      location.reload();
    }
  });

}