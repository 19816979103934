
import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js'; 
import * as db from './data.js';

export function vocal_message(){

  var s=storage.get();

  var farms='';
  farms+=`<option value="" selected disabled>Scegli...</option>`;
  
  jQuery.each(s.farms, function(i, farm){
    farms+=`<option value="${farm.id_farm_center}">${farm.name}</option>`;
  });



  var html=`
    <div class="list inset">
      <ul>

        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Azienda</div>
            <div class="item-input-wrap input-dropdown-wrap">
              <select id="farm" placeholder="Scegli...">
                ${farms}
              </select>
            </div>
          </div>
        </li>

        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Messaggio</div>
            <div class="item-input-wrap">
              <textarea id="message" placeholder="Messaggio"></textarea>
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
      </ul>
    </div>    

    <div class="list inset">
      <button id="send_message_request" class="button button-large button-raised button-fill">Invia vocale a Poderi</button>
    </div>    

    <div id="message_response"></div>
  `;
  var page={
    exclude_card:true,
    title: t("Reports"),
    content: html
  };

  global.theme.render(page);

  jQuery('#send_message_request').on('click', function(){
    global.app.dialog.preloader("Invio il messaggio...");

    var message=jQuery('#message').val();
    var id_farm_center=jQuery('#farm').val();

    var fields=db.getFields(id_farm_center);

    // debugger

    var prompt_help='I campi interessati disponibili sono: ';
    jQuery.each(fields, function(i, field){
      if(field.name){
        if(field.crop_name){
          prompt_help+=field.name;
          prompt_help+=' con coltura '+field.crop_name;
        }
        prompt_help+='; ';
      }
      
    });
    prompt_help=prompt_help.substring(0, prompt_help.length-2);


    if(fields.length==0){
      prompt_help='Nessun campo disponibile';
    }


    // prompt_help+='. Oggi è il giorno '+new Date().toLocaleDateString()+'.';


    jQuery.ajax({
      type: "POST",
      url: global.base_call+"messages/ai_message",
      headers:{
        'Authorization': 'Bearer '+s.user.access_token,
        'Content-Type': 'application/json'
      },
      data:JSON.stringify({
        message: message,
        id_farm_center: id_farm_center,
        prompt_help:prompt_help
      }),
      dataType: "json",
      success: function(message_sent){
        global.app.dialog.close();

        if(message_sent.ok){
          jQuery('#message_response').html(`
            <div class="alert alert-success block block-strong block-outline inset">
              Messaggio inviata correttamente
            </div>
          `);
        }
        else{
          jQuery('#message_response').html(`
            <div class="alert alert-danger block block-strong block-outline inset">
              ${message_sent.message}
            </div>
          `);
        }
      },
      error: function(err){
        global.app.dialog.close();

        console.error("Error sending report request", err);
        jQuery('#message_response').html(`
          <div class="alert alert-danger block block-strong block-outline inset">
            Errore nell'invio del messaggio
          </div>
        `);
      }
    });
  });
}