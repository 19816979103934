/*jshint esversion: 6 */

import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js';
import mapHelper from '../../core/map.js';
import gps from '../../core/gps.js';
import * as tools from '../../tools.js';
import * as db from './data.js';
import * as model from './fairshare_model.js';
import * as fairshare_soil from './fairshare_soil.js';
import * as fairshare_report from './fairshare_report.js';
import * as dbmng_ext from '../../core/dbmng_ext.js';
import * as fairshare_zoo from './fairshare_zoo.js';


export function start(){


  let s=storage.get();

  if(s.user && s.user.uid){


    // var html=`
    //   <div class="scelta_utente">
    //     <button onclick="location.hash='create_farm_center/grower'" class="col button button-large button-outline"><img src="icons/azienda.png"/>${t('Sono una azienda')}</button>
    //     <button onclick="location.hash='farms'" class="col button button-large button-outline"><img src="icons/agronomo.png"/>${t('Sono un agronomo')}</button>
    //   </div>
    // `;
    var html=`
      <button onclick="location.hash='farms'" class="button fs-button button-large button-fill">${t('Inizia')}</button> 
    `;
    var page={
      exclude_card:true,
      title: t("Benvenuto in Poderi."),
      content: html
    };

    global.theme.render(page);
  }
  else{
    global.theme.render({
      exclude_card:true,
      title: t("Poderi"),
      content:  `
        <div class="block-title">${t('Benvenuto in Poderi')}</div>
        <div class="block">${t('L\'app ti permette di facilitare lo scambio dati tra aziende agricole e tecnici dell\'assistenza tecnica.')}</div>
        <div class="block">${t('Se vuoi accedere clicca su Accedi. ')} 
          <a onclick="location.hash='login'" class="button button-fill">${t('Accedi')}</a>
        </div>
      `
    });


  }



}

// Funzione che mostra tutte le farm disponibili e da la possilità ad un agronomo di inserire una nuova azienda
export function farms(){

  global.chat_to_sync=true;

  var s=storage.get();
  var map=new mapHelper();

  // var title=t("Ecco le aziende collegate a te");
  var title=t("");
  // var title=t("{x}_aziende",{"x":0});

  var html='';
  if (!s.farms) {
    s.farms=[];
  }


  if (s.farms.filter(function(f){return !f.toDelete}).length>0) {
    // html +="Da questa pagina è possibile da parte del tecnico scegliere un'azienda per poter accedere ai dati ed alle funzioni aziendali.";
    html=`
    <div class="card demo-card-header-pic" style="border-radius: 15px; margin-left:10px; margin-right:10px;">
      ${map.getHtml(false,false)}
    </div>

    <div class="data-table card">
      <table>
        <thead>
          <tr>
            <th>${t('farm')}</th>
            <th>${t('actions')}</th>
          </tr>
        </thead>
        <tbody id="farm_list"></tbody>
      </table>
    </div>
    `;
  }
  else {
    title=t(`Aggiungi una azienda`);
  }



  // TODO generalizzare quel 'advisor' perché adesso possono farlo anche le aziende
  var addFarm=`
    <button onclick="location.hash='create_farm_center/advisor'" class="button fs-button button-large button-fill">${t('Aggiungi azienda')}</button>
  `;

  var page={
    exclude_card:true,
    title: title,
    content: html
  };

  global.theme.render(page);

  jQuery('#footer').html(addFarm);


  if (s.farms.filter(function(f){return !f.toDelete}).length>0) {
    var opt={
      fullscreenControl: true
    };


    map.initMap([43,11],5, false, opt);




    var markers = L.featureGroup();

    var farm_list=``;

    jQuery.each(s.farms,function(k,v){
      if (!v.toDelete) {
        var url=`#farm/${v.id_farm_center}`;

        if(v.lat && v.lon){
          global.marker = L.marker([v.lat,v.lon]).bindPopup(`
            <table class="table">
              <tbody>
                <tr><th>${v.name}</th></tr>
                <tr><td><a class="link external" href="${url}">${t('Vai')}</a></td></tr>
              </tbody>
            </table>
          `).addTo(markers);
        }


        let play_button=`
          <button class="button detail_farm_${v.id_farm_center}" style="width:unset; margin-top:8px;">
            <i class="f7-icons">play_circle_fill</i>
          </button>
        `;

        let download_button=`
          <button class="button download_sync_${v.id_farm_center}" style="width:unset; margin-top:8px;">
            <i class="f7-icons">cloud</i>
          </button>
        `;

        farm_list=`
          <tr>
            <td>${v.name}</td>
            <td style="display:flex;">
              ${ (!s.filter_farms || s.filter_farms.indexOf(v.id_farm_center) >= 0) ? play_button : download_button }
            </td>
          </tr>
        `;
        // <button class="button delete_farm_${v.id_farm_center}" style="width:unset; margin-top:8px;"><i class="f7-icons">trash_circle_fill</i></button>
        jQuery('#farm_list').append(farm_list);

        jQuery('.detail_farm_'+v.id_farm_center).click(function(){
          location.hash=`farm/${v.id_farm_center}`;
        });

        jQuery('.download_sync_'+v.id_farm_center).click(function(){
          global.app.dialog.preloader('Scarico i dati di '+v.name);
          var s=storage.get();
          if (!s.filter_farms) {
            s.filter_farms=[];
          }
          s.filter_farms.push(v.id_farm_center);
          storage.save(s);

          setTimeout(function() {
            global.app.dialog.close();
            tools.synchronizes(
              'farm/'+v.id_farm_center
            );
          }, 200);
        });
      }
    });

    markers.addTo(global.map);

    let bounds=markers.getBounds();
    if(bounds.isValid()){
      global.map.fitBounds(markers.getBounds());
    }

  }

}


//Form for create or edit the farm_center
function farm_center_form(type,farm, tab){
  var farm_name=t('Azienda')+' ';
  var mail='';
  if (farm) {
    farm_name=farm.name;
    mail=farm.mail;
  }

  var map=new mapHelper();

  var html = `
  <div class="farm_center_form">

    <p class="segmented segmented-strong">
      <button id="button_base" class="button button-active">Dati aziendali</button>
      <button id="button_advanced" class="button">Avanzate</button>
      <button id="button_weather" class="button">Stazione meteo</button>
      <span class="segmented-highlight"></span>
    </p>

    <div class="tab_div" id="dati_aziendali">
      <div class="block-title">${t('Nome dell\'azienda')}</div>
      <div class="list inset">
        <ul>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input id="farm_name" type="text" placeholder="${t("Nome dell'azienda")}" value="${farm_name}"/>
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="block-title">${t('Posizione del centro aziendale')}</div>
      <div class="block">${t("Fai un doppio click sulla mappa per definire il centro aziendale. E' possibile digitare nel campo sottostante il nome del paese o l\'indirizzo per avicinarsi all'area di interesse.")}</div>
      <div class="list inset">
        <ul>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input id="farm_address" type="text" autocomplete="off" placeholder="${t("Cerca l'indirizzo dell'azienda")}"/>
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="card">
        <div class="card-content card-content-padding">
          ${map.getHtml(true, false)}
        </div>
      </div>
    </div>

    <div class="tab_div" id="campi_avanzati" style="display:none;">
      <div class="list inset" id="dbmng" ></div>
    </div>

    <div class="tab_div" id="stazione_meteo" style="display:none;">
      <div class="list inset" style="margin-top:15px;display:${
        type == 'insert' ? 'block' : 'block'
      };" style="margin-top:20px;">
      <div id="ws_message"></div>
    </div>
      <div class="list inset" >
        <ul>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">${t('Stazione meteo')}</div>
              <div class="item-input-wrap input-dropdown-wrap">
                <select id="weather_station"></select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
  `;

  var page={
    exclude_card:true,
    title: type=='insert'?t('Inserimento'):t('Modifica')+' '+t("azienda"),
    content: html
  };
  global.theme.render(page);

  jQuery('.segmented').on('click', 'button', function () {
    jQuery('.segmented button').removeClass('button-active');
    jQuery(this).addClass('button-active');

    jQuery('.tab_div').hide();

    if(jQuery(this).text()==t('Dati aziendali')){
      jQuery('#dati_aziendali').show();
    }
    else if(jQuery(this).text()==t('Avanzate')){
      jQuery('#campi_avanzati').show();
    }
    else if(jQuery(this).text()==t('Stazione meteo')){
      jQuery('#stazione_meteo').show();

      jQuery('#weather_station').html('<option>Caricamento in corso...</option>');
      searchWeatherStation(farm.lat, farm.lon, farm)
    }

  });


  if(tab){
    jQuery('#button_'+tab).click();
  }


  if(true){

    var aForm={
      "fields":{
        "cuaa": {"label": t("Codice Unico dell'Azienda Agricola (CUAA)")},
        "place_registered": {"label": t("Indirizzo Sede legale")},
        "zoo": {
          "label": t("L'azienda ha attività zootecnica?"), 
          "widget": "select_nm",
          "out_type": "checkbox",
          "voc_val": {
            "no": t("No"),
            "cattle": t("Bovini"),
            "pigs": t("Suini"),
            "sheep": t("Ovini"),
            "goat": t("Caprini"),
            "aviary": t("Avicoli"),
            "horse": t("Equini"),
            "rabbit": t("Cunicoli")
          }
        },
        "cod_zoo": {"label": t("Codice aziendale allevamento")},
        "practice": {
          "label": t("Quali sono le pratiche colturali"), 
          "widget":"select", 
            "voc_val": {
            "conventional": t("Convenzionali"),
            "transiction": t("Azienda in transizione al Bio"),
            "mixed": t("Azienda Bio certificata solo su alcuni settori"),
            "bio": t("Azienda Bio certificata")
          }
        },
        "centers": {
          "label": t("Quanti centri aziendali hai?"), 
          "widget":"numeric",
          "default": 1
        },
        "note": {
          "label": t("Note"), 
          "widget":"textarea"        
        }
      }
    };

    var selectNMWidgetF7=dbmng_ext.extendSelectNMWidgetF7();
    jQuery.each(aForm.fields,function(k,v){
      if (v.widget=='select_nm') {
        v.external_widget=selectNMWidgetF7;
      }
    });
  
    var theme_f7 = new Dbmng.Framework7Theme();

    // var template=null;
    // if (section.template) {
    //   template=section.template;
    // }

    global.sForm = new Dbmng.Form ({
      'aForm':aForm,
      "theme":theme_f7
    });
    jQuery('#dbmng').append(global.sForm.createForm(farm));
  }  



  jQuery('#footer').html(`
    <button id="salva_azienda" class="button fs-button button-large button-fill">${type=='insert'?t('Salva'):t('Aggiorna')}</button>
  `);

  // jQuery('#edit_meteo').click(function(){
  //   location.hash=`farm/${farm.id_farm_center}/edit/meteo`;
  //   console.log("Edit meteo");
  // });

  jQuery('#map_container').css('height','400px');



  var opt={
    fullscreenControl: true
  };

  map.initMap([43,11],5, false, opt);


  if (farm) {
    if(farm.address){
      jQuery('#farm_address').val(farm.address);
    }
    else if (farm.place) {
      jQuery('#farm_address').val(farm.place);
    }

    if(farm.id_station && farm.station_name){
      jQuery('#weather_station').html('<option value="'+farm.id_station+'">'+farm.station_name+'</option>');
    }
    else{
      if(farm.json && farm.json.id_station && farm.json.station_name){
        jQuery('#weather_station').html('<option value="'+farm.json.id_station+'">'+farm.json.station_name+'</option>');
      }
    }


    if(farm.lat && farm.lon){
      global.marker = L.marker([farm.lat,farm.lon]).addTo(global.map);
      showPointOnMap(farm.lat,farm.lon);
    }

    var farm_data=db.getFarmData(farm.id_farm_center);
    var skip_fit=true;
    drawPolygon(farm_data, null, skip_fit);
  }

  jQuery("#manual-Map-checkbox input").change(function() {
    jQuery('#manual-Map-checkbox').css('opacity','0.5');
    jQuery(this).attr("disabled", true);
    gps.manualMap(
      function(coord){
        jQuery('#survey_gps_latitude').val(coord.lat);
        jQuery('#survey_gps_longitude').val(coord.lon);
        jQuery('#survey_gps_provider').val("Manual");
      }
    );
  });

  // draw marker when click on map
  global.map.on('dblclick', function(e) {
    var coord=e.latlng;
    if(global.marker){
      global.map.removeLayer(global.marker);
    }
      
    global.marker = L.marker([coord.lat,coord.lng]).addTo(global.map);

    global.map.panTo([coord.lat,coord.lng],{animate:true});

    jQuery('#survey_gps_latitude').val(coord.lat);
    jQuery('#survey_gps_longitude').val(coord.lng);
  });
    
  // Per disattivare lo spostamento del marker col bottone
  // jQuery('#manual-Map').click();
  // jQuery('#manual-Map-checkbox').parent().hide();




  jQuery('#farm_address').change(function(){


    //remove selected profiles for google call
    if(jQuery.ajaxSettings.headers && jQuery.ajaxSettings.headers["selected_profile"]){
      global.selected_profile=jQuery.ajaxSettings.headers["selected_profile"];
      console.log("Gloabl::::",global.selected_profile);
      delete jQuery.ajaxSettings.headers["selected_profile"];
    }
    
    var location=jQuery(this).val();
    var urladdr_nom = `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyBQ6RsDJjIyfruNOIF6EUdMIhi_xwkN5DA`;
    jQuery.ajax({
      type: 'POST',
      url: urladdr_nom,      
      dataType: "json",
      success: function(point){
        var lat=point.results[0].geometry.location.lat;
        var lon=point.results[0].geometry.location.lng;

        //add again the selected profile
        if(global.selected_profile){
          jQuery.ajaxSettings.headers["selected_profile"]=global.selected_profile;
          console.log("Gloabl reimpostato::::",global.selected_profile);
        }

        showPointOnMap(lat,lon);
      }
    });
  });
}


function searchWeatherStation(lat, lon, farm){
  var s=storage.get();

  var urladdr = global.base_path+"aedita/getSW";
  var headers={};
  var request={
    access_token:s.user.access_token,
    lat:lat,
    lon:lon
  };
  if(global.plus){
    urladdr=global.base_call+"getSW";
    headers={
      'Authorization': 'Bearer '+s.user.access_token,
      "Content-Type": "application/json",
    }
    request=JSON.stringify(request);
  }

  jQuery.ajax({
    type: "POST",
    headers: headers,
    url: urladdr,
    data:request,
    dataType: "json",
    success: function(ws){

      if(ws.data.length==0){
        jQuery('#weather_station').html('<option value="0">Nessuna stazione meteo trovata</option>');
      }
      else{
        console.log(ws.data);
        console.log(farm);
        
        var id_station=null;
        if (farm && farm.id_station) {
          id_station=farm.id_station;
        }
        else {
          id_station=ws.data[0].id_station;
        }
        
        var station_name=null;
        if(farm && farm.station_name){
          station_name=farm.station_name;
        }
        else{
          station_name=ws.data[0].name;
        }

        var opt_ws='';
        jQuery.each(ws.data,function(k,v){
          if(v.id_station==id_station){
            station_name=v.name;
          }
          opt_ws+=`<option value="${v.id_station}" ${v.id_station==id_station?'selected':''} >${v.name} (${parseFloat(v.distance).toFixed(2)} km)</option>`;
        });
        jQuery('#weather_station').html(opt_ws);
      }
    },
    error: function(e){
      console.log(e);
      jQuery('#weather_station').html('<option value="0">Nessuna stazione meteo trovata</option>');
      var msg=`
        <span class="station_alert badge color-red" style="padding:3px; margin:10px; font-size:13px; display: inline-table; border-radius: 10px;">
          <a class="link external" style="color:white;">
            Controlla la connessione internet e riprova più tardi, se il problema dovesse persistere contatta l'assistenza.
          </a>
        </span>
      `;

      jQuery('#ws_message').html(msg);
    },
    timeout: 4000
  });
}


function showPointOnMap(lat,lon,zoom){
  var myZoom=18;
  if (zoom) {
    myZoom=zoom;
  }

  global.map.setZoom(myZoom);
  global.map.panTo([lat,lon],{animate:true});

  jQuery('#survey_gps_latitude').val(lat);
  jQuery('#survey_gps_longitude').val(lon);
}

// Funzione che crea una nuovo farm center
// TODO: capire se diversificare tra agronomi e aziende
export function createFarmCenter(who){
  farm_center_form('insert');

  gps.startGPS({
    getFasterPosition:true,
    save_place: true, //dentro la libreria chiama il reverse geocode e lo salva in global.coordinates
    buffer_max_size: 22222222222,
    position: function(pos){
      if (global.blocked_pos!==true) {
        jQuery('#localizationIcon').html('<i class="f7-icons">location_fill</i>');
          pos.timestamp=new Date().getTime();
          global.coordinates=pos;

          console.log(pos);
          jQuery('#survey_gps_latitude').val(pos.bufferedLatitude);
          jQuery('#survey_gps_longitude').val(pos.bufferedLongitude);
          jQuery('#survey_gps_provider').val(pos.provider);

          setTimeout(function(){
            if(global.marker){
              global.map.removeLayer(global.marker);
            }
            global.marker = L.marker([pos.bufferedLatitude,pos.bufferedLongitude]).addTo(global.map);
          },1000);
          console.log(pos);

          showPointOnMap(pos.bufferedLatitude,pos.bufferedLongitude);
      }
      else {
        console.log("posizione bloccata...");
      }

    }
  });

  jQuery('#salva_azienda').click(function(){

    var farm_name=jQuery('#farm_name').val();
    var lat=jQuery('#survey_gps_latitude').val();
    var lon=jQuery('#survey_gps_longitude').val();
    var provider=jQuery('#survey_gps_provider').val();
    var farm_role=who;
    var mail=jQuery('#mail').val();

    var new_id_farm_center=tools.getGuid();
    if (farm_name!='') {
      var farm={
        id_farm_center: new_id_farm_center, 
        name:farm_name,
        lat:lat,
        lon:lon,
        address:jQuery('#farm_address').val(),
        farm_role:farm_role,
        mail:mail,
        sync:false
      }

      db.insert_farm(farm);

      var s=storage.get();
      s.filter_farms.push(new_id_farm_center);
      storage.save(s);

    }
    else {
      alert(t("Inserisci il nome dell'azienda"));
    }

  });

}

//manage the farm's associated users
export function access_farm(id_farm_center){
  // var html='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
  // jQuery('.left').html(html);

  var farm=db.getFarm(id_farm_center);
    var farm_data=db.getFarmData(farm.id_farm_center);


  var roles=db.getRoles();





  var select_role=getSelectF7({
    "id": "role", "options": roles, "selected": "advisor", "label": t("Scegli il ruolo")
  });







  var html=`
    <div id="utenti_associati">
      <div class="block block-strong inset">
        <div id="access_list">
          <div class="data-table">
            <table>
              <thead>
                <tr><th>${t('Ruolo')}</th><th>${t('Utente')}</th><th>${t('Funzioni')}</th></tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div id="addNewUser">
        <div class="block-title">${t('Invita altre persone')}</div>

        <div class="list inset" >
          <ul style="border-bottom-right-radius: 0; border-bottom-left-radius: 0;">
            <li>
               ${select_role}
            </li>
          </ul>
          <p/>          
          <button id="send_invite" class="button fs-button fs-button-small button-fill" style="border-top-right-radius: 0; border-top-left-radius: 0;">${t('Manda un invito')}</button>
        </div>
      </div>



      <div id="send_message"></div>
      <div id="message_res"></div>
    </div>
  `;


  global.theme.render({
    "title": t("Utenti associati all'azienda")+" "+farm.name,
    "content": html,
    exclude_card:true
  });

  if(farm_data.access.filter(function(f){return !f.toDelete;}).length==0 && farm_data.team.filter(function(f){return !f.toDelete;}).length==0){
    jQuery("#access_list").html(t(`Non ci sono altri utenti collegati`));
  }
  else{

    jQuery.each(farm_data.team, function(kt, t){
      if (!t.toDelete) {
        addAccessTable(t, id_farm_center);
      }
    });

    var nessun_invito_valido=true;
    jQuery.each(farm_data.access, function(ka, a){
      if (!a.toDelete) {
        nessun_invito_valido=false;
        addAccessTable(a, id_farm_center);
      }
    });

  }


  jQuery("#send_invite").on("click", function(){

    var guid=tools.getGuid();

    var role_txt=jQuery("#role").val();
    var url=(global.redirect_uri?global.redirect_uri:global.base_link)+"#grant_access/"+guid

    var message=`
      ${t('Benvenuto su')} ${global.nomeApp}. ${('Se vuoi accedere all\'azienda')} ${farm.name} ${t('come')} ${role_txt} ${t('clicca al seguente indirizzo')}
    `;

    const shareData = {
      title: "Vuoi accedere",
      text: message,
      url: url
    }


    var new_access={
      "id_access": guid,
      "id_farm_center": id_farm_center,
      "role": jQuery("#role").val(),
      "uid": null,
      "username": null
    }
    db.insertAccess(new_access);

    if (jQuery("#access_list table").length==0) {
      var tab=`
      <div class="data-table">
        <table>
          <thead>
            <tr><th>${t('Ruolo')}</th><th>${t('Utente')}</th><th>${t('Funzioni')}</th></tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
      `;
      jQuery("#access_list").html(tab);
    }
    addAccessTable(new_access, id_farm_center);

    //TODO chiama db.UpdateWeb('access', function(){manda il messaggio})

    global.app.dialog.preloader("Sto creando l'invito");
    setTimeout(function() {
      db.lightSync(
      function(){
        var notShared=true;
        global.app.dialog.close();

        //Invia il messaggio
        if(navigator.share){
          try {
            notShared=false;
            //ho cmmentato await...
            navigator.share(shareData)
            jQuery("#message_res").html('Hai condiviso il messaggio')
          } catch(err) {
            // jQuery("#message_res").html("C'e stato un errore durante la condivisione");
          }
        }

        if (notShared) {
          var share=`
          <div id="share_div">
            <div class="card">
              <div class="card-header">${t('Invita col link')}</div>
              <div class="card-content card-content-padding">
                <div class="link">
                  <div id="share_link" class="pen-url">${t('Benvenuto su')} ${global.nomeApp}. ${t('Se vuoi accedere all\'azienda')}  ${farm.name} ${t('come')} ${role_txt} ${t('clicca al seguente indirizzo')} ${url}</div>
                  <button onclick="copyText()" class="copy-link">${t('Copia Link')}</button>
                </div>
              </div>
            </div>
          </div>
          `;
          jQuery("#addNewUser").html(share);

        }
      },

      function(err){
        global.app.dialog.close();

        alert(t(`Non è stato possibile creare l'invito. Accertati di avere una connessione internet attiva.`));
        db.deleteAccess(new_access.id_access);
        access_farm(id_farm_center);
      }

    );
    }, 200);

  });

}



export function copyText(){
  var copyText=jQuery('#share_link').text()
  navigator.clipboard.writeText(copyText);
}
window.copyText=copyText;

function addAccessTable(a, id_farm_center){
  var s=storage.get();

  var user=a.username;
  if(a.username==null){
    user="<i style='color:#aaa;'>"+t("in sospeso")+"</i>"; //<button>Manda l'invito</button>
  }


  var deleteButton=`<button class="button button_${a.id_access}"><i class="f7-icons">trash_circle_fill</i></button>`;

  var aRole=db.getRoles().filter(function(f){return f.id_role==a.role});
  var role=null;

  if (aRole.length>0) {
    role=aRole[0].role_name;
  }

  if (role==null || typeof role=='undefined') {
    role=t(a.role);
  }
  var tr=jQuery(`
    <tr>
      <td>${s.user.uid==a.uid?'<b>':''}${role}${s.user.uid==a.uid?'</b>':''}</td>
      <td>${s.user.uid==a.uid?'<b>':''}${user}${s.user.uid==a.uid?'</b>':''}</td>
      <td style="text-align:center;">${s.user.uid==a.uid?'<b>'+t('Tu')+'</b>':deleteButton}</td>
    </tr>
  `).appendTo("#access_list tbody");

  jQuery('.button_'+a.id_access).click(function(){
    var c = confirm(t("Vuoi davvero eliminare il collegamento?"));
    if (c) {
      if (a.uid) {
        db.deleteTeamMember(a.uid,id_farm_center);

      }
      else {
        db.deleteAccess(a.id_access);
      }
      //invece di reload ricarica access farm
      access_farm(id_farm_center);
    }
  });
}


//manage the menu for farm
function setupMenuFarm(farm){

  jQuery('.custom_farm_menu').show();
  jQuery("#custom_farm_name").html(farm.name);
  jQuery('.custom_link_access').parent().show();
  jQuery('.custom_link_chat').parent().show();
  jQuery('.custom_link_farm_edit').parent().show();
  jQuery('.custom_link_farm_bt').parent().show();
  jQuery('.custom_link_soil').parent().show();
  jQuery('.custom_link_warehouse').parent().show();
  jQuery('.custom_link_marketing').parent().show();
  jQuery('.custom_link_ws').parent().show();

  jQuery('.custom_link_access').attr("href",`#farm/${farm.id_farm_center}/access`)
  jQuery('.custom_link_farm_edit').attr("href",`#farm/${farm.id_farm_center}/edit/base`)
  jQuery('.custom_link_farm_bt').attr("href",`#farm/${farm.id_farm_center}/bluetooth_new`)
  jQuery('.custom_link_soil').attr("href",`#farm/${farm.id_farm_center}/soil`)
  jQuery('.custom_link_warehouse').attr("href",`#farm/${farm.id_farm_center}/warehouse`)
  jQuery('.custom_link_marketing').attr("href",`#farm/${farm.id_farm_center}/marketing`)
  jQuery('.custom_link_chat').attr("href",`#chat/${farm.id_farm_center}`)
  jQuery('.custom_link_ws').attr("href",`#farm/${farm.id_farm_center}/ws`)

  global.id_farm_center_bluetooth=farm.id_farm_center;
}

//Show the main farm page
export function show_farm(id_farm_center, id_season){
  var farm_data=db.getFarmDataSeason(id_farm_center,id_season);

  var farm=farm_data.farm_center[0];

  if(!farm){
      var page={
    exclude_card:false,
    title: "",
    content: `<div class="block-title">${t('Non hai i permessi per vedere l\'azienda o l\'azienda non esiste')}</div>
    <button class="button button-fill" onclick="location.hash='farms'">${t('Torna alla pagina iniziale')}</button>
     `
  }
     global.theme.render(page);
    //  debugger
     return
  }


  // debugger
  //Verifica i ruoli aziendali (nella sidebar)
  // if (farm.farm_role=='azienda') {
  var html='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
  jQuery('.left').html(html);
  // }

  setupMenuFarm(farm);

  //prende la season
  // var season=null
  var season=farm_data.actual_season;
  var seasons=farm_data.season;

  var zoo_button='';
  if(isZoo(farm)){
    zoo_button=`<button id="zoo" class="button">${t('Allevamento')}</button>`;
  }

  var page={
    exclude_card:true,
    title: "",
    content: `
      <div id="homepage">
        <div id="farm_season_selector"></div>

        <div id="farm_feedback"></div>

        <div class="inset" id="field_page">
          <p class="segmented segmented-strong">
            <button id="elenco" class="button button-active">${t('Campi')}</button>
            ${zoo_button}
            <button id="mappa" class="button">${t('Mappa')}</button>
            <button id="report" class="button">${t('Report')}</button>
            <!--<button id="models" class="button">${t('Dashboard')}</button>-->

            <span class="segmented-highlight"></span>
          </p>
        </div>

        <div id="plot_list">
        </div>

        <div id="plot_map" style="display:none;">
        </div>

        <div id="plot_report" style="display:none;">
        </div>

        <div id="plot_models" style="display:none;">
        </div>

        <div id="plot_zoo" style="display:none;">
        </div>

      </div>
    `
  };
  global.theme.render(page);

  var today=(new Date()).toISOString().substring(0,10);

  var viewedSeason=null;
  if(season && season.length){
    viewedSeason=season[0];
    if (id_season) {
      viewedSeason=season.filter(function(f){return f.id_season==id_season})[0];
    }
  }
  
  // console.log(season[0].date_to);
  // debugger
  if( viewedSeason && viewedSeason.date_to<today){
    var containActualSeason=false;
    jQuery.each(seasons, function(i, s){
      // debugger
      if (s.date_to>today) {
        containActualSeason=true;
      }
    });
    var button='';
    if (!containActualSeason) {
      button=`
      <div style="width: 70%;    margin: auto;    margin-top: 10px;">
        <button class="button button-fill" onclick="location.hash='/farm/${id_farm_center}/new_season';">${t('Aggiungi la stagione corrente')}</button>
      </div>
        `;
    }
    jQuery("#farm_feedback").append(`
      <span class="badge color-orange" style="padding:3px; font-size:13px; display: inline-table; border-radius: 10px;">${t('Attenzione! la stagione è finita, stai vedendo un dato storico')}</span>
      ${button}
    `).css('text-align','center');
  }

  //Riempie il selettore fi farm & Season
  getFarmSeasonSelects(farm_data,id_farm_center,id_season);

  jQuery('#footer').html(`
    <button onclick="location.hash='farm/${farm.id_farm_center}/add_field'" class="button fs-button button-large button-fill">
      ${t('Aggiungi un campo')}
    </button>
    <br/>    
  `);

  jQuery('#field_page button').click(function(){
    jQuery('#field_page button').removeClass('button-active');
    jQuery(this).addClass('button-active');
    var id=jQuery(this).attr('id');
    jQuery('#footer').html(``);
    // debugger
    if (id=='elenco') {
      jQuery('#plot_zoo').hide();
      jQuery('#plot_map').hide();
      jQuery('#plot_report').hide();
      jQuery('#plot_models').hide();
      jQuery('#plot_list').show();

      jQuery('#footer').html(`
        <button onclick="location.hash='farm/${farm.id_farm_center}/add_field'" class="button fs-button button-large button-fill">
          ${t('Aggiungi un campo')}
        </button>
        <br/>    
      `);
    }
    else if (id=='report') {
      jQuery('#plot_zoo').hide();
      jQuery('#plot_map').hide();
      jQuery('#plot_list').hide();
      jQuery('#plot_models').hide();
      jQuery('#plot_report').show();

      fairshare_report.homeReport(farm_data, id_season);
    }
    else if (id=='models') {
      jQuery('#plot_zoo').hide();
      jQuery('#map_container').css('height','500px');
      jQuery('#plot_map').hide();
      jQuery('#plot_report').hide();
      jQuery('#plot_models').show();
      jQuery('#plot_list').hide();

      fairshare_report.homeModels(farm_data,id_farm_center,id_season);

    }
    else if (id=='zoo') {

      jQuery('#map_container').css('height','500px');
      jQuery('#plot_map').hide();
      jQuery('#plot_report').hide();
      jQuery('#plot_models').hide();
      jQuery('#plot_list').hide();

      jQuery('#plot_zoo').show();

      fairshare_zoo.homeZoo(farm_data,id_farm_center,id_season);
    }
    else {
      jQuery('#map_container').css('height','500px');
      jQuery('#plot_zoo').hide();

      jQuery('#plot_map').show();
      jQuery('#plot_report').hide();
      jQuery('#plot_models').hide();
      jQuery('#plot_list').hide();

      global.map.invalidateSize();
      //fix errore quando mancano i campi
      try{
        global.map.fitBounds(global.fieldsGeoJson.getBounds());
      }
      catch(e){
        console.log(e);
      }
    }




  });


  if(isZoo(farm) && farm_data.plot.length==0){
    jQuery('#zoo').click();
  }

  

  // // todo klean togliere
  // jQuery('#models').click();

  


  jQuery('#farm').change(function(){
    if (jQuery(this).val()=='new_farm') {
      location.hash=`create_farm_center/${farm.farm_role}`;
    }
    else {
      location.hash=`farm/${jQuery(this).val()}`;
    }
  });

  jQuery('#season').change(function(){
    if (jQuery(this).val()=='new_season') {
      location.hash=`farm/${farm.id_farm_center}/new_season`;
    }
    else{
      location.hash=`farm/${farm.id_farm_center}/season/${jQuery(this).val()}`;
    }
  });


  if(farm_data['plot'].length==0 && !isZoo(farm)){
    jQuery('#plot_list').html(`
      <div class="block block-strong inset">
        <p>${t('Hai appena creato l\'azienda. Per continuare crea il primo campo o attiva l\'allevamento.')}</p>
      </div>

      <div class="block block-strong inset">
      <button onclick="location.hash='farm/${farm.id_farm_center}/add_field'" class="button fs-button button-large button-fill">
      ${t('Aggiungi un campo')}
      </button>
      <button onclick="location.hash='farm/${farm.id_farm_center}/edit/advanced'" class="button fs-button button-large button-fill">
      ${t('Attiva allevamento')}
      </button>
      </div>
    `);

    jQuery("#footer").html("");
    jQuery('#field_page').hide();
  }
  else{
    jQuery("#footer").show();
    var campo='';
    jQuery.each(farm_data.plot, function(kp,p){

      // seleziona per la season e per il plot
      // var ff=p.field;
      // var ff=farm_data.field.filter(function(f){return f.id_plot==p.id_plot && f.id_season==season.id_season});
      // console.log(ff, p.id_plot, season.id_season);
      var crop="<span class='badge bg-color-orange'>"+t("non coltivato")+"</span>";
      var link="";
      if(p.field){
        // var field=ff[0];
        crop=p.field.crop_name;
        link=`#farm/${farm.id_farm_center}/field/${p.field.id_field}`;
      }
      else{
        link=`#farm/${farm.id_farm_center}/plot/${p.id_plot}`;
      }

      campo+=`
      <li>
        <a href="${link}" class="item-link link external item-content">
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title">${p.name}</div>
            </div>
            <div class="item-subtitle">${t(crop)}</div>
          </div>
        </a>
      </li>
        `;
    });

    var campi=`
    <div class="block-title block-title-medium">${t('Elenco dei campi')}</div>
    <div class="list media-list inset">
      <ul>
        ${campo}
      </ul>
    </div>
    `;
    jQuery('#plot_list').html(campi);


    var map=new mapHelper();

    var campi=`
    <div class="block-title block-title-medium">${t('Mappa dei campi')}</div>
    <div class="card demo-card-header-pic" style="border-radius: 15px; margin-left:15px; margin-right:15px;">
      ${map.getHtml(false,false)}
    </div>
    `;

    jQuery('#plot_map').html(campi);


    var opt={
      fullscreenControl: true
    };
    map.initMap([farm.lat,farm.lon],7, false, opt);

    global.markerClusterLayer = L.markerClusterGroup({
      disableClusteringAtZoom: 13,
      zoomToBoundsOnClick: true,
      spiderfyOnMaxZoom: false
    }).addTo(global.map);

    showPointOnMap(farm.lat,farm.lon,14);

    drawPolygon(farm_data);


  }


  // jQuery('#footer').html(`
  //       <button onclick="location.hash='farm/${farm.id_farm_center}/add_field'" class="button fs-button button-large button-fill">
  //         ${t('Aggiungi un campo')}
  //       </button>
  //       <br/>    
  //     `);
}

//Check if the farm has zootecnic activity
function isZoo(farm){
  return (farm.zoo && farm.zoo.length>0 && farm.zoo[0]!='no')
}

export function edit_field(id_farm_center,id_field){
  // upsert_field(id_farm_center,id_field);

  var fd=db.getFieldData(id_field);

  //get field data
  var field=db.getFieldData(id_field);

  global.theme.render({
    exclude_card:true,
    title: `${field.plot.name} - ${field.season.season_name} - ${t(field.crop.name)}`,
    content: `
      <div class="block">
        ${t('Stai modificando il campo')} <b>${field.plot.name}</b>
        ${t('coltivato nella stagione')} <b>${field.season.season_name}</b> ${t('a')} <b>${field.crop.name}</b>.
      </div>

      <div class="block block-strong inset">


        <table class="data-table">
          <tbody>
            ${fairshare_soil.showSoilShort(id_farm_center, fd.soil, true, 'table')}
            <tr><th>${t('Campo')}:</th><td>${t('Superficie')}: ${parseSup(fd.plot.area)} <br> ${t('Geometria')}: ${(fd.plot.polygon_coordinates||fd.plot.geojson)?t("presente"):t("assente")} </td><td><a onclick="location.hash='#farm/${id_farm_center}/plot/${fd.plot.id_plot}/edit'" ><i class="f7-icons size-22">pencil</i></a></tr>
          </tbody>
        </table>
      </div>

      <div class="list inset" id="form_container">
      </div>
      <div class="block" id="delete_use">
      </div>
      `
  });





  //Xform to manage field data
  var sForm = new Dbmng.Form ({
    'aForm':{
      "fields":{
        "cultivar": {"label": t("Varietà")},
        "yield_ha": {"label": t("Resa attesa")+" (q/ha)", "widget":"numeric", "placeholder": "Default: 50 (q/ha)"},
        "irrigation": {"label": t("Irrigazione"), "widget":"select",
          "voc_val":[
            {"no": t("Assente")},
            {"emergency": t("Manuale/di soccorso")},
            {"drip": t("Irrigazione a goccia")},
            {"sprinkler": t("Impianto Sprinkler")},
            {"subirrigation": t("SubIrrigazione")},
            {"surface": t("Irrigazione di superficie")},
            {"altro": t("Altre tipologie di Irrigazione")}
          ]},
          "irrigation_flow": {"label": t("Irrigazione - Portata in l/s"), "widget":"numeric"}
        }
    }
  });

  // if (fd.field.id_crop==2) {
  // }
  if (fd.field.id_crop==2 || fd.field.id_crop==1) {
    sForm.aForm.fields.vigour={"label": t("Classe di Vigoria"), "widget":"select", "voc_val":[
      {"low": t("Bassa")},          {"medium": t("Media")}, {"high": t("Alta")}
    ]};
    sForm.aForm.fields.planting_year={"label": t("Anno di impianto"), "type": "number","widget": "numeric"}
    sForm.aForm.fields.inerbimento_naturale={"label": t("Inerbimento naturale"), "widget":"select", "voc_val":[
      {"si": t("Si")},          
      {"no": t("No")}
    ]};
//     distanza tra le file (m)
// distanza sulle file (m)
// densità ad ettaro (10000/(dist_x*dist_y)
    sForm.aForm.fields.dist_x={"label": t("Distanza tra le file (m)"), "type": "number","widget": "numeric"};
    sForm.aForm.fields.dist_y={"label": t("Distanza sulle file (m)"), "type": "number","widget": "numeric"};
    sForm.aForm.fields.density={"label": t("Densità ad ettaro (piante/ha)"), "type": "number","widget": "numeric"};
  }

  jQuery('#form_container').append(sForm.createForm(field.field));

  // debugger
  // onchange of dist_x and dist_y calculate density
  jQuery('.dbmng_form_field_dist_x .real_widget, .dbmng_form_field_dist_y .real_widget').on('change', function(){
    var dist_x=jQuery('.dbmng_form_field_dist_x .real_widget').val();
    var dist_y=jQuery('.dbmng_form_field_dist_y .real_widget').val();
    
    // debugger
    var density=(10000/(dist_x*dist_y)).toFixed(2);
    jQuery('.dbmng_form_field_density .real_widget').val(density);
  });
    

  if ((!field.operation && !field.observation) || (field.observation.length==0 && field.operation.length==0)) {
    jQuery('#delete_use').html(`
      <button class="button button-large"><i class="f7-icons">trash</i>${t('Cancella l\'uso')}</button>
    `);
    jQuery('#delete_use button').click(function(){
      global.app.dialog.confirm(t("Vuoi davvero eliminare l'uso del campo?"), t('Attenzione!'), function(){
        db.deleteField(id_field)
        location.hash='farm/'+id_farm_center;
      });
    });
  }
  else{
    jQuery('#delete_use').html(t(`Non è possibile cancellare il campo, ci sono dei dati associati.`));
  }

  jQuery('#footer').html(`
    <button id="save_field" class="button fs-button button-large button-fill">
      ${t('Salva i dati')}
    </button>
  `);


  jQuery("#save_field").on("click", function(){

    //prende i dati del form
    var new_value=sForm.getValue();

    //prende il campo field
    var ff=field.field;

    //Modifica i campi modificati in field
    var new_field=jQuery.extend({}, ff, new_value);

    db.updateField(new_field, id_field, function(){
      location.hash='#farm/'+id_farm_center+"/field/"+id_field;
    });
  });

}



export function edit_plot(id_farm_center,id_plot){
  // upsert_field(id_farm_center,id_field);
  global.polygon_coordinates=null;

  //get plot data (farm data is neede to draw the map)
  var plot=db.getPlotData(id_plot);
  var farm_data=db.getFarmData(id_farm_center);

  var map=new mapHelper();

  global.theme.render({
    exclude_card:true,
    title: `${plot.plot.name}`,
    content: `
      <div id="form_container" class="block"></div>

      <button id="draw_polygon" class="button fs-c-b fs-button button-large button-fill">${t('Disegna il poligono')}</button>
      <div  class="card" style="border-radius: 15px; margin-left:15px; margin-right:15px;">
          ${map.getHtml(false,false,300)}
      </div>
      `
  });



  map.initMap([plot.farm.lat,plot.farm.lon],7, false, {
    fullscreenControl: true
  });


  showPointOnMap(plot.farm.lat,plot.farm.lon);
  global.map.setZoom(17);
  drawPolygon(farm_data, id_plot);

  jQuery("#draw_polygon").on("click", function(){

    jQuery("#save_plot").hide();
    activateMobileEditing(function(){


      var seeArea = L.GeometryUtil.geodesicArea(global.drawPoints);
      jQuery(".dbmng_form_field_area input").val(parseInt(seeArea));
      hideMap();
      jQuery("#save_plot").show();
    });
  });


  //Xform to manage field data
  var sForm = new Dbmng.Form ({
    'aForm':{
      "fields":{
        "name": {"label": t("Nome")},
        "area": {"label": t("Superficie in")+" m<sup>2</sup>", "type": "float", "widget": "numeric"}
      }
    }
  });
  jQuery('#form_container').append(sForm.createForm(plot.plot));

  jQuery('#footer').html(`
    <button id="save_plot" class="button fs-button button-large button-fill">
      ${t('Salva i dati')}
    </button>
  `);

  map.fixMapHeight();


  jQuery("#save_plot").on("click", function(){

    //prende i dati del form
    var new_value=sForm.getValue();

    if (global.polygon_coordinates) {
      var coo=[];
      jQuery.each(global.polygon_coordinates, function (kc,c){
        coo.push([c.lat,c.lng])
      });
      new_value.polygon_coordinates=JSON.stringify(coo);
    }

    //prende il campo field
    var pp=plot.plot;

    //Modifica i campi modificati in field
    var new_field=jQuery.extend({}, pp, new_value);

    db.updatePlot(new_value, id_plot, function(){
      history.back();
    });



  });

}

//Edit the farm
export function edit_farm(id_farm_center, tab){
  var farm=db.getFarm(id_farm_center);

  farm_center_form('update',farm, tab);

  jQuery('#salva_azienda').click(function(){

    var dbmng_values=global.sForm.getValue();

    console.log(dbmng_values);

    var farm_name=jQuery('#farm_name').val();
    var lat=jQuery('#survey_gps_latitude').val();
    var lon=jQuery('#survey_gps_longitude').val();
    var mail=jQuery('#mail').val();
    var id_station=jQuery('#weather_station').val();
    var station_name=jQuery('#weather_station option:selected').text();


    var new_farm={
      name:farm_name,
      lat:lat,
      lon:lon,
      mail:mail,
      place:jQuery('#farm_address').val(),
      farm_role:farm.farm_role,
      id_farm_center: farm.id_farm_center,
      sync:false,
      id_station:id_station,
      station_name: station_name
    }

    //merge dbmng_values with new_farm (new farm wins)
    new_farm=jQuery.extend({}, dbmng_values, new_farm);

    db.updateFarm(id_farm_center,new_farm);
  });

}


//Funzione di Aggiunta di un nuovo campo
export function createPlot(id_farm_center){

  var field=null;
  var farm_data=db.getFarmData(id_farm_center);
  var farm=farm_data.farm_center[0];
  //annulla le coordinate del campo
  global.polygon_coordinates=null;
  var label=t("Salva");
  var html="";
  var default_field_name=field?field.plot.name:t("Campo")+" "+(farm_data.plot.length+1);
  var map=new mapHelper();

  // <div>Il campo è un poligono in cui si susseguono durante le varie stagioni vari usi (colture) Tutti gli usi sono collegati ai dati del campo (nome, superficie, suolo).</div>

  html+=`
  <div class="block-title">${t('Nome del campo')}</div>
   <div class="list inset">
     <ul>
       <li class="item-content item-input">
         <div class="item-inner">
           <div class="item-input-wrap">
             <input type="text" id="plot_name" autocomplete="off" placeholder="${t('Nome del campo')}" value="${default_field_name}"/>
             <span class="input-clear-button"></span>
           </div>
         </div>
       </li>
     </ul>
   </div>

      <div id="map_polygon">
        <div  class="block-title">${t('Disegna il campo')}</div>
        <div  class="block">${t('Spostati dove vuoi disegnare il primo punto e clicca su "Disegna". Se non vuoi disegnare il poligono clicca su "Avanti".')}</div>
        <div  class="card" style="border-radius: 15px; margin-left:15px; margin-right:15px;">
          ${map.getHtml(false,false,500)}
        </div>
      </div>
      <div style="display:none" class="block" id="map_polygon_skipped">${t('Poligono non disegnato')}</div>

      <div class="list inset" style="margin-top:0;">
        <ul>
          <li class="item-content item-input">
            <div class="item-media">
              <i class="icon demo-list-icon"></i>
            </div>
            <div class="item-inner">
              <div class="item-title item-label">${t('Superficie in')} m<sup>2</sup></div>
              <div class="item-input-wrap">
                <input type="number" id="superficie" autocomplete="off" placeholder="${t('Superficie in metri quadri')}" value="${field && field.plot.area?field.plot.area:''}" />
              </div>
            </div>
          </li>
        </ul>
      </div>
  `;

  html+=`<div class="block-title">${t('Uso del campo')}</div>`;
  var id_crop=null;
  // if (field) {
  //   id_crop=field.field.id_crop;
  // }
  html+=`
  <div class="list inset">
    <ul>
      <li>
        ${seasonSelect(farm_data.season)}
      </li>
      <li>
        ${cropSelect(farm_data.crop, "id_crop", id_crop)}
      </li>
    </ul>
  </div>
  `;

  global.theme.render({
    exclude_card:true,
    content: html
  });



  map.fixMapHeight();

  var opt={
      fullscreenControl: true
    };
  map.initMap([farm.lat,farm.lon],7, false, opt);
  showPointOnMap(farm.lat,farm.lon);
  global.map.setZoom(17);
  drawPolygon(farm_data);


  jQuery('#footer').html(`
    <button style="display:none" id="add_field" class="button fs-button button-large button-fill">
      ${label}
    </button>    
  `);

  activateMobileEditing();



  jQuery("#add_field").on("click",function(){
    if (jQuery('#superficie').val()!='') {
      jQuery("#add_field").prop("disabled", true);

      //prepare the farm object to be insert in the db
      var new_plot={
        "id_farm_center": id_farm_center,
        "id_plot": tools.getGuid(),
        "name": jQuery("#plot_name").val(),
        "area":jQuery('#superficie').val()
      }

      var new_field={
        "id_farm_center": id_farm_center,
        "id_field": tools.getGuid(),
        "id_plot": new_plot.id_plot,
        "id_crop": jQuery("#id_crop").val()
      }
      //id there is the season select use id_select
      if(jQuery("#id_season").val()){
        new_field.id_season=jQuery("#id_season").val();
      }
      else{
        //otherwise use the season label and create later the season
        new_field.season={"year": jQuery("#season").val()};
      }

      if (global.polygon_coordinates) {
        var coo=[];
        jQuery.each(global.polygon_coordinates, function (kc,c){
          coo.push([c.lat,c.lng])
        });
        new_plot.polygon_coordinates=JSON.stringify(coo);
      }
      db.insertPlotAndField(new_plot,new_field,function(){
        location.hash=`farm/${new_plot.id_farm_center}/field/${new_field.id_field}`;
      });
    }
    else {
      alert(t('Disegna il campo o inserisci la superficie'));
    }
  });
}



function activateMobileEditing(success){

  global.drawPoints=[];

  //Aggiunge i controlli
  jQuery('#footer #geom_button').remove();

  jQuery('#footer').append(`
    <p id="geom_button" style="width:100%" class="segmented segmented-raised">
        <button class="button" id="start_polygon">${t('Disegna')}</button>
        <button class="button" style="display:none"  id="undo_polygon">${t('Undo')}</button>
        <button class="button" style="display:none"  id="end_polygon">${t('Finisci')}</button>
        <button class="button" id="skip_polygon">${t('Avanti')}</button>
    </p>
  `);

  jQuery("#start_polygon").on("click", function(){
    global.drawPoints.push(global.map.getCenter());
    jQuery("#end_polygon").show();
    jQuery("#undo_polygon").show();
    jQuery("#skip_polygon").hide();
    drawPoliline(global.drawPoints);

    jQuery("#start_polygon").html(t("Aggiungi"));
  })

  //undo the last point
  jQuery("#undo_polygon").on("click", function(){
    global.drawPoints.pop();
    drawPoliline(global.drawPoints);
  })

  jQuery("#skip_polygon").on("click", function(){
    hideMap();
  });

  global.map.on("move", function(s){
    var points=[];
    points=JSON.parse(JSON.stringify(global.drawPoints));
    points.push(global.map.getCenter());
    drawPoliline(points);
    // var seeArea = L.GeometryUtil.geodesicArea(global.drawPoints);
    // console.log(">>>>>>>>>>>>>>");
    // console.log("SUPERFICIE: "+seeArea);

    // jQuery('#superficie').val(seeArea.toFixed(0));
  });

  jQuery("#end_polygon").on("click", function(){

    if(global.drawPoints.length>=3){
      global.drawPoints.push(global.map.getCenter());

      global.drawPoints.push(global.drawPoints[0]);
      drawPoliline(global.drawPoints, true)
      global.map.off("move");
      global.polygon_coordinates=global.drawPoints;

      let seeArea = L.GeometryUtil.geodesicArea(global.drawPoints);
      console.log("SUPERFICIE: "+seeArea);
      jQuery('#superficie').val(seeArea.toFixed(0));

      if(success){
        success();
      }
      else{
        jQuery("#map_polygon_skipped").html(t("Poligono disegnato correttamente"));
        hideMap();
      }
    }
    else{
      alert(t("Servono almeno tre punti"));
    }
  });
}

//L'utente ha saltato il poligono
function hideMap(){
  jQuery("#map_polygon").hide();
  jQuery("#map_polygon_skipped").show();
  jQuery("#geom_button").hide();

  jQuery("#add_field").show();
  jQuery("#save_plot").show();
}

//Disegna la polilinea mentre vado avanti
function drawPoliline(points, drawPolygon){

  if(global.firstpolyline){
    global.map.removeLayer(global.firstpolyline);
  }

  global.firstpolyline = new L.Polygon(points, {
    color: 'red',
    weight: 3,
    opacity:1,
    smoothFactor: 1
  }).addTo(global.map);


  if(global.geojsonLayer){
    global.map.removeLayer(global.geojsonLayer);
  }

  var geojson={
    "type": "MultiPoint",
    "coordinates": [
    ]
  }
  jQuery.each(points, function(kp,p){
    geojson.coordinates.push([p.lng, p.lat]);
  });
  var myLayerStyle = {
    color: '#500',
    fillColor: '#FFF',
    "fillOpacity": .90,
    radius: 10
  };

  global.geojsonLayer = L.geoJson(geojson,{
    pointToLayer: function createCircles (feature, latlng) {
      return L.circleMarker(latlng, myLayerStyle)
  },
  }).addTo(global.map);
}


//Funzione di Aggiunta di un nuovo campo
//vecchia funzione commentata
/*
export function upsert_field(id_farm_center,id_field){

  var label="Aggiungi Campo";

  if (id_field) {
    var field=db.getFieldData(id_field);
    label="Modifica il campo";
    console.log(field);
  }

  var html="";
  var farm_data=db.getFarmData(id_farm_center);
  var crop=db.getCrop();
  var polygon_coordinates=null;
  var default_field_name=field?field.plot.name:"Campo "+(farm_data.plot.length+1);
  var map=new mapHelper();

  html+=`
  <div class="block-title">Nome del campo</div>
   <div class="list inset">
     <ul>
       <li class="item-content item-input">
         <div class="item-inner">
           <div class="item-input-wrap">
             <input type="text" id="plot_name" autocomplete="off" placeholder="Nome del campo" value="${default_field_name}"/>
             <span class="input-clear-button"></span>
           </div>
         </div>
       </li>
     </ul>
   </div>

   <div class="block-title">Disegna il campo</div>
    <div class="card demo-card-header-pic" style="border-radius: 15px; margin-left:15px; margin-right:15px;">
      ${map.getHtml(false,false)}
    </div>

    <div class="list inset" style="margin-top:0;">
      <ul>
        <li class="item-content item-input">
          <div class="item-media">
            <i class="icon demo-list-icon"></i>
          </div>
          <div class="item-inner">
            <div class="item-title item-label">Superficie in m<sup>2</sup></div>
            <div class="item-input-wrap">
              <input type="text" id="superficie" autocomplete="off" placeholder="Superficie in metri quadri" value="${field && field.plot.superficie?field.plot.superficie:''}" />
            </div>
          </div>
        </li>
      </ul>
    </div>

  `;

  html+=`<div class="block-title">Uso del campo</div>`;
  var id_crop=null;
  if (field) {
    id_crop=field.field.id_crop;
  }
  html+=`
  <div class="list inset">
    <ul>
      <li>
        ${seasonSelect(farm_data.season)}
      </li>
      <li>
        ${cropSelect(crop, "id_crop", id_crop)}
      </li>
    </ul>
  </div>
  `;

  global.theme.render({
    exclude_card:true,
    title: label,
    content: html
  });

  var farm=farm_data.farm_center[0];
  if (farm) {
    var opt={
      fullscreenControl: true
    };


    map.initMap([farm.lat,farm.lon],7, false, opt);
    showPointOnMap(farm.lat,farm.lon);
    global.map.setZoom(17);
  }

  var drawnItems = new L.FeatureGroup();
  global.map.addLayer(drawnItems);

  var drawControl = new L.Control.Draw({
    draw: {
      polygon: {
        allowIntersection: false, // Restricts shapes to simple polygons
        drawError: {
          color: '#e1e100', // Color the shape will turn when intersects
          message: '<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)' // Message that will show when intersect
        },
        shapeOptions: {
          color: '#bada55'
        }
      },
      marker: false,
      circle: false,
      polyline:false,
      rectangle:false,
      circlemarker:false,
      edit: {
        featureGroup: drawnItems, //REQUIRED!!
      }
    }
  });
  global.map.addControl(drawControl);

  var layer;
  global.map.on('draw:created', function (e) {

   if (typeof layer !== 'undefined') {
     layer.removeFrom(global.map);
   }
   var type = e.layerType;
   layer = e.layer;

   global.map.addLayer(layer);

   var seeArea = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
   jQuery('#superficie').val(seeArea.toFixed(0));


   var geom=layer.toGeoJSON().geometry;
   var coord=[];
   jQuery.each(geom.coordinates[0],function(k,v){
     coord.push([v[1],v[0]]);
   });
   polygon_coordinates=JSON.stringify(coord);
  });

  if (field && field.plot.id_plot) {
    drawPolygon(farm_data,field.plot.id_plot);
  }
  else {
    drawPolygon(farm_data);
  }

  jQuery('#footer').html(`
    <button id="add_field" class="button fs-button button-large button-fill">
      ${label}
    </button>
  `);


  jQuery("#add_field").on("click",function(){
    if (jQuery('#superficie').val()!='') {
      jQuery("#add_field").prop("disabled", true);

      //prepare the farm object to be insert in the db
      var new_field={
        "id_field": field?field.field.id_field:tools.getGuid(),
        "id_farm_center": id_farm_center,
        "id_crop": jQuery("#id_crop").val(),
        "name": jQuery("#plot_name").val(),
        "area":jQuery('#superficie').val()
      };
      if (field) {
        new_field.id_plot=field.field.id_plot;
      }

      if (polygon_coordinates) {
        new_field.polygon_coordinates=polygon_coordinates;
      }
      else if (field && field.plot.polygon_coordinates) {
        new_field.polygon_coordinates=field.plot.polygon_coordinates;
      }

      //id there is the season select use id_select
      if(jQuery("#id_season").val()){
        new_field.id_season=jQuery("#id_season").val();
      }
      else{
        //otherwise use the season label and create later the season
        new_field.season={"year": jQuery("#season").val()};
      }

      new_field.plot={"name": jQuery("#plot_name").val()};

      if (field) {
        db.updateFieldAndPlot(new_field, function(){
          location.hash=`farm/${new_field.id_farm_center}/field/${new_field.id_field}`;
        });
      }
      else {
        db.insertField(new_field, function(){
          location.hash=`farm/${new_field.id_farm_center}/field/${new_field.id_field}`;
        });
      }
    }
    else {
      alert('Disegna il campo o inserisci la superficie');
    }
  });
}
*/

//Draw the poligons
export function drawPolygon(farm_data, id_plot, opt){


  let skip_fit=false;
  let skip_tooltip=false;

  if(opt){
    if(opt.skip_fit){
      skip_fit=true;
    }
    if(opt.skip_tooltip){
      skip_tooltip=true;
    }
  }

  var mapData = L.markerClusterGroup();
  console.log("FARM DATA:",farm_data.plot);



  var theLayer=null;
  var legend = {};

  jQuery.each(farm_data.plot,function(k,v){



    if (v.polygon_coordinates || v.geojson) { //
      var LeafIcon = L.Icon.extend({
        options: {
          iconSize:     [38, 95],
          shadowSize:   [50, 64],
          iconAnchor:   [22, 94],
          shadowAnchor: [4, 62],
          popupAnchor:  [-3, -76]
        }
      });
      var greenIcon = new LeafIcon({
        iconUrl: 'icons/invisible.png',
      });


      let geometry=null;


      if(v.polygon_coordinates!==null){
        let polygon_coordinates=null;
        if (typeof v.polygon_coordinates == 'string') {
          polygon_coordinates=JSON.parse(v.polygon_coordinates);
        }
        else {
          polygon_coordinates=v.polygon_coordinates;
        }
        let coords=[];
        jQuery.each(polygon_coordinates,function(k,v){
          coords.push([v[1],v[0]]);
        });
        geometry={
          type: 'Polygon',
          coordinates: [coords],
        }
      }
      else{
        geometry=JSON.parse(v.geojson);
      }


      // mapData.addLayer(L.marker(polygon_coordinates[0],{icon: greenIcon}));

      var plotColor = '#64b5f6';
      if (v.field && v.field.id_crop && db.getCrop(v.field.id_crop) && db.getCrop(v.field.id_crop).color) {
				plotColor = db.getCrop(v.field.id_crop).color;
			}



      let feature_collection = {
				type: 'FeatureCollection',
				crs: {
					type: 'name',
					properties: {
						name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
					},
				},
				features: [
					{
						type: 'Feature',
						properties: {
							id_plot: v.id_plot,
							id_farm_center: v.id_farm_center,
							name: v.name,
							field: v.field,
							color: plotColor
						},
						geometry: geometry,
					},
				],
			};
      
      

      let geojsonLayer = L.geoJson(feature_collection, {
        onEachFeature: function (feature, layer) {
          var crop_name='---';
          var id_field=null;

          if (feature.properties.field) {
            crop_name=feature.properties.field.crop_name;
            id_field=feature.properties.field.id_field;
          }


          var url=`#farm/${feature.properties.id_farm_center}/plot/${feature.properties.id_plot}`;
          if (feature.properties.field) {
            url=`#farm/${feature.properties.id_farm_center}/field/${id_field}`;
          }

          if(!skip_tooltip){

            layer.bindPopup(`
              <table class="table">
                <tbody>
                  <tr><td>${t('Nome')}</td><th>${feature.properties.name}</th></tr>
                  <tr><td>${t('Coltura')}</td><th>${t(crop_name)}</th></tr>
                  <tr><td><a class="link external" href="${url}">${t('Vai')}</a></td></tr>
                </tbody>
              </table>
            `);
          }

          if (id_plot==feature.properties.id_plot) {
            layer.setStyle({
              'color': "red"
            });
            theLayer=layer;
          }
          else {
            // Colorazione del poligono in base alla coltura
            if(feature.properties.field && feature.properties.field.id_crop){
              legend[feature.properties.color] = db.getCrop(feature.properties.field.id_crop).name;
            }
            else{
              if(feature.properties.color=='#64b5f6'){
                legend[feature.properties.color] = t('Non assegnato');
              }
            }

            // filter the color of the field by crop  
            if (feature.properties.color) {
              layer.setStyle({
                'color': feature.properties.color
              });
						}
          }
        }
      }).addTo(mapData);
      console.log(geojsonLayer);
    }
  });
  // debugger
  // add the legend in left bottom corner of the map
  if (Object.keys(legend).length > 0) {
    var legendControl = L.control({position: 'bottomleft'});
    legendControl.onAdd = function (map) {
      var div = L.DomUtil.create('div', 'info map_legend');
      // var labels = [];
      // for (var key in legend) {
      //   labels.push('<li><svg style="margin-right: 10px;" height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" fill="'+key+'"/></svg>'+legend[key]+'</li>');
      // }
      // div.innerHTML = labels.join('<br>');
			// loop through our density intervals and generate a label with a colored square for each interval
      var li='';
			for (var key in legend) {
        li +=
        '<li><svg style="margin-right: 10px;" height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" fill="' +
        key +
        '"/></svg>' +
        legend[key] +
        '</li>';
			}
			// add closing ul tag
      div.innerHTML = '<ul>' + li + '</ul>';
      return div;
    };
    legendControl.addTo(global.map);
  }


  
  mapData.addTo(global.map);
  global.fieldsGeoJson=mapData;
  
  if(!skip_fit){
    if (theLayer) {
      setTimeout(function() {
        try{
          global.map.fitBounds(theLayer.getBounds());
        }
        catch(e){
          console.log(e);
        }
      }, 1000);
    }
    else {
      setTimeout(function() {
        try{
          console.log(mapData.getBounds());
          global.map.fitBounds(mapData.getBounds());
          if (global.map.getZoom()<10) {
            global.map.setZoom(15);
          }
        }
        catch(e){
          console.log(e);
        }
      }, 1000);
    }
  }
}

//The function create a select to choose the season
function seasonSelect(season ,selected){
  var html="";
  var default_opt='';

  //Insert the season
  if(season.length==0){
    //if not exists
    var current_year=tools.getCurrentYear();
    var id='season';
    var os='';
    var anno_corrente=new Date().getFullYear();
    default_opt=anno_corrente;
    for (var i = anno_corrente; i > (anno_corrente-5); i--) {
      os+=`<option value=${i}>${i}</option>`;
    }
    // Anno di raccolta: <input id="season" type="numeric" value="${current_year}"></input><br/>
  }
  else{
    var os="";
    jQuery.each(season, function(ks,s){
      var sel="";
      if(s.id_season==selected){
        sel="selected";
        default_opt=s.season_name;
      }

      if (default_opt=='' && ks==0) {
        default_opt=s.season_name;
      }
      os+=`<option ${sel} value=${s.id_season}>${s.season_name}</option>`;
    });

    var id='id_season';
  }

  html=`
    <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
      <select id="${id}">${os}</select>
      <div class="item-content">
        <div class="item-inner">
          <div class="item-title">${t('Anno di raccolta')}</div>
          <div class="item-after">${default_opt}</div>
        </div>
      </div>
    </a>
  `;
  return html;
}


//select di scelta della coltura
function cropSelect(crop, div, selected, no_label){
  var html="";
  var default_opt='';
  var oc="";
  jQuery.each(crop, function(kc,c){
    var sel="";
    if(c.id_crop==selected){
      sel="selected";
      default_opt=c.name;
    }
    else if (!selected && c.default) {
      sel="selected";
      default_opt=c.name;
    }

    if (default_opt=='' && kc==0) {
      default_opt=c.name;
    }
    oc+=`<option ${sel} value=${c.id_crop}>${t(c.name)}</option>`;
  });
  oc+=`<option values="0">${t('Nessuna coltura')}</option>`;

  // html+=`
  //   <select id="${div}">${oc}</select>
  // `;

  var style='';
  var select_label=t('Coltura');
  var style_inner='';
  var style_content='';

  //TODO: Diego perche c'era questo comando?
  if (no_label) {
    select_label='';
    style_inner='width: fit-content;';
    style="font-weight:bold;color:black;max-width: unset;margin-left:0;"
    style_content='justify-content: center;';
  }

  html=`
    <a class="item-link smart-select smart-select-init" data-open-in="popup"  data-close-on-select="true" data-searchbar="true">
      <select id="${div}">${oc}</select>
      <div class="item-content" style="${style_content}">
        <div class="item-inner" style="${style_inner}">
          <div class="item-title">${select_label}</div>
          <div class="item-after" style="${style}">${t(default_opt)}</div>
        </div>
      </div>
    </a>
  `;
  // html=`
  // <select id="${div}">${oc}</select>
  // `

  return html;
}



//Funzione di creazione di una nuova stagione e cloning dei campi della vecchia
export function new_season(id_farm_center){

  var farm=db.getFarm(id_farm_center);
  var farm_data=db.getFarmData(id_farm_center);
  var crops=db.getCrop();

  //TODO occorre aggiungere dei controlli
    //non aggiungere una stagione per un anno che già esiste
    //non andare troppo nel futuro (max 1 anno)
    //E' meglio fare una select...
  var new_year=tools.getCurrentYear();

  if(farm_data.season.length>0){
    try{
      new_year=parseInt(farm_data.season[0].date_from.substring(0,4));
      new_year++;
    }
    catch(e){;}
  }

  //TODO aggiungere controllo; 0 è giusto?
  var clone_season=farm_data.season[0];


  var html=`
  <div class="block-title">${t('Nuovo anno di raccolta')}</div>
   <div class="list inset">
     <ul>
       <li class="item-content item-input">
         <div class="item-inner">
           <div class="item-input-wrap">
             <input type="text" id="season" placeholder="${t('Nuovo anno di raccolta')}" value="${new_year}"/>
             <span class="input-clear-button"></span>
           </div>
         </div>
       </li>
     </ul>
   </div>

    <div>
      <div class="block-title">${t('Copia gli usi colturali dall\'anno')} ${clone_season.season_name}</div>

      <div id="plot_list" class="data-table card">
      <table class="table">
        <thead>
          <tr>
            <th>${t('Nome del campo')}</th><th>${clone_season.season_name}</th><th>${t('Coltura nuova stagione')}</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
      </div>
    </div>

  `;


  global.theme.render({
    exclude_card:true,
    title: t("Crea una nuova stagione"),
    content: html
  });
  jQuery('#footer').html(`
    <button id="create_new_season" class="button fs-button button-large button-fill">${t('Crea la nuova stagione')}</button>
  `);
  console.log(farm_data['plot']) ;
  jQuery.each(farm_data['plot'], function(kp,p){

    //seleziona per la season e per il plot
    var ff=farm_data.field.filter(function(f){return f.id_plot==p.id_plot && f.id_season==clone_season.id_season});
    var crop="non coltivato";
    var id_crop=null;
    if(ff.length>0){
      var field=ff[0];
      crop=db.getCropName(field.id_crop)
      id_crop=field.id_crop;
    }

    //crea una select con la coltura del nuovo anno (di default mette la vecchia)
    var btn=`
    <div class="list">
      <ul>
        <li style='justify-content: center;'>
          ${cropSelect(crops, "id_crop_"+p.id_plot, id_crop, true)}
        </li>
      </ul>
    </div>
    `;

    var row=`
      <tr><th>${p.name}</th><td>${crop}</td><td data-plot_name="${p.name}"  data-id_plot="${p.id_plot}" class="choose_crop">${btn}</td></tr>
    `;

    jQuery(row).appendTo("#plot_list tbody");
  });


  jQuery("#create_new_season").on("click", function(){
    jQuery("#create_new_season").prop("disabled", true);

    var new_fields=[];
    //scansiona tutte le righe
    jQuery("#plot_list .choose_crop").each(function(knf, el){

      //prende la crop dalla select iesima
      var id_crop=jQuery(el).find("select").val();
      var id_plot=jQuery(el).attr("data-id_plot");
      var plot_name=jQuery(el).attr("data-plot_name");

      //Attenzione nel new_field non metto la season perche non è ancora stata creata
      var new_field={
        "id_field": tools.getGuid(),
        "id_farm_center": id_farm_center,
        "id_crop":  id_crop,
        "id_plot": id_plot,
        "name": plot_name
      };

      new_fields.push(new_field);
    });


    var year=jQuery("#season").val();
    var new_season={"year": year};
    var existing_name=farm_data.season.filter(f=> f.season_name==year);

    if(existing_name.length>0){
      alert(t("Attenzione! l'anno selezionato esiste già"))
      jQuery("#create_new_season").prop("disabled", false);
    }
    else{
      db.cloneSeason(id_farm_center, new_season, new_fields, function(sss){
        location.hash=`farm/${id_farm_center}/season/${sss.id_season}`;
      });
    }



  });

}


//Main page of plot (field containers over season)
export function show_plot(id_farm_center, id_plot ){

  var fd=db.getPlotData(id_plot);

  var html='';
    if(jQuery.isEmptyObject(fd.plot)){
    html=`<div class="block-title">${t('Non hai i permessi per vedere il campo o il campo non esiste')}</div>
    <button class="button button-fill" onclick="location.hash='farm/${id_farm_center}'">${t('Torna alla pagina iniziale')}</button></div>
    `;
    global.theme.render({
      exclude_card:false,
      title: ``,
      content: html
    });
    return;
  }

  var farm_data=db.getFarmData(id_farm_center);
   
  html=`
  <div id="field_season_selects"></div>

  <div class="block-title block-title-medium">${t('Suolo')}</div>
  <div class="block" id="soil_list">${fairshare_soil.showSoilShort(id_farm_center, fd.soil)}</div>


    <div class="block-title block-title-medium">${t('Usi del campo')}</div>
    <div class="list media-list inset">
      <ul  id="field_list">
      </ul>
    </div>

    <div></div>

    <div id="add_use"></div>

  `;


  global.theme.render({
    exclude_card:true,
    title: ``,
    content: html
  });

  get_Field_Season_selects(farm_data,fd);

  var season_available=[];



    var default_crop=null;


    //iter per le stagioni (ordinate)
    jQuery.each(fd.season, function(ks,s){
      //prende i campi per quelle stazioni

      //calcola se la stazione è occupata
      var used=false;

      jQuery.each(fd.field, function(kf,f){

        if(f.id_season==s.id_season){


          used=true; //la stagione ha almeno 1 campo

          var crop=db.getCropName(f.id_crop);

          if(default_crop==null){
            default_crop=f.id_crop;
          }

          var link=`#farm/${id_farm_center}/field/${f.id_field}`;

          var ht=jQuery(`
            <li>
              <a href="${link}" class="item-link link external item-content">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title">${crop}</div>
                  </div>
                  <div class="item-subtitle">${s.season_name}</div>
                </div>
              </a>
            </li>
          `).appendTo("#field_list");
        }

      });

      //Aggiunge la stagione tra quelle disponibili
      if(!used){
        season_available.push(s);
      }

    });


    //Segnala che non c'è nessun campo
    if(fd.field.length==0){
      jQuery("#field_list").append(`
      Il campo non ha nessun uso associato
        <button class="button button-large" id="delete_plot"><i class="f7-icons">trash</i> Cancella il campo</button>
      `);


      jQuery("#delete_plot").on("click", function(){
        db.deletePlot(id_plot, function(){
          location.hash="#/farm/"+id_farm_center;
        });
      });
    }





  //se non ci sono stagioni disponibili (ogni stagione ha un uno) blocco l'inserimento
  //teoricamente è possibile avere due field per season ma per adesso è meglio bloccarlo
  //non lo gestiamo bene in uX
  if(season_available.length>0){
    jQuery("#add_use").html(`
    <div class="block-title block-title-medium">${t('Aggiungi un uso')}</div>
      <div class="list inset">
        <ul>
          <li>
            ${seasonSelect(season_available)}
          </li>
          <li>
            ${cropSelect(fd.crop, "id_crop", default_crop)}
          </li>
        </ul>
      </div>
      `);
      jQuery('#footer').html(`
        <button id="add_field" class="button fs-button button-large button-fill">
        ${t('Aggiungi l\'uso')}
        </button>
      `);
  }


    jQuery("#add_field").on("click",function(){

      jQuery("#add_field").prop("disabled", true);
    //prepare the farm object to be insert in the db
    var field={
      "id_field": tools.getGuid(),
      "id_farm_center": id_farm_center,
      "id_crop": jQuery("#id_crop").val(),
      "name": fd.plot.name,
    };

    field.id_season=jQuery("#id_season").val();

    field.id_plot=fd.plot.id_plot;

    db.insertField(field, function(){
      location.hash=`farm/${field.id_farm_center}/field/${field.id_field}`;
    });
  });

}


//Home page of the field
export function show_field(id_farm_center, id_field, section){

  var fd=db.getFieldData(id_field);


  //TODO servono tutti questi i mport?
  var farm_data=db.getFarmData(id_farm_center);
  var farms=db.getAllFarms();

  // debugger
  var opt_f='';
  var default_opt='';

  jQuery.each(farms.filter(function(f){return !f.toDelete;}),function(k,v){
    var selected='';
    if (id_farm_center==v.id_farm_center) {
      selected='selected';
      default_opt=v.name;
    }
    opt_f+=`<option value="${v.id_farm_center}" ${selected}>${v.name}</option>`;
  });

  jQuery('.navbar-inner>.title').html(`
    <div class="list inset" style="margin:0;">
      <ul>
        <li>
          <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">
            <select id="farm">
              ${opt_f}
            </select>

            <div class="item-content">
              <div class="item-inner" style="width: fit-content;">
                <div class="item-title"></div>
                <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${default_opt}</div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  `);

  jQuery('#farm').change(function(){
    location.hash='farm/'+jQuery(this).val();
  });



  //Cosa mostrare nella pagine
  //Coltura
  //Superficie (se c''è la geometria)
  //Varietà?
  //Sintesi del suolo

  // <li class="accordion-item"><a class="item-content item-link" href="#">
  // <div class="item-inner">
  // <div class="item-title">${t('Dati del campo')}</div>
  // </div>
  // </a>
  // <div class="accordion-item-content">
  //
  //
  // </div>
  // </li>

  // debugger

  var html='';
  if(jQuery.isEmptyObject(fd.field)){
    html=`<div class="block-title">${t('Non hai i permessi per vedere il campo o il campo non esiste')}</div>
    <button class="button button-fill" onclick="location.hash='farm/${id_farm_center}'">${t('Torna alla pagina iniziale')}</button></div>
    `;
    global.theme.render({
      exclude_card:false,
      title: ``,
      content: html
    });
    return;
  }


   html=`

    <div id="field_alert"></div>

    <div id="model_result_home"></div>







    <div class="list media-list inset">
      <ul>
        <li>
          <div class="row">
            <a style="width:85%;" href="#farm/${id_farm_center}/field/${id_field}/observation" class="item-link link external item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">${t('Monitoraggio')} <span>(${fd.observation.length})</span></div>
                </div>
              </div>
            </a>
            <a style="width:15%;" href="#farm/${id_farm_center}/field/${id_field}/new_observation" class="item-link link external item-content">
              <i class="f7-icons">plus_square_fill</i>
            </a>
          </div>
        </li>
        <li>
          <div class="row">
            <a style="width:85%;" href="#farm/${id_farm_center}/field/${id_field}/operation" class="item-link link external item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">${t('Operazioni colturali')} <span>(${fd.operation.length})</div>
                </div>
              </div>
            </a>
            <a style="width:15%;" href="#farm/${id_farm_center}/field/${id_field}/new_operation" class="item-link link external item-content">
              <i class="f7-icons">plus_square_fill</i>
            </a>
          </div>
        </li>
        <!--
          <li>
            <a href="#farm/${id_farm_center}/plot/${fd.plot.id_plot}" class="item-link link external item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">${t('Ordinamento Colturale')}</div>
                </div>
              </div>
            </a>
          </li>
        -->
      </ul>
    </div>
  `;



  global.theme.render({
    exclude_card:true,
    title: `
      <div style="text-align:center;">
        <div id="field_season_selects"></div>
      </div>`,
    content: html
  });

  get_Field_Season_selects(farm_data,fd);




  // <span style="font-size: 18px;">${fd.plot.name} - ${t('stagione')} ${fd.season.season_name}</span>

    //TODO stile!
    var today=(new Date()).toISOString().substring(0,10);
  //se il modello è di una stagione già terminata il modello finisce con la stagzione precedente
  if(fd.season.date_to<today){
    jQuery("#field_alert").append(`
      <span class="badge color-orange" style="padding:3px; font-size:13px; display: inline-table; border-radius: 10px;">${t('Attenzione! la stagione è finita, stai vedendo un dato storico')}</span>
    `).css('text-align','center');
  }


  if(fd.plot.polygon_coordinates==null){
    jQuery("#field_alert").append(`
      <span class="badge color-orange" style="padding:3px; font-size:13px; display: inline-table; border-radius: 10px;">
        Il campo non ha il poligono. <a class="link external" style="color:white;" href="#farm/${id_farm_center}/plot/${fd.plot.id_plot}/edit">
          Disegna il poligono
        </a>
      </span>
    `).css('text-align','center');
  }

  if(fd.soil==null){
    jQuery("#field_alert").append(`
      <span class="badge color-yellow" style="padding:3px; font-size:13px; display: inline-table; border-radius: 10px;">
        <a class="link external" style="color:white;" href="#farm/${id_farm_center}/new_soil">
          ${t('Attenzione! Il campo non è associato a nessun suolo.')}
          <br>
          ${t('Aggiungi un suolo')}
        </a>
      </span>
    `).css('text-align','center');
  }


  // //You can delete the fields only if empty
  // if(fd.observation.length==0 && fd.operation.length==0){
  //   // var del_btn=`<button class="button color-red" id="deleteField">${t('Elimina il campo')}</button>`;
  //   // jQuery('#footer').html(del_btn);
  //   jQuery('#deleteField').click(function(){
  //     var c = confirm("Vuoi davvero eliminare l'uso del campo?");
  //     if (c) {
  //       db.deleteField(id_field)
  //       location.hash='farm/'+id_farm_center;
  //     }
  //   });
  // }
  // else {
    jQuery('#footer').html('');
  // }

  model.prepareSections(id_field, fd, section);
}


//produce la select con farm & season
export function getFarmSeasonSelects(farm_data, id_farm_center, id_season){

  var farms=db.getAllFarms();

  var opt_f='';
  var default_opt='';

  jQuery.each(farms.filter(function(f){return !f.toDelete;}),function(k,v){
    var selected='';
    if (id_farm_center==v.id_farm_center) {
      selected='selected';
      default_opt=v.name;
    }
    opt_f+=`<option value="${v.id_farm_center}" ${selected}>${v.name}</option>`;

    if (k==0 && v.farm_role=='farm_role') {
      opt_f+=`<option value="new_farm" data-option-icon="f7:plus_circle">${t('Nuova azienda')}</option>`;
    }
  });

  var opt_s='';
  var default_opts='';
  jQuery.each(farm_data.season, function(ks,s){
    var selected_s='';
    if(ks==0){
      default_opts=s.season_name;
    }
    if (id_season==s.id_season) {
      selected_s='selected';
      default_opts=s.season_name;
    }
    opt_s+=`<option ${selected_s} value="${s.id_season}" >${s.season_name}</option>`;
  });
  if(farm_data.season.length>0){
    opt_s+=`<option value="new_season" data-option-icon="f7:plus_circle">${t('Nuova stagione')}</option>`;
  }



  var select_stagione='';
  if (opt_s!='') {
    select_stagione=`
    <div class="list inset">
      <ul>
        <li>
          <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">

            <select id="season">
              ${opt_s}
            </select>

            <div class="item-content">
              <div class="item-inner" style="width: fit-content;">
                <div class="item-title"></div>
                <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0">${default_opts}</div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    `;
  }
  jQuery("#farm_season_selector").html(
    `
    <div class="row" style="justify-content: center; margin-bottom:20px;">
            <div>
              <div class="list inset">
                <ul>
                  <li>
                    <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">
                      <select id="farm">
                        ${opt_f}
                      </select>

                      <div class="item-content">
                        <div class="item-inner" style="width: fit-content;">
                          <div class="item-title"></div>
                          <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${default_opt}</div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              ${select_stagione}
            </div>
          </div>
    `
  );



}

//elenco dei campi e delle stagioni per un field
function get_Field_Season_selects(farm_data,fd){
  console.log("KKKKK",farm_data);
  var id_farm_center=farm_data.farm_center[0].id_farm_center;
  var opt_f='';
  var default_opt='';
  jQuery.each(farm_data.plot.filter(function(f){return !f.toDelete;}),function(k,v){
    var selected='';
    if (fd.plot.id_plot==v.id_plot) {
      selected='selected';
      default_opt=v.name;
    }
    opt_f+=`<option value="${v.id_plot}" ${selected}>${v.name}</option>`;
  });


  var opt_s='';
  var default_opts='';
  jQuery.each(farm_data.season, function(ks,s){

    var selected_s='';

    // //trova, se c'è, il field di quella stagione
    var fff=farm_data.field.filter(function(f){
        return f.id_plot==fd.plot.id_plot && f.id_season==s.id_season;
      // return 0;
    });

    var season_name=s.season_name;
    if(fff.length>0){
      season_name+=" - "+db.getCropName(fff[0].id_crop);
    }


    if(ks==0){
      default_opts=season_name;
    }
    // console.log("actual season",fd.season.id_season);
    // console.log("ks season",s.id_season);
    if (fd.season.id_season==s.id_season) {
      selected_s='selected';
      default_opts=season_name;
    }
    opt_s+=`<option ${selected_s} value="${s.id_season}" >${season_name}</option>`;
  });


  jQuery('#field_season_selects').html(`

    <div class="row" style="justify-content: center; margin-bottom:20px;">
      <div>
        <div class="list inset" style="margin-bottom:0;margin-top:0;">
          <ul>
            <li>
              <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">
                <select id="plot">
                  ${opt_f}
                </select>

                <div class="item-content">
                  <div class="item-inner" style="width: fit-content;">
                    <div class="item-title"></div>
                    <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${default_opt}</div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="list inset" style="margin-bottom:0;margin-top:0;">
          <ul>
            <li>
              <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">

                <select id="season">
                  ${opt_s}
                </select>

                <div class="item-content">
                  <div class="item-inner" style="width: fit-content;">
                    <div class="item-title"></div>
                    <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0">${default_opts}</div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  `);

  jQuery('#plot,#season').change(function(){
    var id_season=jQuery('#season').val();
    var id_plot=jQuery('#plot').val();

    var s=storage.get();
    var next_field=s.field.filter(function(f){
      return f.id_plot==id_plot && f.id_season==id_season;
    })[0];

    if (next_field && next_field.id_field) {
      location.hash=`farm/${id_farm_center}/field/${next_field.id_field}/`;
    }
    else {
      location.hash=`farm/${id_farm_center}/plot/${id_plot}/`;
    }

    setTimeout(function() {
      jQuery('#season').val(id_season);
    }, 500);

  });

}

export function  getSelectF7(opt ){

  var selected_label="";
  var optr="";
  if(opt.options.length>0){
    //la prima è la chiave e la seconda è la label
    var keys=Object.keys(opt.options[0]);
    jQuery.each(opt.options, function(kr,o){
      var key=o[keys[0]];
      var value=o[keys[1]];
      //Calcola il valore di default
      var sel="";
      if( (opt.selected && opt.selected==key) ||(!opt.selected && kr==0)){
        selected_label=value;
        sel="selected";
      }
      optr+=`<option ${sel} value="${key}">${value}</option>`;
    });
  }


  var s=`
      <a class="item-link smart-select smart-select-init" data-open-in="popover"  data-close-on-select="true">
        <select id="${opt.id}">
          ${optr}
        </select>
        <div class="item-content">
          <div class="item-inner">
            <div class="item-title">${opt.label}</div>
            <div class="item-after">${selected_label}</div>
          </div>
        </div>
      </a>
    `;

    return s;
}


function rejectAccess(){
  var s=storage.get();
  delete s.id_access_to_grant;
  storage.save(s);

  location.hash='';
}
window.rejectAccess=rejectAccess;

export function grantAccess(id_access){
  console.log("ID_ACCESS:"+id_access);
  var s=storage.get();
  if (s.user ) {

    var urladdr = global.base_path+"fairshare/check_invitation";
    var headers = {};
    var request={
      access_token:s.user.access_token,
      id_access:id_access
    };
    if(global.plus){
      urladdr = global.base_call+"check_invitation";
      headers = {
        'Authorization': 'Bearer '+s.user.access_token,
        'Content-Type': 'application/json'
      }
      request=JSON.stringify(request) ;
    }

    jQuery.ajax({
      type: "POST",
      url: urladdr,
      headers:headers,
      data:request,
      dataType: "json",
      success: function(invitation){
        console.log(invitation);
        if (invitation.ok) {
          var invito=invitation.data[0];
          console.log(invito);
          var map=new mapHelper();

          var html=`
          <div class="card demo-card-header-pic" style="border-radius: 15px">
            ${map.getHtml(false,false)}


            <div class="card-content card-content-padding">
              <p class="date">Invito in sospeso</p>
              <p>Hai ricevuto una richiesta per entrar a far parte dell'azienda <b>${invito.farm_name}</b> da parte di <b>${invito.username}</b></p>
            </div>
            <div class="card-footer"><a id="accetta_invito" class="button button-fill">Accetta</a><a id="rifiuta_invito" class="button">Rifiuta</a></div>
          </div>
          `;

          global.theme.render({
            exclude_card:true,
            title: "Hai ricevuto un invito",
            content: html
          });

          var opt={
            fullscreenControl: true
          };

          map.initMap([invito.lat,invito.lon],8, false, opt);
          global.marker = L.marker([invito.lat,invito.lon]).addTo(global.map);
          showPointOnMap(invito.lat,invito.lon);

          jQuery('#accetta_invito').click(function(){
            var urladdr = global.base_path+"fairshare/link_team";
            var headers = {};
            var request={
              access_token:s.user.access_token,
              id_access:id_access
            };
            if(global.plus){
              urladdr = global.base_call+"link_team";
              headers = {
                'Authorization': 'Bearer '+s.user.access_token,
                'Content-Type': 'application/json'
              }
              request=JSON.stringify(request) ;
            }
            jQuery.ajax({
              type: "POST",
              headers:headers,
              url: urladdr,
              data:request,
              dataType: "json",
              success: function(link){
                if (link.ok) {
                  delete s.id_access_to_grant;
                  storage.save(s);
                  location.hash='tools/sync';
                }
                else {
                  alert(link.message);
                }
              }
            });
          });

          jQuery('#rifiuta_invito').click(function(){
            rejectAccess();
          });

        }
        else {
          global.theme.render({
            title: "OPS 😩",
            content: invitation.message+`<br><br><a onclick="rejectAccess()" class="button button-fill">Torna alla home page</a>`
          });
        }

      }
    });


  }
  else {
    s.id_access_to_grant=id_access;
    storage.save(s);

    location.hash='login';
  }
}

export function credits(){
  global.theme.render({
    title: "Credits",
    content: `
    <div class="card demo-card-header-pic" style="border-radius: 15px">
      <div class="card-header align-items-flex-end">
        <img src="icons/logo-psr.png" alt="" style="height:100%;">
      </div>
      <div class="card-content card-content-padding">
        <p class="date">Credits PSR Regione Toscana</p>
        <p>${t('La web-app Poderi è stata sviluppata da')} <a class="link external" href="http://www.aedit.it/" target="_blank">AEDIT srl</a>, ${t('grazie anche al finanziamento del PSR 2014-2020 della Regione Toscana, Sottomisura 16.2 - Annualità 2022, progetto')} <a class="link external" href="https://progetto.poderi.app/" target="_blank">Poderi</a></p>
      </div>
    </div>

    <div class="card demo-card-header-pic" style="border-radius: 15px">
      <div class="card-header align-items-flex-end">
        <img src="icons/credits_fairshare.png" alt="" style="height:100%;">
      </div>
      <div class="card-content card-content-padding">
        <p class="date">Credits Fairshare</p>
        <p>${t('La web-app Poderi è stata sviluppata da')} <a class="link external" href="http://www.aedit.it/" target="_blank">AEDIT srl</a>, ${t('grazie anche ai finanziamenti del progetto')} <a class="link external" href="https://www.h2020fairshare.eu/" target="_blank">H2020 FAIRshare</a></p>
      </div>
    </div>


    `
  });
}


function parseSup(mm){
  return (parseFloat(mm)/10000).toFixed(2)+"ha"
}
