import * as db from './data.js';
import global from '../../global.js';
import * as tools from '../../tools.js';
import * as dbmng_ext from '../../core/dbmng_ext.js';
import photo from '../../core/photo.js';
import t from '../../translate.js';
import Mosca from './Mosca.js';
import notification from '../../core/notification.js';





//Main page of crop operation
export function show_operation(id_farm_center, id_field){
  jQuery('#footer').html('');

  var fd=db.getFieldData(id_field);
  var html=`
    ...
  `;

  global.theme.render({
    exclude_card:true,
    title: `${fd.plot.name} - ${t('stagione')} ${fd.season.season_name} - ${t('Operazioni Colturali')}`,
    content: `

      <div class="block-title">${t('Elenco delle operazioni')}</div>
      <div style="margin:10px;">
        <button onclick="location.hash='farm/${id_farm_center}/field/${id_field}/new_operation'" class="button fs-button button-large button-fill">${t('Aggiungi una nuova operazione')}</button>
      </div>

      <div id="operation_list"></div>
    `
  });


  table_oo("#operation_list", id_farm_center, fd.operation, {"id_field": id_field});

}

//La funzione produce una tabella con 1 riga per ogni operazione
export function table_oo(div, id_farm_center, values, opt){

  if(!opt){
    opt={};
  }
  var filter;
  if(opt.filter){
    filter=opt.filter;
  }
  var fields;
  if(opt.fields){
    fields=opt.fields;
  }


  if(values.length>0){


    //cerca di capire il type
    var type="";
    var filter_field="";
    var id_field=values[0].id_field;
    if(values[0]["id_observation"]){
      type="observation";
      filter_field="id_survey_schema";
    }
    else if (values[0]["id_crop_operation"]) {
      type="operation";
      filter_field="id_crop_operation";
    }

    //se c'è il filtro, filtra la tipologia
    if(filter){
      values=values.filter(v=>v[filter_field]==filter);
    }
    values=values.filter(v=>v.toDelete!=true);


    //ordina i record per il valore di date
    values.sort(function(a,b){


      let order=1;
      try{
        order=new Date(b.date) - new Date(a.date);
      }
      catch(e){
        if(a.date>b.date){
          order=-1;
        }
        else if(a.date<b.date){
          order=1;
        }
        else{
          order=0;
        }
      }
      return order;
    });

    var a_plus='';
    if (filter) {
      a_plus=`
      <a class="link external" href="#farm/${id_farm_center}/field/${id_field}/new_${type}/${filter}">
        <i style="font-size:2.5em" class="icon f7-icons">plus_app_fill</i>
      </a>
      `;

    }

    jQuery(div).html(`
    <div class="card data-table">


      <table >
        <thead>
          <tr>
            <th>${a_plus}</th>
            ${!fields?``:`<th>${t('Campi')}</th>`}
            <th>Data</th>
            ${filter?'':'<th>Tipo</th><th>Valore</th>'}
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  `);


    jQuery.each(values, function(ko,o){
      var o2=jQuery.extend( {}, o );

      //Cancella i campi che non vanno in visualizzazione
      delete o2.id_field;
      delete o2.id_fields;
      delete o2.date;
      delete o2.sync;
      delete o2.toDelete;
      delete o2.json;
      delete o2.val;
      delete o2.uid;
      delete o2.version;
      delete o2.update_timestamp;
      if(type=='operation'){
        delete o2.id_crop_operation;
        delete o2.id_operation;
        delete o2.id_fields;
      }
      else if(type=='observation'){
        delete o2.id_survey_schema;
        delete o2.id_observation;
      }

      if(filter && ko==0){
        jQuery.each(o2, function(k,v){
          jQuery(div+' thead tr').append(`<th>${global.capitalizeFirstLetter(k)}</th>`);
        });
      }




      var tds='';
      //inserire qui le formattazioni custom
      if(type=='operation' && o.id_crop_operation==3){

        var n1=o.no3?parseFloat(o.no3):0;
        var n2=o.nh4?parseFloat(o.nh4):0;
        var n3=o.urea?parseFloat(o.urea):0;
        var p=o.p2o5?parseFloat(o.p2o5):0;
        var k=o.k2o?parseFloat(o.k2o):0;

        let prd="";
        if(o.products){
          jQuery.each(o.products,function(k,v){
            prd+=`${v.product_name} - ${v.amount_tot} ${v.uom?v.uom:""}<br/>`;
          });
        }

        tds=`<td>${prd}<br/>NPK: ${(n1+n2+n3).toFixed(1)} - ${p.toFixed(1)} - ${k.toFixed(1)}</td>`;

      }
      else if(type=='operation' && o.id_crop_operation==10){

        // var n1=o.no3?parseFloat(o.no3):0;
        // var n2=o.nh4?parseFloat(o.nh4):0;
        // var n3=o.urea?parseFloat(o.urea):0;
        // var p=o.p2o5?parseFloat(o.p2o5):0;
        // var k=o.k2o?parseFloat(o.k2o):0;

        let prd="";
        if(o.products){
          jQuery.each(o.products,function(k,v){
            prd+=`${v.product_name} - ${v.amount_tot}${v.uom?v.uom:""} (${v.reason})<br/>`;
          });
        }

        tds=`<td>${prd}</td>`;



      }
      else if(type=='observation' && o.id_survey_schema==13){
        console.log(o);
        var percentuale=((o.num_si/o.num_no)*100).toFixed(0);
        tds=`<td>${t("Infestazione")}: ${percentuale}%</td>`;

      }
      else if(type=='observation' && o.id_survey_schema==10){
        var attivaVals=['u','l1v','l2v'];
        var attiva=0;

        jQuery.each(attivaVals,function(kv,p){
          if (o[p]) {
            attiva+=o[p];
            console.log(attiva);
          }
        });

        attiva=((attiva/o.tot)*100).toFixed(0);

        var totaleVals=['u','fu'];
        var totale=0;

        jQuery.each(totaleVals,function(kv,p){
          if (o[p]) {
            totale+=o[p];
            console.log(totale);
          }
        });
        totale=((totale/o.tot)*100).toFixed(0);

        tds=`<td>${t("Attiva")}: ${attiva}% ${t("Totale")}: ${totale}%</td>`;

      }
      else if(type=='observation' && o.id_survey_schema==1){
        var trap_name=db.decodeTrap(o.id_trap);
        tds=`<td>${t("Trappola")}: ${trap_name} ${t("Adulti catturati")}: ${o.trap}</td>`;
      }
      else if(type=='observation' && (o.id_survey_schema==11 || o.id_survey_schema==19) ){
        var id_survey_schema=o.id_survey_schema;
        
        var fasemedia_name=db.decodeFasemedia(id_survey_schema, o.fasemedia);
        console.log(fasemedia_name,"KLEN");
        tds=`<td>${t("Fase media")}: ${fasemedia_name}</td>`;
      }
      else{
        if(filter){
          jQuery.each(o2,function(k,v){
            tds+=`<td>${t(v)}</td>`;
          });
        }
        else{
          tds="<td>";
          var total_num=null;
          var traps_num=0;
          var avg=null;

          jQuery.each(o2,function(k,v){
            if(k!='images'){
              var main_tab='{}';
                if (type=='observation') {
                  main_tab=db.getSurveySchema(o.id_survey_schema)
                }
                else if (type=='operation') {
                  main_tab=db.getCropOperation(o.id_crop_operation)
                }

                var schema;
                if(typeof main_tab.schema=='string'){
                  schema=JSON.parse(main_tab.schema);
                }
                else{
                  schema=main_tab;
                }
                var value=v;

                console.log("DD", schema, k);


                var label=(k);
                if(!main_tab.custom && schema[k]) {
                  if ((main_tab.id_crop==1 && main_tab.name=='Catture - Tignoletta della vite') || (main_tab.id_crop==2 && main_tab.name=='Catture')) {
                    if (!total_num) {
                      total_num=0;
                    }

                    if (v) {
                      total_num+=parseInt(v);
                      traps_num++;
                    }
                    avg=total_num/traps_num;

                    var schema_last_element=(Object.keys(schema).length-1);

                    var name_last_element=null;

                    var numerik=-1;
                    jQuery.each(schema,function(kschema,vschema){
                      numerik++
                      if (numerik==schema_last_element) {
                        name_last_element=kschema;
                      }
                    });

                    if (name_last_element==k) {
                      label=t('Media');
                      value=avg.toFixed(2)
                    }
                    else {
                      value='';
                    }
                  }
                  else {
                    if(schema[k].widget && (schema[k].widget=='radio' || schema[k].widget=='select')){
                      if ( Array.isArray(schema[k].voc_val)) {
                        value=schema[k].voc_val.filter(function(f){return f[v]});

                        if (value.length>0) {
                          value=value[0][v];
                        }
                        else {
                          value='---';
                        }
                      }
                      else {
                        value=schema[k].voc_val[v];
                      }
                    }
                    label=t(schema[k].label);
                  }
                }
                if(value!=''){
                  tds+=`${t(label)}: ${value}<br/>`;
                }
                }
          });
          tds+="</td>";
        }
      }

      var type_cell="";
      if(!filter){
        type_cell=`<td>${type=='observation'?t(db.resolveSurveySchema(o.id_survey_schema)):t(db.resolveCropOperation(o.id_crop_operation))}</td>`;
      }

      //Risolve (evetualmente) il nome del campo
      var fields_decode="";
      if(fields){

        function decodeFieldName(id_fields, fields){
          var fields_decode="";
          jQuery.each(id_fields, function(k,v){
            jQuery.each(fields, function(k2,v2){
              if(v2.id_field==v){
                fields_decode+=`${v2.name}<br/>`;
              }
            });
          });
          return fields_decode;
        }
        fields_decode="<td>"+decodeFieldName(o.id_fields,fields)+"</td>";
      }

      var tr=jQuery(`
        <tr >
          <td>
            <i onclick="location.hash='farm/${id_farm_center}/field/${o.id_field}/${type}/${o["id_"+type]}'"
              class="icon f7-icons">pencil
            </i>
            <i class="to-delete icon f7-icons">trash
            </i>
          </td>

          ${fields_decode}
          <td>${o.date}</td>
          ${type_cell}
          ${tds}
          <td>
            <i class="to-see icon f7-icons">eye</i>
          </td>
          <td>
            <i class="to-clone icon f7-icons">square_on_square</i>
          </td>
        </tr>
      `).appendTo(div+" tbody");

      tr.find(".to-delete").on("click", function(){

        global.app.dialog.confirm("Sei sicuro di eliminare il record?", t('Attenzione!'), function(){


          db.deleteElement(type, o["id_"+type], function(){

            if(type=='observation'){
              show_observation(id_farm_center, opt.id_field);
            }
            else{
              show_operation(id_farm_center, opt.id_field);
            }

            // //ricarica la tabella (se faceva location.reload interrompeva la sincronizzazione)
            // table_oo(div, id_farm_center, values, opt);
          });
        });
      });

      tr.find(".to-see").on("click", function(){
        if(type=='observation'){
          observation(id_farm_center, opt.id_field, o["id_"+type], o.id_survey_schema, 'seeonly');
        }
        else{
          operation(id_farm_center, opt.id_field, o["id_"+type], o.id_crop_operation, 'seeonly');
        }
      });

      tr.find(".to-clone").on("click", function(){
        if(type=='observation'){
          var new_id_observation=tools.getGuid();
          var new_observation={};
          new_observation=JSON.parse(JSON.stringify(o));
          new_observation.id_observation=new_id_observation;
          delete new_observation.date

          db.insertObservation(id_field, new_observation, function(a,b){
            console.log(a,b);
            location.hash=`farm/${id_farm_center}/field/${id_field}/observation/${new_id_observation}`;
          });
        }
        else{
          var new_id_operation=tools.getGuid();
          var new_operation={};
          new_operation=JSON.parse(JSON.stringify(o));
          new_operation.id_operation=new_id_operation;
          delete new_operation.date

          db.insertOperation(id_field, new_operation, function(a,b){
            console.log(a,b);
            location.hash=`farm/${id_farm_center}/field/${id_field}/operation/${new_id_operation}`;
          });
        }
      });

    });
  }
  else{
    jQuery(div).html(`
      <div class="block block-strong inset">
      ${t('Non ci sono dati')}
      </div>
    `);
  }

}


function deleteOO(type, id){
  console.log(type,id);
}

//Show all the crop observation
export function show_observation(id_farm_center, id_field){

  jQuery('#footer').html('');
  var fd=db.getFieldData(id_field);
  var html=`

  `;
  global.theme.render({
    exclude_card:true,
    title: `${fd.plot.name} - ${t('stagione')} ${fd.season.season_name}`,
    content: `
      <div class="block-title">${t('Elenco dei monitoraggi')}</div>
      <div style="margin:10px;">
        <button onclick="location.hash='farm/${id_farm_center}/field/${id_field}/new_observation'" class="button fs-button button-large button-fill">${t('Aggiungi un nuovo rilievo')}</button>
      </div>

      <div id="observation_list"></div>

    `
  });

  table_oo("#observation_list", id_farm_center, fd.observation,{"id_field": id_field});
}

//Show all the crop observation
export function observation(id_farm_center, id_field, id_observation, id_survey_schema, opentype){

  var fd=db.getFieldData(id_field);
  var monit_date=global.getTodayDate();
  var old_observation=null;


  //Prende se in modifica l'old_observation
  var new_id_observation=null;
  if (!id_observation) {
    new_id_observation=tools.getGuid();
  }
  else{
    old_observation=db.getObservation(id_observation);


    var html='';
    if(jQuery.isEmptyObject(old_observation)){
      html=`<div class="block-title">${t('Non hai i permessi per vedere il monitoraggio o il monitoraggio non esiste')}</div>
      <button class="button button-fill" onclick="location.hash='farm/${id_farm_center}/field/${id_field}/observation'">${t('Torna alla pagina iniziale')}</button></div>
      `;
      global.theme.render({
        exclude_card:false,
        title: ``,
        content: html
      });
      return;
    }
    
    monit_date=old_observation.date;
  }





  var other_fields=[];
  if(fd.field.id_season){
    //Plot di farmdata season contiene anche id_field e crop_name
    other_fields=db.getFarmDataSeason(id_farm_center,fd.field.id_season).plot;
    // console.log(db.getFarmDataSeason(id_farm_center,fd.field.id_season));
  }

  var opt_field=``;
  var selected_field='';
  var tot_area=0;
  jQuery.each(other_fields, function(kp,p){
    var sel="";

    if (p.field && (p.field.id_crop==fd.field.id_crop) ) {
      var field_name=`${p.name} - ${p.field.crop_name}`;
      if(p.field.id_field==id_field || (old_observation && old_observation.id_fields && old_observation.id_fields.indexOf(p.field.id_field)>-1 )){
        sel="selected='selected'";
        selected_field=selected_field+field_name+", ";
        try{
          tot_area=tot_area+parseFloat(p.area);
        }
        catch(e){;}
      }
      opt_field+=`<option data-area="${p.area}" ${sel} value="${p.field.id_field}">${field_name}</option>`;
    }

  });


  var html=`

  `;

  var optss=`<option selected disabled>Scegli la scheda</option>`;

  var ss=db.getSurveySchema();
  console.log(ss);

  var ss_for_crop=ss.filter(function(s){return s.id_crop==fd.field.id_crop || s.id_crop==0});
  var ss_list='';
  if (ss_for_crop.length==0) {
    optss=`<option selected disabled>${t('Non c\'è nessuna scheda di monitoraggio per questa coltura')}</option>`;
    ss_list=t(`Non c'è nessuna scheda di monitoraggio per questa coltura`);
  }

  if (ss_for_crop.length>0) {
    ss_list+='<ul>';
  }

  jQuery.each(ss_for_crop,function(k,v){
    console.log(v.active);
    if(v.active==0) {
      return true;
    }
    optss+=`<option value="${v.id_survey_schema}">${t(v.name)}</option>`;
    ss_list+=`
      <li>
        <label class="item-radio item-radio-icon-end item-content">
          <input type="radio" name="ss_list" value="${v.id_survey_schema}" />
          <i class="icon icon-radio"></i>
          <div class="item-inner">
            <div class="item-title">${t(v.name)}</div>
          </div>
        </label>
      </li>
    `;
  });

  if (ss_for_crop.length>0) {
    ss_list+='</ul>';
  }

  // console.log(v);

  global.theme.render({
    exclude_card:true,
    title: `${fd.plot.name} - ${t('stagione')} ${fd.season.season_name}`,
    content: `

      <div style="margin:10px;" class="hide-see-only">
        <button onclick="location.hash='farm/${id_farm_center}/season/${fd.season.id_season}/trap'" class="button fs-button button-large button-fill">${t('Gestione e rilievi trappole')}</button>
      </div>

      <div class="block-title hide-see-only">${id_observation?t("Modifica monitoraggio"):t("Crea un nuovo monitoraggio")}</div>
      <div class="list inset" id="ss_list_div">${ss_list}</div>

      <div class="list inset hide-see-only">
        <ul>
          <li class="item-content item-input" id="ss_select" style="display:none;">
            <div class="item-inner">
              <div class="item-input-wrap">
                <select id="survey_schema">${optss}</select>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input id="monit_date" type="date" value="${monit_date}" />
              </div>
            </div>
          </li>
          <li>
          <a class="item-link smart-select smart-select-init" data-open-in="popup" data-searchbar="true" data-searchbar-placeholder="Cerca">
            <select id="id_fields" multiple>
              ${opt_field}
            </select>
            <div class="item-content">
              <div class="item-inner" style="width: fit-content;">
                <div class="item-title"></div>
                <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${selected_field}</div>
              </div>
            </div>
          </a>
        </li>
        </ul>
      </div>

      <div id="add_trap" class="list inset hide-see-only"></div>
      
      

      <div id="dbmng" class="list inset"></div>

      <div style="display:none;" class="fs-photo block block-strong inset">
        ${id_observation?getPhotoHtml(id_observation):getPhotoHtml(new_id_observation)}
      </div>
    `
  });

  jQuery('#footer').html(`<button style="display:none;" class="hide-see-only button fs-button button-large button-fill" id="save_observation">${t('Salva')}</button>`);


  if (id_observation) {
    var images=old_observation.images
    fillPhotos(id_observation,images)
  }
  else {
    fillPhotos(new_id_observation)
  }

  // var id_observation=null;

  jQuery('#monit_date, #survey_schema').change(function(){
    var canRenderForm=true;
    if (jQuery('#monit_date').val()!='' && jQuery('#survey_schema').val()!=null ) {

      var season_start=new Date(fd.season.date_from).getTime();
      var season_end=new Date(fd.season.date_to).getTime();
      // season start is 90 days before the real start
      season_start=season_start-(90*24*60*60*1000);

      // season end is 90 days after the real end
      season_end=season_end+(90*24*60*60*1000);

      var monit_date_obj=new Date(jQuery('#monit_date').val()).getTime();

      // alert(monit_date_obj > season_start && monit_date_obj < season_end)
      if(!(monit_date_obj > season_start && monit_date_obj < season_end)) {
        alert(t('La data del monitoraggio dovrebbe essere compresa tra la data di inizio e fine stagione'));
        console.log("Data inizio stagione: "+new Date(season_start));
        console.log("Data monitoraggio: "+new Date(monit_date_obj));
        console.log("Data fine stagione: "+new Date(season_end));
      }
      
      jQuery('#save_observation,.fs-photo').show();

      var id_survey_schema=jQuery('#survey_schema').val();
      var theSurveySchema=db.getSurveySchema(id_survey_schema);
      // console.log(JSON.parse(theSurveySchema.schema));

      if (theSurveySchema.custom) {
        var custom=null;
        try{
          if (theSurveySchema.custom=='Mosca') {
            custom=new Mosca(id_survey_schema);
          }
          else if (theSurveySchema.custom=='Sostenibilita') {
            custom=new Sostenibilita(id_survey_schema);
          }
          else {
            custom=new Simple(id_survey_schema);
          }
        }
        catch(e){
          console.error(e);
        }

        var custom_html=custom.render(old_observation);
        jQuery('#dbmng').html(custom_html);
        custom.interact(old_observation);

      }
      else {
        var fields;
        if(typeof theSurveySchema.schema=='string'){
          fields = JSON.parse(theSurveySchema.schema);
        }
        else{
          fields = theSurveySchema.schema;
        }
          

        //1: catture (verbale generico)
        if(id_survey_schema==1){

          jQuery("#add_trap").html(`<a class="link external" href="#farm/${id_farm_center}/season/${fd.field.id_season}/trap/add_trap">Aggiungi Trappola</a>`);

          fields["id_trap"]={"label": "Trappola", "type": "string", "widget":"select","nullable":false,  "voc_val":{}};

          if(fd.trap && fd.trap.length>0){
            jQuery.each(fd.trap,function(k,v){
              fields["id_trap"].voc_val[v.id_trap]=v.name;
            });
          }
          else{
            // Devo prima inserire la trappola
            jQuery("#add_trap").html(`<a class="link external button fs-button button-large button-fill" href="#farm/${id_farm_center}/season/${fd.field.id_season}/trap/add_trap">Aggiungi Trappola</a>`);

            canRenderForm=false;
            jQuery('#save_observation,.fs-photo').hide();
            // jQuery("#add_trap").html("");
          }

          //serve per pre-selezionare la trappola se chiamata dalla pagina della trappola
          var preselected_trap=null;
          try{
            var tt=location.href.split("?")[1].split("=");
            if(tt[0]=='id_trap'){
              preselected_trap=tt[1];
            }
          }
          catch(e){;}
          if(preselected_trap){
            fields["id_trap"]["default"]=preselected_trap;
          }

        }
        else{
          jQuery("#add_trap").html("");
        }

        var aForm = {};
        aForm.primary_key = Array('id');
        aForm.fields = fields;

        var selectNMWidgetF7=dbmng_ext.extendSelectNMWidgetF7();
        jQuery.each(aForm.fields,function(k,v){
          v.label=t(v.label);
          v.placeholder=t(v.placeholder);

          if (v.voc_val) {
            jQuery.each(v.voc_val,function(kv,voc_val_v){
              console.log(voc_val_v);

              if (typeof v.voc_val[kv]=='string') {
                v.voc_val[kv]=t(voc_val_v);
              }
              else {
                jQuery.each(voc_val_v,function(i,d){
                  voc_val_v[i]=t(d);
                });
              }
            });
          }

          if (v.widget=='select_nm') {
            v.external_widget=selectNMWidgetF7;
          }
        });


        console.log("OBS",fd);



        var observation={};
        // Questa funzione suggerisce i dati per la scheda-giorno-campo se ha già i dati
        var done_obs=fd.observation.filter(
          function(o){
            return o.id_survey_schema==id_survey_schema && o.id_field==id_field && o.date==jQuery('#monit_date').val()
          }
        );

        if (id_observation) {
          renderForm('dbmng',aForm,old_observation, null, id_farm_center);
        }
        else if (done_obs.length==1) {
          global.app.dialog.create({
            title: t('Attenzione'),
            text: t('È già presente un rilievo'),
            buttons: [
              {
                text: '<span id="new_ril">'+t('Inserisci nuovo rilievo')+'</span>',
              },
              {
                text: '<span id="mod_ril">'+t('Modifica rilievo esistente')+'</span>',
              }
            ],
            verticalButtons: true,
            on: {
              open() {
                jQuery('#mod_ril').on('click', () => {
                  observation=done_obs[0];
                  id_observation=observation.id_observation;
                  renderForm('dbmng',aForm,observation, null, id_farm_center);
                });
                jQuery('#new_ril').on('click', () => {
                  renderForm('dbmng',aForm,observation, null, id_farm_center);
                });
              }
            }
          }).open();
        }
        else {
          if(canRenderForm){
            renderForm('dbmng',aForm,observation, null, id_farm_center);
          }
        }
      }

    }
  });

  jQuery('input[type=radio][name=ss_list]').change(function() {
    jQuery('#ss_select').show();
    jQuery('#ss_list_div').hide();

    jQuery('#survey_schema').val(this.value).change();
  });

  if (id_observation) {
    console.log("id_observation",id_observation);
    jQuery("input[name=ss_list][value=" + old_observation.id_survey_schema + "]").prop('checked', true).change();
  }
  else if (id_survey_schema) {
    console.log("id_survey_schema",id_survey_schema);
    jQuery("input[name=ss_list][value=" + id_survey_schema + "]").prop('checked', true).change();
  }

  jQuery('#save_observation').click(function(){
    var id_survey_schema=jQuery('#survey_schema').val();
    var theSurveySchema=db.getSurveySchema(id_survey_schema);
    var observation={};

    if (theSurveySchema.custom) {
      observation={
        num_no:jQuery('#num_puntura_no').val(),
        num_si:jQuery('#num_puntura_si').val()
      };
    }
    else {
      observation=global.form.getValue();

    }
    observation.date=jQuery('#monit_date').val();
    observation.id_survey_schema=jQuery('#survey_schema').val();
    observation.id_fields=jQuery("#id_fields").val();


    var canSave=true; 
    // check if id_survey_schema is 1 (catture) and if id_trap is null
    if (id_survey_schema==1 && !observation.id_trap) {
      canSave=false;
      alert(t('Devi selezionare una trappola'));
    }

    if (canSave) {
      if (id_observation) {
        db.updateObservation(id_observation, id_field, observation, function(){
          history.back();
          db.storeImages();
        });
      }
      else {
        observation.id_observation=new_id_observation;
        db.insertObservation(id_field, observation, function(){
          history.back();
          db.storeImages();
        });
      }   
    }
  });


  if(opentype=='seeonly'){
    hide_see_only()
  }
}

// funzione che serve e nascondeer le parti di html che non devono essere viste
// quando si è in modalità seeonly
function hide_see_only(){
  jQuery('.hide-see-only').hide();

  // disable all input fields, select, textarea, etc
  jQuery('input, select, textarea').attr('disabled', 'disabled');
}


//gestisci una crop_operation
export function operation(id_farm_center, id_field, id_operation, id_crop_operation, opentype){
  var fd=db.getFieldData(id_field);
  var monit_date=global.getTodayDate();
  var old_operation=null;


  //Prende se in modifica l'old_observation
  var new_id_operation=null;
  if (!id_operation) {
    new_id_operation=tools.getGuid();
  }
  else{
    old_operation=db.getOperation(id_operation);


    var html='';
    if(jQuery.isEmptyObject(old_operation)){
      html=`<div class="block-title">${t('Non hai i permessi per vedere l\'operazione colturale o l\'operazione colturale non esiste')}</div>
      <button class="button button-fill" onclick="location.hash='farm/${id_farm_center}/field/${id_field}/operation'">${t('Torna alla pagina iniziale')}</button></div>
      `;
      global.theme.render({
        exclude_card:false,
        title: ``,
        content: html
      });
      return;
    }
    

    monit_date=old_operation.date;
  }



  var other_fields=[];
  if(fd.field.id_season){
    //Plot di farmdata season contiene anche id_field e crop_name
    other_fields=db.getFarmDataSeason(id_farm_center,fd.field.id_season).plot;
    // console.log(db.getFarmDataSeason(id_farm_center,fd.field.id_season));
  }


  var opt_field=``;
  var selected_field='';
  var tot_area=0;
  jQuery.each(other_fields, function(kp,p){
    var sel="";

    if (p.field) {
      var field_name=`${p.name} - ${p.field.crop_name}`;
      if(p.field.id_field==id_field || (old_operation && old_operation.id_fields && old_operation.id_fields.indexOf(p.field.id_field)>-1 )){
        sel="selected='selected'";
        selected_field=selected_field+field_name+", ";
        try{
          tot_area=tot_area+parseFloat(p.area);
        }
        catch(e){;}
      }
      opt_field+=`<option data-area="${p.area}" ${sel} value="${p.field.id_field}">${field_name}</option>`;
    }

  });





  var optco=`<option selected disabled>${t('Scegli l\'operazione colturale')}</option>`;
  var co=db.getCropOperation();
  console.log(co);

  var co_list='';
  co_list+='<ul>';
  jQuery.each(co.filter(function(s){return s.active==1 && (s.id_crop==0 || s.id_crop==fd.field.id_crop) }),function(k,v){
    optco+=`<option value="${v.id_crop_operation}">${t(v.name)}</option>`;
    co_list+=`
      <li>
        <label class="item-radio item-radio-icon-end item-content">
          <input type="radio" name="co_list" value="${v.id_crop_operation}" />
          <i class="icon icon-radio"></i>
          <div class="item-inner">
            <div class="item-title">${t(v.name)}</div>
          </div>
        </label>
      </li>
    `;
  });

  co_list+='</ul>';
  // console.log(v);
  // debugger
  global.theme.render({
    exclude_card:true,
    title: `${id_field?fd.plot.name:''} ${id_field?' - '+t('stagione')+' '+fd.season.season_name:''}`,
    content: `
      <div class="block-title hide-see-only">${t('Crea una nuova operazione colturale')}</div>
      <div class="list inset" id="co_list_div">${co_list}</div>

      <div class="list inset hide-see-only">
        <ul>
          <li class="item-content item-input" id="co_select" style="display:none;">
            <div class="item-inner">
              <div class="item-input-wrap">
                <select id="crop_operation">${optco}</select>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input id="monit_date" type="date" value="${monit_date}" />
              </div>
            </div>
          </li>
          <li>
          <a class="item-link smart-select smart-select-init" data-open-in="popup" data-searchbar="true" data-searchbar-placeholder="Cerca">
            <select id="id_fields" multiple>
              ${opt_field}
            </select>
            <div class="item-content">
              <div class="item-inner" style="width: fit-content;">
                <div class="item-title"></div>
                <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${selected_field}</div>
              </div>
            </div>
          </a>
        </li>
        </ul>
      </div>

      <div id="product_div" class="list inset"></div>

      <div id="dbmng" class="list inset"></div>
      <input id="tot_area" value="${tot_area}" disabled=disabled  type="hidden">
      <div class="block" id="calc_dose"></div>


      <div id="other_button"></div>



      <div style="display:none;" class="fs-photo block block-strong inset">
        ${id_operation?getPhotoHtml(id_operation):getPhotoHtml(new_id_operation)}
      </div>
    `
  });
  
  jQuery('#footer').html(`<button style="display:none;" class="hide-see-only button fs-button button-large button-fill" id="save_operation">${t('Salva')}</button>`);



  if (id_operation) {
    var images=old_operation.images
    fillPhotos(id_operation,images)
  }
  else {
    fillPhotos(new_id_operation)
  }




  //UPdate the total area
  jQuery("#id_fields").on("change", function(){
    var tot_area=0;
    jQuery("#id_fields option:selected").each(function(kk,vv){

      try{
        tot_area=tot_area+parseFloat(jQuery(vv).attr("data-area"));
      }
      catch(e){;}
      jQuery("#tot_area").val(tot_area);
    });
  });


  jQuery('#monit_date, #crop_operation').change(function(){
    if (jQuery('#monit_date').val()!='' && jQuery('#crop_operation').val()!=null ) {
      jQuery('#save_operation,.fs-photo').show();

      var id_crop_operation=jQuery('#crop_operation').val();
      var theCropOperation=db.getCropOperation(id_crop_operation);

      var fields;
      if (typeof theCropOperation.schema=='string'){
        fields=JSON.parse(theCropOperation.schema);
      }
      else {
        fields=theCropOperation.schema;
      }
      var aForm = {};
      aForm.primary_key = Array('id');
      aForm.fields = fields;

      var selectNMWidgetF7=dbmng_ext.extendSelectNMWidgetF7();
      jQuery.each(aForm.fields,function(k,v){
        v.label=t(v.label);
        v.placeholder=t(v.placeholder);

        if (v.voc_val) {
          jQuery.each(v.voc_val,function(kv,voc_val_v){
            console.log(voc_val_v);

            if (typeof v.voc_val[kv]=='string') {
              v.voc_val[kv]=t(voc_val_v);
            }
            else {
              jQuery.each(voc_val_v,function(i,d){
                voc_val_v[i]=t(d);
              });
            }
          });
        }

        if (v.widget=='select_nm') {
          v.external_widget=selectNMWidgetF7;
        }
      });


      console.log("OPERATION",fd);

      var operation={};
      // Questa funzione suggerisce i dati per la scheda-giorno-campo se ha già i dati
      var done_obs=fd.operation.filter(
        function(o){
          return o.id_crop_operation==id_crop_operation && o.id_field==id_field && o.date==jQuery('#monit_date').val()
        }
      );

      if (old_operation) {
        renderForm('dbmng',aForm,old_operation,id_crop_operation, id_farm_center);
      }
      else if (done_obs.length==1) {
        global.app.dialog.create({
          title: t('Attenzione'),
          text: t(`È già presente un'operazione`),
          buttons: [
            {
              text: '<span id="new_ril">'+t('Inserisci nuova operazione')+'</span>',
            },
            {
              text: '<span id="mod_ril">'+t('Modifica operazione esistente')+'</span>',
            }
          ],
          verticalButtons: true,
          on: {
            open() {
              jQuery('#mod_ril').on('click', () => {
                operation=done_obs[0];
                id_operation=operation.id_operation;
                renderForm('dbmng',aForm,operation, id_crop_operation, id_farm_center);
              });
              jQuery('#new_ril').on('click', () => {
                renderForm('dbmng',aForm,operation, id_crop_operation, id_farm_center);
              });
            }
          }
        }).open();
      }
      else {
        renderForm('dbmng',aForm,operation, id_crop_operation, id_farm_center);
      }

      jQuery('#dbmng .real_widget').change(function(){
        jQuery('#monit_date').unbind().change(function(){
          console.log("non fa più nulla al cambio della data...");
        })
      });


    }
      
    if(id_crop_operation==5){
      jQuery('#monit_date').parent().parent().parent().hide()
    }
    else{
      jQuery('#monit_date').parent().parent().parent().show()
    }

    if(id_crop_operation==3 || id_crop_operation==10){
      jQuery('#other_button').html(`<div class="list inset">
        <button class="button" id="add_product">${t('Aggiungi un altro prodotto')}</button>
      </div>
      `);

      jQuery('#add_product').click(function(){

        let num_prod=jQuery('.product_block').length;

        prodotto_fn(id_farm_center, id_crop_operation, operation, num_prod);
      });

    }
    else{
      jQuery('#other_button').html('');
    }

  });



  jQuery('input[type=radio][name=co_list]').change(function() {
    jQuery('#co_select').show();
    jQuery('#co_list_div').hide();

    jQuery('#crop_operation').val(this.value).change();
  });

  if (id_operation) {
    console.log("id_operation",id_operation);
    jQuery("input[name=co_list][value=" + old_operation.id_crop_operation + "]").prop('checked', true).change();
  }
  else if (id_crop_operation) {
    console.log("id_crop_operation",id_crop_operation);
    jQuery("input[name=co_list][value=" + id_crop_operation + "]").prop('checked', true).change();
  }


  jQuery('#save_operation').click(function(){


    saveOperation(old_operation, id_operation, id_field, new_id_operation, function(){
      history.back();
      db.storeImages();
    });
    
  });


  if(opentype=='seeonly'){
    hide_see_only()
  }
}

//salva una nuova operazione
function saveOperation(old_operation, id_operation, id_field, new_id_operation, on_success){

  // console.log(global.form.getValue());
  // console.log(global.form.isValid());
  // debugger
  var isValid=global.form.isValid();
  if(!isValid.ok){
    global.app.input.validateInputs('#dbmng');
    console.log(isValid.message);
    // alert(t('Controlla i campi obbligatori'));
    return;
  }

  var operation=global.form.getValue();
  operation.date=jQuery('#monit_date').val();
  operation.id_crop_operation=jQuery('#crop_operation').val();
  operation=photo.addImages(operation);

  operation.id_fields=jQuery("#id_fields").val();

  //prende i dati della concimazione e dei trattameti
  if(operation.id_crop_operation==3 || operation.id_crop_operation==10){


    let products=[];

    let n=0;
    let p=0;
    let k=0;

    jQuery('.product_block').each(function(){

      let id=jQuery(this).attr('id');
      let order=id.split('_')[2];

      


      let prd={};
      prd.id_product=jQuery(".dbmng_form_field_id_product_"+order+"  select").val();
      prd.product_name=jQuery(".dbmng_form_field_product_name_"+order+" input").val();
      prd.uom=jQuery(".dbmng_form_field_uom_"+order+" select").val();
      prd.amount_tot=jQuery(".dbmng_form_field_amount_tot_"+order+" input").val();
      prd.amount_ha=jQuery(".dbmng_form_field_amount_ha_"+order+" input").val();
      

      if(operation.id_crop_operation==3){
        prd.dose_no3=jQuery(".dbmng_form_field_dose_n1_"+order+" input").val();
        prd.dose_nh4=jQuery(".dbmng_form_field_dose_n2_"+order+" input").val();
        prd.dose_urea=jQuery(".dbmng_form_field_dose_n3_"+order+" input").val();

        n=n+parseFloat(prd.dose_no3)+parseFloat(prd.dose_nh4)+parseFloat(prd.dose_urea);

        prd.dose_p2o5=jQuery(".dbmng_form_field_dose_p_"+order+" input").val();

        p=p+parseFloat(prd.dose_p2o5);
        prd.dose_k2o=jQuery(".dbmng_form_field_dose_k_"+order+" input").val();
        k=k+parseFloat(prd.dose_k2o);


      }
      else if(operation.id_crop_operation==10){
        prd.reason=jQuery(".dbmng_form_field_reason_"+order+" input").val();
      }

      products.push(prd);
      

      order=order+1;
    });


    operation.products=products;

    if(operation.id_crop_operation==3){
      operation.no3=n;
      operation.p2o5=p;
      operation.k2o=k;
    }

  }


  if (old_operation) {
    db.updateOperation(id_operation, id_field, operation, function(a,b){
      if(on_success){
        on_success(a,b);
      }
    });
  }
  else {
    operation.id_operation=new_id_operation;
    db.insertOperation(id_field, operation, function(a,b){
      if(on_success){
        on_success(a,b);
      }
    });
  }
}

//Funzione per poter gestire le interazioni su particolari crop operation
function addSmartInteraction(id_farm_center, id_crop_operation, old_operation){

    //Special per irrigazione
    if(id_crop_operation==2){
      jQuery("#id_fields , .dbmng_form_field_water input").on("change", function(){

        var area=parseFloat(jQuery("#tot_area").val());
        var mm=parseFloat(jQuery(".dbmng_form_field_water input").val());
        var txt="";
        if(mm){
          txt=`L'irrigazione proposta è pari a ${mm*10} mc/ha e ${(mm*10*area/10000).toFixed(1)} mc complessivi su una superficie di ${(area/10000).toFixed(1)}ha.`
        }
        else{
          txt+=t("Inserisci l'irrigazione in mm");
        }
        jQuery("#calc_dose").html(txt);
      });

      jQuery(".dbmng_form_field_water input").trigger("change");
    }
    //Special per irrigazione e per semina
    if(id_crop_operation==7 || id_crop_operation==9){

      //campo contiene utila selezionata
      jQuery(".dbmng_form_field_type_amount").hide();

      jQuery("#id_fields").on("change", function(){

        let type=jQuery('.dbmng_form_field_type_amount input').val();
        console.log(type);
        if(type==''){type='tot';}

        jQuery(".dbmng_form_field_amount_"+type+" input").trigger("change");

      });

      jQuery(".dbmng_form_field_amount_tot input").on("change", function(){

        let area=parseFloat(jQuery("#tot_area").val());
        let amount=parseFloat(jQuery(".dbmng_form_field_amount_tot input").val());

        let amount_ha=amount/(area/10000);

        jQuery(" .dbmng_form_field_amount_ha input" ).val(amount_ha.toFixed(3));
        jQuery(" .dbmng_form_field_type_amount input" ).val("tot");
      });

      jQuery(".dbmng_form_field_amount_ha input").on("change", function(){

        let area=parseFloat(jQuery("#tot_area").val());
        let amount_ha=parseFloat(jQuery(".dbmng_form_field_amount_ha input").val());

        let amount =amount_ha * (area/10000);

        jQuery(" .dbmng_form_field_amount_tot input" ).val(amount.toFixed(3));
        jQuery(" .dbmng_form_field_type_amount input" ).val("ha");
      });
    }
    

    if(id_crop_operation==3 || id_crop_operation==10 ){

      if(old_operation.products && old_operation.products.length>0){
        jQuery(old_operation.products).each(function(index, prd){
          prodotto_fn(id_farm_center, id_crop_operation, old_operation, index);
        });
      }
      else{
        prodotto_fn(id_farm_center, id_crop_operation, old_operation, 0);
      }
    }

}

//aggiorna i dati della fertilizzazione
function updateFert(id_crop_operation, order){
  console.log("fert");

  var prd=jQuery(".dbmng_form_field_id_product_"+order+" option:selected");

  var id_product=prd.val();
  if(id_product=="xxx"){
    //show product_name
    if(jQuery(".dbmng_form_field_product_name_"+order+" input").val()==global.label_other){
      jQuery(".dbmng_form_field_product_name_"+order+" input").val("");
    }
    jQuery(".dbmng_form_field_product_name_"+order).show();
  }  
  else{
    jQuery(".dbmng_form_field_product_name_"+order).hide();
  }

  var amount_ha=jQuery(".dbmng_form_field_amount_ha_"+order+" input").val();


  var uom_moltiplica=parseFloat((jQuery(".dbmng_form_field_uom_"+order+" option:selected")).attr("data-convert"));

  if(id_crop_operation==3){

    var no3=parseFloat(jQuery(".dbmng_form_field_dose_n1_"+order+" input").val());
    var nh4=parseFloat(jQuery(".dbmng_form_field_dose_n2_"+order+" input").val());
    var urea=parseFloat(jQuery(".dbmng_form_field_dose_n3_"+order+" input").val());
    var p2o5=parseFloat(jQuery(".dbmng_form_field_dose_p_"+order+" input").val());
    var k2o=parseFloat(jQuery(".dbmng_form_field_dose_k_"+order+" input").val());

    

    if(isNaN(no3)){
      jQuery("#fert_unit_"+order+"").html("Dosi sconosciute")
    }
    else if(amount_ha && prd.length>0){

      if(isNaN(no3)){no3=0;}
      if(isNaN(nh4)){nh4=0;}
      if(isNaN(urea)){urea=0;}
      if(isNaN(p2o5)){p2o5=0;}
      if(isNaN(k2o)){k2o=0;}

      amount_ha=parseFloat(amount_ha);

      var n1=(uom_moltiplica*amount_ha*no3/100.0);
      var n2=(uom_moltiplica*amount_ha*nh4/100.0);
      var n3=(uom_moltiplica*amount_ha*urea/100.0);
      var p=(uom_moltiplica*amount_ha*p2o5/100.0);
      var k=(uom_moltiplica*amount_ha*k2o/100.0);

      jQuery(".dbmng_form_field_n1_"+order+" input").val(n1);
      jQuery(".dbmng_form_field_n2_"+order+" input").val(n2);
      jQuery(".dbmng_form_field_n2_"+order+" input").val(n3);
      jQuery(".dbmng_form_field_p_"+order+" input").val(p);
      jQuery(".dbmng_form_field_k_"+order+" input").val(k);

      jQuery("#fert_unit_"+order+"").html(`NPK: ${(n1+n2+n3).toFixed(1)} - ${p.toFixed(1)} - ${k.toFixed(1)}`);

    }
  }
  else{
    if(amount_ha){
      jQuery("#fert_unit_"+order+"").html(parseFloat(amount_ha).toFixed(2));
    }
    else{
      jQuery("#fert_unit_"+order+"").html("");
    }
  }
}

function prodotto_fn(id_farm_center, id_crop_operation, old_operation, order){

  let ware=db.getWarehouse(id_farm_center);

  let id_product_type="ferti"
  if(id_crop_operation==10){
    id_product_type="pesti"
  }

  let list_product=ware.product.filter(function(f){
    return f.id_product_type==id_product_type
  });

  var prd="<option  ></option>";
  jQuery.each(list_product, function(kf,f){

    let other_field="";
    if(id_crop_operation==3){
      other_field=` data-n="${f.n?f.n:0}" data-p2o5="${f.p?f.p:0}"  data-k2o="${f.k?f.k:0}" `;
    }

    prd+=`<option ${other_field} value="${f.id_product}">${t(f.name)}
        </option>`
  });

  global.label_other=t('Altro prodotto (inserimento manuale)');

  prd+=`<option  data-no3="0" data-nh4="0"  data-urea="0"  data-p2o5="0"  data-k2o="0" value="xxx">${global.label_other}</option>`;
  prd+=`<option  data-no3="0" data-nh4="0"  data-urea="0"  data-p2o5="0"  data-k2o="0" value="xxx_load">${t('Carica nel magazzino')}</option>`;

  //nascondi i campi
  jQuery(`.dbmng_form_field_k_${order}, .dbmng_form_field_p_${order}, .dbmng_form_field_n1_${order}, .dbmng_form_field_n2_${order}, .dbmng_form_field_n3_${order}`).hide();
  //vecchi campi (poi si tolgono dal dbmng)
  jQuery(`.dbmng_form_field_k2o, .dbmng_form_field_p2o5, .dbmng_form_field_no3, .dbmng_form_field_nh4, .dbmng_form_field_urea`).hide();

  jQuery(`.dbmng_form_field_dose_ha, .dbmng_form_field_nome_prodotto,  .dbmng_form_field_target,  .dbmng_form_field_udm `).hide();
  
  //Aggiungi i nuovo campi

  jQuery("#product_div").append(`

  <ul class="product_block" id="product_block_${order}">
  <li style="margin-bottom:60px;"">
    
    <div class="row">
      <div class="col-75">
        <div class="item-content item-input dbmng_form_field_id_product_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('Tipo Prodotto')}</div>
            <div class="item-input-wrap">
              <select class="real_widget">
                ${prd}
              </select>
            </div>
          </div>
        </div>
      </div>      
      <div class="col-25" style="margin: auto;">
        <button class="button button-primary" id="remove_product_${order}"><i class="f7-icons" style="font-size: 30px;">minus_circle_fill</i></button>
      </div>
    </div>     
  </div>

    <div class="row">
      <div class="col-100">
        <div style="display:none" class="item-content item-input dbmng_form_field_product_name_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('Nome Prodotto')}</div>
              <div class="item-input-wrap">
                <input type="text" placeholder="${t('Inserisci il nome del prodotto')}" class="real_widget">  
              </div>
            </div>
          </div>
        </div>      
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <div class="item-content item-input dbmng_form_field_uom_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('Unità')}</div><div class="item-input-wrap">
              <select>
                <option data-convert="1" value="kg">kg</option>
                <option data-convert="100" value="q">q</option>
                <option data-convert="1000" value="t">T</option>
                <option data-convert="1" value="l">l</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-35">
        <div class="item-content item-input dbmng_form_field_amount_tot_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('Totale')}</div><div class="item-input-wrap">
              <input type="number" class="real_widget"></input>
            </div>
          </div>
        </div>
      </div>
      <div class="col-40">
        <div class="item-content item-input dbmng_form_field_amount_ha_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('Quantita/ha')}</div><div class="item-input-wrap">
              <input type="number" class="real_widget"></input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display:none;" class="fertilizisation_dose row">
      <div class="col-20">
        <div class="item-content item-input dbmng_form_field_dose_n1_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('NO3')}</div><div class="item-input-wrap">
            <input type="number" class="real_widget"></input>
            </div>
          </div>
        </div>
      </div>
      <div class="col-20">
        <div class="item-content item-input dbmng_form_field_dose_n2_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('NH4')}</div><div class="item-input-wrap">
            <input type="number" class="real_widget"></input>
            </div>
          </div>
        </div>
      </div>
    <div class="col-20">
        <div class="item-content item-input dbmng_form_field_dose_n3_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('Urea')}</div><div class="item-input-wrap">
            <input type="number" class="real_widget"></input>
            </div>
          </div>
        </div>
      </div>
    <div class="col-20">
        <div class="item-content item-input dbmng_form_field_dose_p_${order}">
          <div class="item-inner">
            <div class="item-title item-label">${t('P2O5')}</div><div class="item-input-wrap">
            <input type="number" class="real_widget"></input>
            </div>
          </div>
        </div>
      </div>
    <div class="col-20">
    <div class="item-content item-input dbmng_form_field_dose_k_${order}">
      <div class="item-inner">
        <div class="item-title item-label">${t('K2O')}</div><div class="item-input-wrap">
        <input type="number" class="real_widget"></input>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


    ${id_product_type=="ferti"?`
      <div class="item-content item-input">
        <div class="item-inner">
          <div class="item-title item-label">${t('Unità/ha')}</div><div id="fert_unit_${order}" class="item-input-wrap">
            ${t('Scegli un prodotto ed inserisci una quantità')}...
          </div>
        </div>
      </div>
    `: `
    <div class="item-content item-input dbmng_form_field_reason_${order}">
      <div class="item-inner">
        <div class="item-title item-label">${t('Motivo del trattamento')}</div><div class="item-input-wrap">
          <input type="text" class="real_widget"></input>
          </div>
        </div>
      </div>
    `}


    </li>
    </ul>

  `);

  jQuery("#remove_product_"+order).on("click",function(){
    
    let num_prod=jQuery(".product_block").length;
    if(num_prod>1 ){
      jQuery("#product_block_"+order).remove();
    }
    else{
      alert("Devi avere almeno un prodotto");
    }
  });


  //load the create product page...
  jQuery(".dbmng_form_field_id_product_"+order+" select").on("change",function(){
    let id_product=jQuery(this).val();
    if(id_product=='xxx_load'){


      let op=old_operation;
      let id_op=null
      let new_id_operation=null;


      if(!op.id_operation){
        op=null;
        id_op=null;
        new_id_operation=tools.getGuid();
      }
      else{
        
      }


      let id_field=jQuery("#id_fields").val()[0];
      //prima deve salvare i dati inseriti e poi deve fare il redirect
      saveOperation(op, id_op, id_field, new_id_operation, function(a,b,c){

        //add to history the current state


        let use_operation=new_id_operation;
        if(op && op.id_operation){
          use_operation=op.id_operation;
        }



        let ss=location.href.split("#");
        let new_href=ss[0]+"#farm/"+id_farm_center+"/field/"+id_field+"/operation/"+use_operation+"/";
        history.pushState({},"",new_href);

        location.hash="#farm/"+id_farm_center+"/warehouse/new/"+id_product_type+"?id_operation="+use_operation+"&id_field="+id_field+"";



      });


    }
  });
 

  //prende i dati inseriti
  if(old_operation){

    if(old_operation.products){

      if(order < old_operation.products.length ){

        //get the i products
        let prd=old_operation.products[order];

        jQuery(".dbmng_form_field_id_product_"+order+"  select").val(prd.id_product);
        jQuery(".dbmng_form_field_product_name_"+order+" input").val(prd.product_name);
        jQuery(".dbmng_form_field_uom_"+order+" select").val(prd.uom);
        jQuery(".dbmng_form_field_amount_tot_"+order+" input").val(prd.amount_tot);
        jQuery(".dbmng_form_field_amount_ha_"+order+" input").val(prd.amount_ha);
  
        jQuery(".dbmng_form_field_amount_ha_"+order+" input").val(prd.amount_ha);
  
        if(id_crop_operation==3){
          jQuery(".dbmng_form_field_dose_n1_"+order+" input").val(prd.dose_no3);
          jQuery(".dbmng_form_field_dose_n2_"+order+" input").val(prd.dose_nh4);
          jQuery(".dbmng_form_field_dose_n3_"+order+" input").val(prd.dose_urea);
          jQuery(".dbmng_form_field_dose_p_"+order+" input").val(prd.dose_p2o5);
          jQuery(".dbmng_form_field_dose_k_"+order+" input").val(prd.dose_k2o);          
        }
        else if(id_crop_operation==10){
          jQuery(".dbmng_form_field_reason_"+order+" input").val(prd.reason);
        }
        updateFert(id_crop_operation, order);


        var preselected_idproduct=null;
        try{
          var tt=location.href.split("?")[1].split("=");
          if(tt[0]=='id_product'){
            preselected_idproduct=tt[1];
          }
        }
        catch(e){;}
        if(preselected_idproduct){
          jQuery(".dbmng_form_field_id_product_0  select").val(preselected_idproduct).trigger("change");

          var prdk=jQuery('.dbmng_form_field_id_product_0 option:selected');
          jQuery(".dbmng_form_field_product_name_0 input").val(prdk.text());

          if(prdk.attr("data-n")!=null){
            jQuery(".dbmng_form_field_dose_n1_0 input").val(prdk.attr("data-n"));
            jQuery(".dbmng_form_field_dose_n2_0 input").val(0);
            jQuery(".dbmng_form_field_dose_n3_0 input").val(0);
          }
          else{
            jQuery(".dbmng_form_field_dose_n1_0 input").val(prdk.attr("data-no3"));
            jQuery(".dbmng_form_field_dose_n2_0 input").val(prdk.attr("data-nh4"));
            jQuery(".dbmng_form_field_dose_n3_0 input").val(prdk.attr("data-urea"));
          }
          jQuery(".dbmng_form_field_dose_p_0 input").val(prdk.attr("data-p2o5"));
          jQuery(".dbmng_form_field_dose_k_0 input").val(prdk.attr("data-k2o"));
          updateFert(id_crop_operation, 0);


        }

      }
      else{
        alert("Errore: non ci sono abbastanza prodotti");
      }


    }
    else{
    
      // jQuery(".dbmng_form_field_id_product  select").val(old_operation.id_product);
      // jQuery(".dbmng_form_field_product_name input").val(old_operation.product_name);
      // jQuery(".dbmng_form_field_uom input").val(old_operation.uom);
      // jQuery(".dbmng_form_field_amount_tot input").val(old_operation.amount_tot);
      // jQuery(".dbmng_form_field_amount_ha input").val(old_operation.amount_ha);

      // jQuery(".dbmng_form_field_amount_ha input").val(old_operation.amount_ha);

      // if(id_crop_operation==3){
      //   jQuery(".dbmng_form_field_dose_n1 input").val(old_operation.dose_no3);
      //   jQuery(".dbmng_form_field_dose_n2 input").val(old_operation.dose_nh4);
      //   jQuery(".dbmng_form_field_dose_n3 input").val(old_operation.dose_urea);
      //   jQuery(".dbmng_form_field_dose_p input").val(old_operation.dose_p2o5);
      //   jQuery(".dbmng_form_field_dose_k input").val(old_operation.dose_k2o);
        
      // }
      // updateFert(id_crop_operation, order);
    }

  }


  //se cambio il totale o i campi aggiorno la produzione per ettaro
  jQuery(`.dbmng_form_field_amount_tot_${order} input, #id_fields   `).on("change", function(){

    var area=parseFloat(jQuery("#tot_area").val())/10000;
    var tot=parseFloat(jQuery(`.dbmng_form_field_amount_tot_${order} input`).val());


    jQuery(".dbmng_form_field_amount_ha_"+order+" input").val((tot/area).toFixed(2));
    console.log("tot cambia ha");

    updateFert(id_crop_operation, order);
  });

  //se cambio il prodotto per ettaro aggiorno il totale
  jQuery(".dbmng_form_field_amount_ha_"+order+" input ").on("change", function(){
    var area=parseFloat(jQuery("#tot_area").val())/10000;
    var prod_ha=parseFloat(jQuery(".dbmng_form_field_amount_ha_"+order+" input").val());
    jQuery(".dbmng_form_field_amount_tot_"+order+" input").val((prod_ha*area).toFixed(2));

    updateFert(id_crop_operation, order);
  });


    //se cambio prodotto
  jQuery(".dbmng_form_field_id_product_"+order+" select  ").on("change", function(){


    var prd=jQuery('.dbmng_form_field_id_product_'+order+' option:selected');


    jQuery(".dbmng_form_field_product_name_"+order+" input").val(prd.text());

    if(prd.attr("data-n")!=null){
      jQuery(".dbmng_form_field_dose_n1_"+order+" input").val(prd.attr("data-n"));
      jQuery(".dbmng_form_field_dose_n2_"+order+" input").val(0);
      jQuery(".dbmng_form_field_dose_n3_"+order+" input").val(0);
    }
    else{
      jQuery(".dbmng_form_field_dose_n1_"+order+" input").val(prd.attr("data-no3"));
      jQuery(".dbmng_form_field_dose_n2_"+order+" input").val(prd.attr("data-nh4"));
      jQuery(".dbmng_form_field_dose_n3_"+order+" input").val(prd.attr("data-urea"));
    }
    jQuery(".dbmng_form_field_dose_p_"+order+" input").val(prd.attr("data-p2o5"));
    jQuery(".dbmng_form_field_dose_k_"+order+" input").val(prd.attr("data-k2o"));

    updateFert(id_crop_operation, order);

  });

  //unità di misura
  jQuery(".dbmng_form_field_uom_"+order+" select, .dbmng_form_field_dose_n1_"+order+" input, .dbmng_form_field_dose_n2_"+order+" input, .dbmng_form_field_dose_n3_"+order+" input,.dbmng_form_field_dose_p_"+order+" input,.dbmng_form_field_dose_k_"+order+" input ").on("change", function(){
    console.log("cambia prodotto")
    updateFert(id_crop_operation, order);
  }); 
}

export function getPhotoHtml(id_obersvation, images){


  var html='';
  html+="<div id='photo'>";
    html+='<p class="row hide-see-only">';
      if (!global.is_cordova()) {
        html+='<input style="display:none;" id="scatta_foto" class="scatta_foto scatta_foto_trigger col" type="file" accept="image/*"></input>';
        html+='<button id="scatta_foto_btn" class="scatta_foto col button button-large button-raised" type="file" accept="image/*"><span>'+t('Aggiungi')+'</span> <i class="icon f7-icons">camera_fill</i></button>';

      }
      else {
        html+='<button id="scatta_foto_camera" class="scatta_foto scatta_foto_trigger col button button-large button-raised" type="file" accept="image/*"><span>'+t('Aggiungi')+'</span> <i class="icon f7-icons">camera_fill</i></button>';
        html+='<button id="scatta_foto_gallery" class="scatta_foto scatta_foto_trigger col button button-large button-raised" type="file" accept="image/*"><span>'+t('Aggiungi')+'</span> <i class="icon f7-icons">photo_fill_on_rectangle_fill</i></button>';
      }
    html+='</p>';

    try {
      html+=photo.getPhotoDiv(id_obersvation, images);
    }
    catch (e) {
      global.crash_log(e,"photo.getPhotoDiv(id_obersvation) -");
    }
  html+="</div>";
  return html;
}

export function fillPhotos(id_obersvation, images){
  
  photo.fillPhotoDiv(id_obersvation, images);

  if (global.is_cordova()) {
    jQuery(".scatta_foto_trigger").click(function() {
      var soruce=jQuery(this).attr("id");
      if(soruce=="scatta_foto_camera"){
        photo.takePicture(id_obersvation,null, Camera.PictureSourceType.CAMERA);
      }
      else if(soruce=="scatta_foto_gallery"){
        photo.takePicture(id_obersvation,null, Camera.PictureSourceType.PHOTOLIBRARY);
      }
    });

  }
  else {
    jQuery("#scatta_foto").change(function() {
      photo.takePicture(id_obersvation);
    });

    jQuery('#scatta_foto_btn').click(function(){
      // debugg
      jQuery("#scatta_foto").click();
    });
  }
}


function renderForm(div,aForm,dataVal, id_crop_operation, id_farm_center){
  var theme_f7 = new Dbmng.Framework7Theme();
  
  if(aForm.fields && !aForm.fields["notes"]){
    aForm.fields["notes"]={"label": "Note"}
  }

  global.form=new Dbmng.Form({aForm:aForm, aParam:{exclude_card:true}, theme:theme_f7});
  jQuery('#'+div).html(global.form.createForm(dataVal));

  
  if(dataVal.images && typeof dataVal.images=='string'){
    dataVal.images=JSON.parse(dataVal.images);
  }
  
  if(dataVal.images && dataVal.images.length>0){

    let guid_rilievo=dataVal.id_observation;
    if(typeof guid_rilievo=='undefined'){
      guid_rilievo=dataVal.id_operation;
    }

    jQuery('.fs-photo').html(getPhotoHtml(guid_rilievo, dataVal.images))
    fillPhotos(guid_rilievo, dataVal.images);
  }

  //Interazioni avanzate per determinate crop operation
  if(id_crop_operation){
    addSmartInteraction(id_farm_center, id_crop_operation, dataVal);
  }



}
