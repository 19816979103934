import global from './global.js';
import storage from './storage.js';
import * as Login from "./login.js";

import t from './translate.js';
import exe_routes from "./routes.js";
import * as tools_routes from "./tools.js";


window.handleOpenURL = function(url) {
  if(url.indexOf('code=')==-1){
    let hash=url.split('#')[1];
    if(hash.indexOf('login')!=-1){
      location.hash=hash;
    }
    else{
      var s=storage.get();
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>ORA CI SONO QUASI>>>>>>>>>>",s);
      location.hash=hash;
    }
  }
  else{
    console.log(">>>>>>>>>>>>>>>>>>>");

    console.log("Closing SafariViewController....");
    if(global.is_cordova() && global.iOS()){
      if(SafariViewController){
        SafariViewController.hide();
      }
    }

    global.app.dialog.preloader("Sto effettuando l'accesso...");
    // do stuff, for example
    // document.getElementById("url").value = url;
    console.log(url);
    // var url=url.replace('oliverecapp://a','');
    let code=url.split('code=')[1];
    console.log(code);
    Login.getTokensOauth2(code);
    // firstApi(oauth2_data,"init_plus", code);

    console.log("<<<<<<<<<<<<<<<<<<<<<");
  }
};

export default function init_app_plus(theme, project){
  // alert('init_app_plus  called');
  if (global.is_cordova()) {
    // alert('init_app_plus  is_cordova');


    
    console.log("is cordova");
    document.addEventListener('deviceready',function(){
      if(cordova.getAppVersion){
        cordova.getAppVersion.getVersionNumber(function (version) {
          global.app_version=version;
          console.log("app version cordova:", global.app_version);
          init_app_plus_called(theme, project);
        });
      }
      else{
        init_app_plus_called(theme, project);
      }
      
    },false);
  }
  else {

    console.log("controllo se ho il custom scheme");
    if(global.custom_scheme_url){
      console.log("ho il custom scheme ma questa funzione non è ancora attivata");

      // detect if is browser in android 
      var isMobile = {
        Windows: function() {
            return /IEMobile/i.test(navigator.userAgent);
        },
        Android: function() {
            return /Android/i.test(navigator.userAgent);
        },
        BlackBerry: function() {
            return /BlackBerry/i.test(navigator.userAgent);
        },
        iOS: function() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows());
        }
      };
      
      if(isMobile.Android()){
        try{
          console.log("ho il custom scheme");
          location.href=location.href.replace(location.origin,global.custom_scheme_url)        
        }
        catch(e){
          console.log("non trovo il custom scheme");
        }
      }
      
    }

    console.log("not cordova");
    init_app_plus_called(theme, project);
  }
}



function init_app_plus_called(theme, project){
  global.plus=true;


  init_app_internal(theme, project);

  // console.log(project);
  global.theme=theme;
  global.project=project;
  global.loadingText=t('Caricamento in corso..');
  global.lockApp=false;

  if (typeof global.settingsName=='undefined') {
    global.settingsName='template_settings';
  }

  console.log(global.language);
  if (typeof global.language=='undefined') {
    global.language='en';
  }

  // console.log($);
  // console.log($.jStorage);
  global.jQuery=jQuery;
  if(jQuery){
    jQuery.jStorage=$.jStorage;
    // console.log(jQuery.jStorage);
  }

  var s=storage.get();
  if (s && s.language) {
    global.language=s.language;
  }


  if(s.user && s.user.uid){
    jQuery.ajaxSetup({
      headers: { 'selected_profile': s.user.uid }
    });
  }

  console.log("start plus");
  ;


  let oauth2_data={};
  if(s.user){
    if(s.user.refresh_token){
      oauth2_data={
        accessToken:s.user.access_token,
        refreshToken:s.user.refresh_token,
        check_token: true
      }
    }
  }
  

  //A colte il reload avvienie cosi velcemente che non è stato salvato lo storage
  // le funzioni di firstApi e init_plus non vengono chiamate
  // se è passato meno di x secondi dall'ultima call

 

  // Ora chiamo la prima api
  //((console.log("ora chiamo la prima api sono passati " + (new Date().getTime()-global.lastSynch) + " millisecondi");

  firstApi(oauth2_data,"init_plus");


  if (s.user && ( !s.lastUpdate || s.lastUpdate < (new Date().getTime()-86400000) ) ) {
    location.hash='tools/sync';
  }
  
}


export function firstApi(oauth2_data, source){


  // global.isCallingFirstApi=true;

  if(!source){
    source="nonso";
  }

  console.log("first_api",source);

  var s=storage.get();

  var headers={};

  let toSync=false;
  if (s.user && ( !s.lastUpdate || s.lastUpdate < (new Date().getTime()-86400000) ) ) {
    toSync=true;
  }

  var aData = {};
  aData.id_device = s.id_device;
  aData.platform = s.platform;
  aData.model = s.model;
  aData.version_os = s.version_os;
  aData.app_version = s.version;
  aData.app_code = s.app_code;
  aData.notification_token = s.notification_token;

  let request={
    app_version: global.app_version,
    notification:{
      token:s.notification_token,
      error:s.notification_token_error?s.notification_token_error:null,
    },
    // notification_token: s.notification_token,
    toSync:toSync,
    info: aData,
    source:source
  };

  //manda il token di accesso se c'è e anche l'header
  if(oauth2_data.check_token){
    request.refresh_token=oauth2_data.refreshToken;
    request.check_token=true;
    headers.Authorization='Bearer '+oauth2_data.accessToken;
  }

  var url_address=global.base_call+"first_api";
  jQuery.ajax({
    method: "POST",
    contentType: "application/json; charset=utf-8",
    url: url_address,
    data:JSON.stringify(request),
    dataType: "json",
    headers:headers,
    success: function (data) { 
      
      
      
      if(global.is_cordova()){
        global.app.dialog.close();
        var s=storage.get();
        if(data.user && data.user.uid>0 && (!s || !s.user || s.user.uid!=data.user.uid)){
          
          location.hash='';
          location.reload(false);
        }
      }

      console.log("first_api data", data);
      if(data.ok){
        var s=storage.get();

        //Cambia l'utente solo se firstlogin torna uno user
        if(data.user && data.user.uid>0){

          
          // Se non aggiorno l'utente ma lo cambio devo cancellare i dati
          if(s.user && data.user.uid!=s.user.uid){
            delete s.user;
            delete s.res;
            delete s.id_farm;
            delete s.all_farms;
            delete s.farms;
            delete s.field;
            delete s.plot;
            delete s.season;
            
            // Mi permette di sincronizzare di nuovo al login
            delete s.lastUpdate;
          }

          s.user={            
            uid:data.user.uid,
            first_login:data.user.first_login,
            realname:data.user.superuser.realname,
            username:data.user.superuser.name,
            roles:data.user.roles,
            email:data.user.superuser.mail
          };

          if(data.user.profiles){
            s.user.profiles=data.user.profiles;
          }

          if(data.user.feature_flag){
            s.user.feature_flag=data.user.feature_flag;

            if(s.user.feature_flag && s.user.feature_flag.length>0){
              jQuery.each(s.user.feature_flag,function(k,v){
                if(v.id_feature=='misura21' && v.is_enabled==1){
                  jQuery('#sidebar_misura21').show();
                }
              });
            }
            else{
              jQuery('#sidebar_misura21').hide();
            }
          }
          else{
            jQuery('#sidebar_misura21').hide();
          }


          if(data.user.feature_flag_message){
            s.user.feature_flag_message=data.user.feature_flag_message;
          }

          if(oauth2_data.check_token){
            s.user.access_token= oauth2_data.accessToken;
            s.user.refresh_token= oauth2_data.refreshToken;
            s.user.social_token= oauth2_data.accessToken;            
          }
          if (data.user.access_token) {
            s.user.access_token= data.user.access_token;
            s.user.social_token= data.user.access_token;
          }
          if (data.user.refresh_token) {
            s.user.refresh_token= data.user.refresh_token;
          }



          storage.save(s);
          console.log(s.user);
        }
        else{
          
          if(!s.user){
            s.user={};
          }

          if(data.user && data.user.uid==0){
            if(s && s.user && s.user.refresh_token){
              delete s.user.refresh_token;
            }
            if(s && s.user && s.user.access_token){
              delete s.user.access_token;
            }
            if(s && s.user && s.user.social_token){
              delete s.user.social_token;
            }
            s.user.refreshUser=true;
            storage.save(s);
            
            location.hash='login';
            // exe_routes(location.hash);

          }
        }


        console.log("LLLL", data);
        
        if(!global.is_cordova()){
          try{
            handlePermission();
          }
          catch(e){
            console.log(e);
          }
        }
        else{
          var obj={
            'state':'granted'
          };
          permissionQuery(obj);
        }
        
        console.log(data);
  
        if(data.toUpdateApp){
          updateAppVersion();
        }
        

        
        if(data.user){
          if(data.user.uid>0){
            if(location.search){
              console.log("LLLL1",location.search);
              //ricarica la pagina solo se ha fatto il login dell'utente
                window.location.href =  window.location.href.split("?")[0]+'#'; //"http://www.newurl.com";
            }
            //questa funzione faceva ricaricare la pagina e quindi la funzione di hash          
            else if(location.hash && location.hash!='#login'){
              console.log("LLLL2",location.hash);

              console.log("hash", location.hash);
              // exe_routes(location.hash);
            }
            else {
              console.log("LLLL3",location.hash);

              location.hash='';
            }  
          }
          else{
            location.hash='login';
          }
        }

      }
      else {
        console.log(data);
        if(data.doLogin){
          location.hash='login';
        }
      }
      // location.reload(false);

    },
    error: function (e) {
      console.log(e);
    }
  });

}

function handlePermission() {
    return navigator.permissions
            .query({name:'notifications'})
            .then(permissionQuery)
            .catch(permissionError);
}

function permissionQuery(result) {
  
    console.debug({result});
    var newPrompt;
    var s=storage.get();

    if (result.state == 'granted') {
      var settings={};
      // notifications allowed, go wild
      // console.log("GLN",global.notification.getToken);
      

      if(s && (typeof s.notification_token == 'undefined' || s.notification_token==null) ){
        // se le notifiche sono attive e il token non esiste
        console.log("Prende il token");
        if(global.notification && global.notification.getToken){
          global.notification.getToken(function(token){
            console.log('michele',token, settings);
            settings.notification_token = token;

            var storag = storage.get();
            settings=jQuery.extend({},storag, settings);
            storage.save(settings);

            if(global.plus){
              // Non c'è bisogno perché ci pensa first_api
              if(storag.user){
                var oauth2_data={
                  accessToken:storag.user.access_token,
                  refreshToken:storag.user.refresh_token,
                }
              }
              else{
                var oauth2_data={
                  accessToken:null,
                  refreshToken:null,
                }
              }

              firstApi(oauth2_data, "token notification");
            }

            console.log("jkean new settings", settings);

            return settings;
          }, function(error){
            // if(global.nomeApp=='OliveRec'){
            //   alert(error);
            // }
            return settings;
          });
        }
        else {
          return settings;
        }
      }
      

    } else if (result.state == 'prompt') {
        // we can ask the user
        newPrompt = Notification.requestPermission();

    } else if (result.state == 'denied') {
      var s=storage.get();
      s.notification_token=null
      s.notification_token_error='Notifications denied';
      storage.save(s);
      // notifications were disabled
      alert(t('Per favore attiva le notifiche per poter ricevere le notifiche di questo sito'));
    }

    result.onchange = () => console.debug({updatedPermission: result});

    return newPrompt || result;
}

function permissionError(error) {
    console.error(error);
}


//funzione chiamata dall'html
function init_app_internal(theme, project){
  if (!global.is_cordova() && global.enablePWA!==false) {
    caches.keys().then(function(cacheNames) {
      jQuery('#debug').append('<br><b>Caches:<b>');
      cacheNames.forEach(function(cacheName) {
        console.log("CACHE NAME: "+cacheName);
        jQuery('#debug').append('<br>'+cacheName);
      });
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/sw.js')
        .then((reg) => {
          console.log('SW registered!', reg);
          jQuery('#debug').append('<br>SW registered!');

          //update every minute
          setInterval(function () {
            console.log('SW : check for update');
            reg.update();
          }, 2000);
        })
        .catch((err) => {
          console.log('Boo!', err);
          jQuery('#debug').append('<br>Boo! SW not registered!');
        });


      //check if there is a new service worker
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        function (event) {
          console.log('SW: controllerchange!');
            
          var html='<button class="refresh_page button button-primary" >Ricarica pagina</button>';
          jQuery('#footer').html(html);
          jQuery('#debug').append(html);

          jQuery('.refresh_page').on('click',function(){
            jQuery('#debug').append('<br>Sto ricaricando la pagina adesso...');
            location.reload();
            jQuery('#debug').append('<br>Ho lanciato il comando... aspetta');
          });
        }
      );
    }
  }



  //attiva crashalitic per android
  if (global.is_cordova()) {
    if (typeof FirebaseCrashlytics!=='undefined') {
      global.crashlytics = FirebaseCrashlytics.initialise();
    }
  }

  //variabili globali
  global.theme=theme;
  global.project=project;
  global.loadingText=t('Caricamento in corso..');
  //variabile per bloccare l'app per l'aggiornamento forzato
  global.lockApp=false;

  //setting di jstorage
  if (typeof global.settingsName=='undefined') {
    global.settingsName='template_settings';
  }

  console.log(global.language);
  if (typeof global.language=='undefined') {
    global.language='en';
  }

  //Attiva jquery e jquery.storage
  global.jQuery=jQuery;
  if(jQuery){
    jQuery.jStorage=$.jStorage;
  }

  //Inizializza lo storage
  storage.init({});

  //usa la lingua definita dall'utente
  let s=storage.get();
  if (s && s.language) {
    global.language=s.language;
  }

  //verifica se il porgetto prevede delle sidebars
  let theme_param={'div_id':'body_div', 'title': ''};
  if(typeof project.get_theme_param=='function'){
    theme_param = project.get_theme_param(theme_param);
    console.log(theme_param.sidebars);
    let tools={'label': t('Impostazioni'), 'icon_f7':'gear', 'href':'#tools'};
    if (theme_param.showTools!==false) {
      theme_param.sidebars.push(tools);
    }
  }

  //Crea la pagina html scheletro dell'app
  theme.init(theme_param);

  //TODO verificare!
  checkVersion();

  if (!global.lockApp) {
    //aggancia tutte le route
    exe_routes();
  }

  // tools_routes.register();



  // try {
  //   var urladdr="manifest.json";
  //   if (!global.is_cordova()) {
  //     if (typeof url!='undefined') {
  //       urladdr=url;
  //     }
  //   }
  //   console.log(urladdr);
  //   jQuery.ajax({
  //     url: urladdr,
  //     dataType:'text',
  //     success: function(text) {
  //       var manifest = JSON.parse(text);
  //       console.log("MANIFEST",manifest);

  //       if ((!s.installationDialog || s.installationDialog!='done' ) && global.iOS()) {
  //         setTimeout(function() {
  //           var installDialog = global.app.dialog.create({
  //             title:`${t('Installa')} ${manifest.name}`,
  //             destroyOnClose:true,
  //             buttons:[{
  //               close:true,
  //               text:t('Chiudi'),
  //               onClick:function(){
  //                 var s=storage.get();
  //                 s.installationDialog='done';
  //                 storage.save(s);
  //               }
  //             }],
  //             text: `
  //             1. ${t('Clicca su')} "<i class="f7-icons">square_arrow_up</i>"
  //             <br>
  //             2. ${t('Seleziona')} "<b>${t('Aggiungi alla schermata Home')} <i class="f7-icons">plus_square</i></b>"
  //             `,
  //             closeByBackdropClick: true,
  //             cssClass: 'iOSinstallPWA'
  //           });

  //           installDialog.open(true);

  //         }, 6000);
  //       }

  //       if (manifest.name) {
  //         document.title = manifest.name;
  //       }
  //       if (manifest.theme_color) {
  //         var metaThemeColor = document.querySelector("meta[name=theme-color]");
  //         metaThemeColor.setAttribute("content", manifest.theme_color);
  //       }

  //     },
  //     error:function(e){
  //       console.log("errore nel caricamento del manifest...");
  //     }
  //   });

  // }
  // catch (e) {
  //   console.log("manifest non andato a buon fine!");
  // }

  // if (s.user && ( !s.lastUpdate || s.lastUpdate < (new Date().getTime()-86400000) ) ) {
  //   location.hash='tools/sync';
  // }

}


function checkVersion(){
  // if (global.is_cordova() && cordova.platformId == 'android') {
  //   cordova.getAppVersion.getVersionNumber().then(function (local_version) {
  //     cordova.getAppVersion.getVersionCode(function(local_code){
  //       console.log("LOCAL CODE: "+local_code);
  //       console.log("LOCAL VERSION: "+local_version);

  //       var s=storage.get();
  //       var storage_version=s.version;

  //       console.log("STORAGE VERSION: "+storage_version);

  //       var firstTimeInThisVersion=true;
  //       if (typeof s.firstTimeInThisVersion!='undefined') {
  //         firstTimeInThisVersion=s.firstTimeInThisVersion;
  //       }

  //       if (storage_version!=local_version) {
  //         firstTimeInThisVersion=true;

  //         s.version=local_version;
  //         s.app_code = local_code;
  //         storage.save(s);
  //       }

  //       jQuery.ajax({
  //         type: 'GET',
  //         url: global.base_call+"get_app_version",
  //         dataType: "json",
  //         success: function(server_version){
  //           console.log("SERVER VERSION:",server_version);

  //           var release_note=server_version.data[0].release_note;
  //           var release_date=server_version.data[0].release_date;
  //           server_version=server_version.data[0].version;

  //           console.log("SERVER VERSION:",server_version);

  //           var changeVersion=false;

  //           if (typeof local_version!='undefined'){
  //             if (local_version!=server_version) {
  //               changeVersion=true;
  //             }
  //           }
  //           else {
  //             changeVersion=true;
  //           }

  //           var mainNumberSV=server_version.split('.')[0];
  //           var primaryNumberSV=server_version.split('.')[1];
  //           var secondaryNumberSV=server_version.split('.')[2];

  //           var mainNumberLV=local_version.split('.')[0];
  //           var primaryNumberLV=local_version.split('.')[1];
  //           var secondaryNumberLV=local_version.split('.')[2];

  //           if (parseInt(mainNumberSV)>parseInt(mainNumberLV) || parseInt(primaryNumberSV)>parseInt(primaryNumberLV)) {
  //             forceUpdate();
  //           }
  //           else if(parseInt(mainNumberSV)==parseInt(mainNumberLV) && parseInt(primaryNumberSV)==parseInt(primaryNumberLV) && parseInt(secondaryNumberSV) > parseInt(secondaryNumberLV)) {
  //             console.log("versione cambiata!");
  //             changedVersion();
  //           }
  //           else {
  //             console.log("versione non cambiata!");

  //             if (firstTimeInThisVersion==true) {
  //               console.log("prima volta nell'app");

  //               global.app.dialog.alert(release_note, "Note di rilascio", function(){location.reload();});

  //               s.release_date=formatDate(release_date);
  //               s.firstTimeInThisVersion=false;
  //               storage.save(s);
  //             }
  //           }
  //         },
  //         error: function(e) {
  //           console.log("Si è verificato un errore");
  //           // alert(e);
  //         }
  //       });
  //     });
  //   });
  // }
  // else {
  //   if (global.app_version) {
  //     var s=storage.get();
  //     s.version=global.app_version;
  //     storage.save(s);
  //   }
  // }
}

function updateAppVersion(){
  jQuery('#debug').append('<br>Nuova Versione disponibile!');
  // alert('updateAppVersion');
  // if ('serviceWorker' in navigator) {
  //   jQuery('#debug').append('serviceWorker in navigator!');

  //   // console.log(caches.keys());
  //   caches.keys().then(function(cacheNames) {
  //     cacheNames.forEach(function(cacheName) {
  //       jQuery('#debug').append('<br>'+cacheName+' cancellato (a regola)!');

  //       console.log("CACHE NAME: "+cacheName);
  //       caches.delete(cacheName);
  //     });
  //   });
  // }
  // else{
  //   jQuery('#debug').append('serviceWorker NOT in navigator!');
  // }
  
  // var s=storage.get();
  // jQuery('#debug').append('<br><br>'+JSON.stringify(s)+'');

  

  // caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
  // this._target.storageAgent().clearDataForOrigin(this._securityOrigin, storageTypes.join(','));

}